.image_container {
    position: relative;
}

.image_container>img {
    width: 100%;
}

#card_heading_div:hover {
    background: #F86B1F;
    fill: var(--white);
    stroke: var(--white);
    background-color: #F86B1F;
}


.card_heading {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    position: absolute;
    top: 74%;
    left: -6%;
    background: #212F63;
    padding: 15px 30px;
    cursor: pointer;
}



.card_heading>p {
    /* H --/Title SB 18 */
    max-width: 250px;
    width: 250px;
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;

    /* identical to box height, or 22px */

    display: flex;
    align-items: center;

    /* White */

    color: #FFFFFF;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .card_heading>p {
        font-size: 14px;
    }
   
}
@media screen and (max-width: 767px) {
.arabic_card_heading{

    left: -8px;
}

}