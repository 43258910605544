.main_wrapper{
    padding: 20px 18rem;
   
}
.faq_text{
    font-size: 34px;
    font-weight: 600;
    text-transform: uppercase;
    color: #414141;
    text-align: center;
    margin: 40px 0px;
}
.search_input_box input{

    border: 1px solid#E1E1E1 ;   
    width: 100%;
    padding: 15px;
    font-size: 16px;
    outline: none;
}
.search_input_box{
    position: relative;
    
}
.search_input_box i{
    position: absolute;
    right: 20px;
    top: 22px;
    color:#212F63;
}
input[type="search"]::placeholder {
    color: #6F758B;
  }
  .faq_tabs_lists{
    margin: 30px 0px;
    text-align: center;
   
  }

  .faq_tabs_lists label{
    color: #212F63    ;
  }
  .shipping_accordian_bar1 .shipping_Accordion_text1{
    background-color: #F7F7F7  ;
    margin-bottom: 20px;
    box-shadow: none;
    border: none;
    padding: 15px;
}
.shipping_accordian_bar1 .shipping_Accordion_typography1{
    font-size: 18px;
    color: #414141;
    font-weight: 500;
    line-height: 22px;
}
.bottom_image{
    margin-top: 5%;
}
@media screen and (max-width: 767px) {
    .main_wrapper{
        padding: 20px 10px;
        margin-top: 5rem;
    }
    .faq_text{
        margin-top: 0px;
        font-size: 28px;
    }
    .faq_tabs_lists{
        margin: 10px;
    }
    .shipping_accordian_bar1 .shipping_Accordion_typography1{
        font-size: 15px;
        line-height: 22px;
    }

}
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .main_wrapper{
        padding: 20px 3rem;
    }
}
@media screen and (min-width: 1201px) and (max-width: 1366px) {
    .main_wrapper{
        padding: 20px 5rem;
    }
}
@media screen and (min-width: 1367px) and (max-width: 1600px) {
    .main_wrapper{
        padding: 20px 8rem;
    }
}
@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .main_wrapper{
        padding: 20px 10rem;
    }
}