.close_icon_wrapper,
.cancel_icon_wrapper {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 12px;
    display: inline-grid;
    place-items: center;
    place-content: center;
    cursor: pointer;
    z-index: 2;
}

.close_icon_wrapper {
    background: var(--white);
}

.cancel_icon_wrapper {
    background: var(--error-red-dark);
}