.main_wrapper {
    padding: 30px 10rem;
    
}

.thanku_message_text h1 {
    font-size: 40px;
    color: #414141;
    font-weight: 700;
    line-height: 52px;
    text-transform: capitalize;
    margin-top: 5%;
    margin-bottom: 4%;
}

.thanku_message_text p {
    font-size: 16px;
    color: #6F758B;
    text-align: center;
    margin: 0px auto;
    width: 70%;
    line-height: 28px;
    font-weight: 500;

}

.thanku_message_text a {
    color: #414141;
    text-decoration: none;
    font-weight: 500;
}

.thanku_message_text {
    text-align: center;
}

.time_place {
    display: flex;
    justify-content: center;
    margin-top: 4%;
}

.time_place div {
    display: flex;
    align-items: center;
}

.time_place i {
    color: #F86B1F;
    font-size: 20px;
    margin-right: 12px;
}

.time_place h5 {
    font-size: 18px;
    color: #414141;
    font-weight: 600;
    text-transform: capitalize;
}

.time_place span {
    font-size: 18px;
    margin-left: 2px;
    color: #414141;
    font-weight: 500;
}

.time_place h6 {
    color: #212F63;
    font-size: 18px;
    font-weight: 600;

}

.thanku_message_box_wrap {
    margin-left: 70px;
}

.shopping_details_boxes {
    display: flex;
    justify-content: space-between;
    border: 1px solid #E1E1E1;
    margin-top: 40px;
}

.shopping_details_boxes ul {
    list-style: none;
    padding-left: 0px;
    margin-top: 38px;
}

.shopping_details_boxes h3 {
    font-size: 20px;
    color: #414141;
    font-weight: 500;
    margin-top: 16px;

}

.shopping_details_boxes div {
    text-align: left;
}

.shopping_details_boxes li {
    color: #6F758B;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
}

.shopping_details_boxes_lists:nth-child(2) {
    width: 32%;
    border-left: 1px solid#E1E1E1;
    padding: 35px 35px;
}

.shopping_details_boxes_lists:nth-child(3) {
    width: 32%;
    border-left: 1px solid#E1E1E1;
    padding: 35px 35px;
}

.shopping_details_boxes_lists {
    width: 32%;
    padding: 35px 35px;
}

.shopping_details_prices_lists li {
    display: flex;
    justify-content: space-between;
}

.shopping_details_prices_lists p {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
    border-top: 1px solid #e2e2e2;
    padding-top: 20px;
    color: #414141;
    font-size: 16px;
}
.continue_btn{
    background-color: #212F63;
    color: white;
    padding: 8px 30px;
    font-size: 16px;
    font-weight: 500;
    margin-top: 30px;
    border: none;
}
@media only screen and (min-width: 1201px) and (max-width: 1366px) {
    .main_wrapper {
        padding: 30px 2rem;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    .main_wrapper {
        padding: 30px 2rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .main_wrapper {
        padding: 30px 2rem;
    }

    .shopping_details_boxes h3 {
        font-size: 16px;
    }

    .shopping_details_boxes ul {
        margin-top: 20px;
    }

    .shopping_details_boxes li {
        font-size: 14px;
    }
}

@media only screen and (max-width: 767px) {
    .main_wrapper {
        padding: 30px 1rem;
        margin-top: 5rem;
    }

    .shopping_details_boxes {
        display: block;
    }

    .shopping_details_boxes_lists {
        width: 90%;
        margin-bottom: 20px;
        border-bottom: 1px solid#e2e2e2;
        margin: 0 auto;
        padding: 30px 0px;
    }

    .shopping_details_boxes_lists:nth-child(2) {
        width: 90%;
        margin-bottom: 20px;
        border-bottom: 1px solid#e2e2e2;
        margin: 0 auto;
        padding: 30px 0px;
        border-left: none;
    }

    .shopping_details_boxes_lists:nth-child(3) {
        width: 90%;
        border-bottom: none;
        border-left: none;
        padding: 35px 0px;
    }
    .thanku_message_text h1{
        font-size: 30px;
        line-height: 38px;
    }
    .thanku_message_text p {
        font-size: 14px;
        color: #6F758B;
        text-align: center;
        margin: 0px auto;
        width: 100%;
        line-height: 24px;
        font-weight: 500;
    }
    .time_place h5{
        font-size: 15px;
    }
    .time_place span{
        font-size: 14px;
    }
    .time_place h6{
        font-size: 15px;
    }
    .shopping_details_boxes h3{
        font-size: 18px;
        margin-top: 6px;
    }
    .shopping_details_boxes ul{
        margin-top: 14px;
    }
    .shopping_details_boxes li{
        font-size: 14px;
    }
}