.main_wrapper{
    padding: 30px 15rem;
 
}
.privacy_policy_text h1{
    font-size: 40px;
    font-weight: 600;
    color: #414141;
    text-transform: capitalize;
    line-height: 52px;
}
.privacy_policy_text{
    margin-bottom: 4%;
    margin-top: 0%;
}
.privacy_policy_text p{
    font-size: 16px;
    color: #6F758B    ;
    font-weight: 400;
    text-align: justify;
    line-height: 26px;
    margin-top: 25px;
}
.privacy_policy_content h3{
    font-size: 20px;
    font-weight: 600;
    color: #414141;
    text-transform: capitalize;
    line-height: 30px;
}
.privacy_policy_content p{
    font-size: 16px;
    color: #6F758B    ;
    font-weight: 400;
    text-align: justify;
    line-height: 26px;
    margin-top: 25px;
}
.privacy_policy_content{
    margin-bottom: 4%;
}
@media screen and (max-width: 767px) {
    .main_wrapper{
        padding: 30px 2rem;
        margin-top: 5rem;
    }

}
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .main_wrapper{
        padding: 30px 4rem;
    }
}