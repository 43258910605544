.pagination_view_all_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.pagination_wrapper>span {
    padding: 4px 12px;
    margin-right: 10px;
    color: var(--dark-brinjal);
    border: 0.5px solid var(--light-gray3);
    cursor: pointer;
}

.pagination_wrapper>span:last-child {
    margin-left: 0px !important;
    position: relative;
    padding: 4px 15px;
}

.pagination_wrapper>span:last-child>svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.view_all_action_btn>svg {
    margin-left: 10px;
}

.view_all_action_btn {
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 500;
    font-size: .9rem;
    line-height: 140%;
    text-transform: capitalize;
    color: var(--dark-brinjal);
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-bottom: 20px;
}
.pagination{
    list-style:none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
    font-size: 1.2rem;
    gap: 5px;
}
.pagination .page_num{
    padding: 8px 15px;
    cursor: pointer;
    border: 1px solid #E1E1E1;
    /* border-style: solid 23px;
    border-color: #E1E1E1; */
    border-radius: 3px;
    color: #212F63;
    /* font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500; */
}
.pagination .page_num:hover{
    border-style: solid;
    border-color: #6F758B;
    color: #6F758B;
}
.pagination .active{
    border-style: solid;
    border-color: #6F758B;
    color: #6F758B;
    
}
@media screen and (max-width: 767px) {
    .pagination_view_all_wrapper {
        display: block;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
    }

    .pagination_view_all_wrapper {
        display: flex;

    }

    .pagination_view_all_wrapper a {
        position: relative;
        top: 0px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .pagination_view_all_wrapper {
        display: flex;

    }

}