.main_wrapper {
    padding: 10px 10rem;

}

.clickable_text a:focus {
    color: red;
    cursor: default;
}

.a_link.active {
    color: #FD7E14;
}

.place_order_btn {
    position: relative;
    bottom: 190px;
    left: 676px;
    background: #212F63;
    color: white;
    width: 160px;
    height: 44px;
    border: 2px solid #212F63;
}

.account_order_lists a {
    list-style: none;
    color: #212F63;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    line-height: 34px;
}

.account_order_lists li {
    list-style: none;
}

.account_order_lists .active_list {
    list-style: none;
    color: #6F758B;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    line-height: 34px;
}

.order_lists {
    display: flex;
    justify-content: space-between;
    margin: 3% 0px;

}

.account_order_lists {
    width: 25%;
}

.account_order_accordion_lists {
    width: 70%;
}

.account_order_accordion_lists h1 {
    font-size: 40px;
    font-weight: 600;
    color: #414141;
    margin-bottom: 2rem;
}

.order_lists_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}

.order_lists_bar .cancel_button {
    color: #F86B1F;
    text-decoration: underline;
}

.accordion_lists_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accordion_lists_bar {
    width: 40%;

}

.accordion_lists_bar1 {
    display: flex;
    justify-content: space-around;
    align-items: center;

}

.accordion_lists_bar1 {
    width: 40%;
}

.order_lists_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    background: #F7F7F7;
    border: none !important;
    width: 100%;
}

.order_lists_bar p {
    color: #6F758B;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    width: 100%;

}

.order_lists_bar span {
    color: #414141;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    height: 34px;
    width: 140px;
    padding: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    text-align: left;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
}

.order_lists_bar .orange_text {
    color: #F86B1F;
}

.order_lists_bar_items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    background: #F7F7F7;
    border: none !important;
}

.accordion_lists_addres h5 {
    color: #414141;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.accordion_lists_addres {
    width: 46%;
    justify-content: space-between;
    display: flex;
}

.productdetailslist {
    justify-content: space-between;
    display: flex;
    align-items: center;

}






.productdetailslistMainDiv {
    margin-top: 30px;
}

.checkout_order_summery h2 {
    color: #666666;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
}

.checkout_order_summery {
    background: #F7F7F7;
    padding: 30px;
    width: 35%;
}

.checkout_items_products {
    border-bottom: 1px solid #cdcdcd;
    padding-bottom: 20px;
}

.checkout_items_products {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    align-items: center;
}

.checkout_items_oil_filters {
    display: flex;
    align-items: center;
}

.checkout_items_oil_filters img {
    margin-right: 25px;
    width: 40px;
    height: 40px;
}

@media screen and (max-width: 767px) {
    .main_wrapper {
        padding: 30px 1rem;
    }

    .account_order_lists a {
        font-size: 13px;
    }

    .accordion_lists_addres h5 {
        font-size: 13px;
    }

    .order_lists_bar p {
        font-size: 13px;
    }

    .account_order_accordion_lists {
        width: 100%;
    }

    .accordion_lists_order {
        width: 100%;
        display: flex;
        justify-content: space-between;

    }

    .checkout_order_summery {
        background: #F7F7F7;
        padding: 14px;
        width: 100%;
    }

    .order_lists_bar {
        display: block;
        justify-content: space-between;
        /* align-items: center; */
        padding: 20px 0px;
        background: #F7F7F7;
        border: none !important;
    }

    .accordion_lists_bar {
        display: block;
    }

    .accordion_lists_bar1 {
        justify-content: space-between;
    }

    .checkout_items_oil_filters {
        display: flex;
        align-items: center;

    }

    .checkout_items_oil_filters img {
        margin-right: 25px;
    }

    .accordion_lists_addres {
        width: 100%;
        justify-content: space-between;
        display: flex;
        padding: 0px 10px;
    }

    .checkout_order_summery h2 {
        font-size: 15px;
    }

    .checkout_items_oil_filters h4 {
        color: #6F758B;
        font-size: 14px;

    }

    .checkout_items_oil_filters h5 {
        font-size: 14px;
    }

    .checkout_items_products h4 {
        color: #6F758B;
        font-size: 14px;
    }

    .checkout_items_products h5 {
        font-size: 14px;
    }

    .order_lists {
        display: block;
        justify-content: space-between;
        margin: 0% 0px;
    }

    .account_order_lists {
        width: 100%;
        margin-top: 0rem;
    }

    .account_order_lists ul {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .account_order_accordion_lists h1 {
        font-size: 24px;
        font-weight: 600;
        color: #414141;
        margin-bottom: 1rem;
        margin-top: 20px;
    }

    .accordion_lists_bar {
        width: 100%;
        margin-bottom: 20px;
    }

    .accordion_lists_bar1 {
        width: 100%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .main_wrapper {
        padding: 30px 2rem;
    }

    .accordion_lists_addres h5 {
        font-size: 14px;
    }

    .order_lists_bar p {
        font-size: 14px;
    }

    .account_order_accordion_lists {
        width: 100%;
    }

    .accordion_lists_order {
        width: 48%;
        display: flex;
        justify-content: space-between;

    }

    .checkout_order_summery {
        background: #F7F7F7;
        padding: 30px;
        width: 100%;
    }

    .order_lists_bar {
        display: flex;
        justify-content: space-between;
        /* align-items: center; */
        padding: 20px 30px;
        background: #F7F7F7;
        border: none !important;
    }

    .checkout_items_oil_filters {
        display: flex;
        align-items: center;

    }

    .checkout_items_oil_filters img {
        margin-right: 25px;
    }

    .accordion_lists_addres {
        width: 55%;
        justify-content: space-between;
        display: flex;
    }

    .checkout_order_summery h2 {
        font-size: 15px;
    }

    .checkout_items_oil_filters h4 {
        color: #6F758B;
        font-size: 14px;

    }

    .checkout_items_oil_filters h5 {
        font-size: 14px;
    }

    .checkout_items_products h4 {
        color: #6F758B;
        font-size: 14px;
    }

    .checkout_items_products h5 {
        font-size: 14px;
    }

    .order_lists {
        display: block;
        justify-content: space-between;
        margin: 0% 0px;
    }

    .account_order_lists {
        width: 100%;
        margin-top: 5rem;
    }

    .account_order_lists ul {
        display: flex;
        justify-content: space-between;
    }

    .accordion_lists_bar {
        width: 48%;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .main_wrapper {
        padding: 30px 2rem;
    }

    .accordion_lists_addres h5 {
        font-size: 14px;
    }

    .order_lists_bar p {
        font-size: 14px;
    }

    .account_order_accordion_lists {
        width: 80%;
    }

    .accordion_lists_order {
        width: 48%;
        display: flex;
        justify-content: space-between;

    }

    .checkout_order_summery {
        background: #F7F7F7;
        padding: 30px;
        width: 100%;
    }

    .order_lists_bar {
        display: flex;
        justify-content: space-between;
        /* align-items: center; */
        padding: 20px 30px;
        background: #F7F7F7;
        border: none !important;
    }

    .checkout_items_oil_filters {
        display: flex;
        align-items: center;

    }

    .checkout_items_oil_filters img {
        margin-right: 25px;
    }

    .accordion_lists_addres {
        width: 55%;
        justify-content: space-between;
        display: flex;
    }

    .checkout_order_summery h2 {
        font-size: 15px;
    }

    .checkout_items_oil_filters h4 {
        color: #6F758B;
        font-size: 14px;

    }

    .checkout_items_oil_filters h5 {
        font-size: 14px;
    }

    .checkout_items_products h4 {
        color: #6F758B;
        font-size: 14px;
    }

    .checkout_items_products h5 {
        font-size: 14px;
    }

    .order_lists {
        display: flex;
        justify-content: space-between;
        margin: 0% 0px;
    }

    .account_order_lists {
        width: 25%;
        margin-top: 5rem;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1366px) {
    .main_wrapper {
        padding: 30px 4rem;
    }

    .accordion_lists_addres h5 {
        font-size: 14px;
    }

    .order_lists_bar p {
        font-size: 14px;
    }

    .account_order_accordion_lists {
        width: 80%;
    }

    .accordion_lists_order {
        width: 48%;
        display: flex;
        justify-content: space-between;

    }

    .checkout_order_summery {
        background: #F7F7F7;
        padding: 30px;
        width: 100%;
    }

    .order_lists_bar {
        display: flex;
        justify-content: space-between;
        /* align-items: center; */
        padding: 20px 30px;
        background: #F7F7F7;
        border: none !important;
    }

    .checkout_items_oil_filters {
        display: flex;
        align-items: center;

    }

    .checkout_items_oil_filters img {
        margin-right: 25px;
    }

    .accordion_lists_addres {
        width: 48%;
        justify-content: space-between;
        display: flex;
    }

    .accordion_lists_addres div {
        width: 48%;
    }

    .checkout_order_summery h2 {
        font-size: 15px;
    }

    .checkout_items_oil_filters h4 {
        color: #6F758B;
        font-size: 14px;

    }

    .checkout_items_oil_filters h5 {
        font-size: 14px;
    }

    .checkout_items_products h4 {
        color: #6F758B;
        font-size: 14px;
    }

    .checkout_items_products h5 {
        font-size: 14px;
    }

    .order_lists {
        display: flex;
        justify-content: space-between;
        margin: 0% 0px;
    }

    .account_order_lists {
        width: 25%;
        margin-top: 5rem;
    }
}

@media screen and (min-width: 1367px) and (max-width: 1600px) {
    .main_wrapper {
        padding: 30px 7rem;
    }

    .account_order_accordion_lists {
        width: 78%;
    }

    .accordion_lists_order {
        width: 48%;
        display: flex;
        justify-content: space-between;

    }

    .checkout_order_summery {
        background: #F7F7F7;
        padding: 30px;
        width: 100%;
    }

    .order_lists_bar {
        display: flex;
        justify-content: space-between;
        /* align-items: center; */
        padding: 20px 30px;
        background: #F7F7F7;
        border: none !important;
    }

    .checkout_items_oil_filters {
        display: flex;
        align-items: center;

    }

    .checkout_items_oil_filters img {
        margin-right: 25px;
        width: 40px;
        height: 40px;
    }

    .accordion_lists_addres {
        width: 50%;
        justify-content: space-between;
        display: flex;
    }

    .checkout_items_oil_filters h4 {
        color: #6F758B;
        font-size: 15px;

    }

    .checkout_items_products h4 {
        color: #6F758B;
        font-size: 15px;
    }

    .account_order_lists {
        width: 25%;
        margin-top: 5rem;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .main_wrapper {
        padding: 30px 7rem;
    }

    .account_order_accordion_lists {
        width: 78%;
    }

    .accordion_lists_order {
        width: 48%;
        display: flex;
        justify-content: space-between;

    }

    .checkout_order_summery {
        background: #F7F7F7;
        padding: 30px;
        width: 100%;
    }

    .order_lists_bar {
        display: flex;
        justify-content: space-between;
        /* align-items: center; */
        padding: 20px 30px;
        background: #F7F7F7;
        border: none !important;
    }

    .checkout_items_oil_filters {
        display: flex;
        align-items: center;

    }

    .checkout_items_oil_filters img {
        margin-right: 25px;
    }

    .accordion_lists_addres {
        width: 50%;
        justify-content: space-between;
        display: flex;
    }

    .checkout_items_oil_filters h4 {
        color: #6F758B;
        font-size: 15px;

    }

    .checkout_items_products h4 {
        color: #6F758B;
        font-size: 15px;
    }

    .account_order_lists {
        width: 25%;
        margin-top: 5rem;
    }
}

@media screen and (min-width: 1801px) and (max-width: 1920px) {
    .main_wrapper {
        padding: 30px 10rem;
    }

    .account_order_accordion_lists {
        width: 78%;
    }

    .accordion_lists_order {
        width: 48%;
        display: flex;
        justify-content: space-between;

    }

    .checkout_order_summery {
        background: #F7F7F7;
        padding: 30px;
        width: 100%;
    }

    .order_lists_bar {
        display: flex;
        justify-content: space-between;
        /* align-items: center; */
        padding: 20px 30px;
        background: #F7F7F7;
        border: none !important;
    }

    .checkout_items_oil_filters {
        display: flex;
        align-items: center;

    }

    .checkout_items_oil_filters img {
        margin-right: 25px;
    }

    .accordion_lists_addres {
        width: 42%;
        justify-content: space-between;
        display: flex;
    }

    .checkout_items_oil_filters h4 {
        color: #6F758B;
        font-size: 15px;

    }

    .checkout_items_products h4 {
        color: #6F758B;
        font-size: 15px;
    }

    .account_order_lists {
        width: 25%;
        margin-top: 5rem;
    }
}

@media screen and (min-width: 1921px) and (max-width: 2560px) {
    .main_wrapper {
        padding: 30px 15rem;
    }

    .account_order_accordion_lists {
        width: 78%;
    }

    .accordion_lists_order {
        width: 48%;
        display: flex;
        justify-content: space-between;

    }

    .checkout_order_summery {
        background: #F7F7F7;
        padding: 30px;
        width: 100%;
    }

    .order_lists_bar {
        display: flex;
        justify-content: space-between;
        /* align-items: center; */
        padding: 20px 30px;
        background: #F7F7F7;
        border: none !important;
    }

    .checkout_items_oil_filters {
        display: flex;
        align-items: center;

    }

    .checkout_items_oil_filters img {
        margin-right: 25px;
    }

    .accordion_lists_addres {
        width: 42%;
        justify-content: space-between;
        display: flex;
    }

    .checkout_items_oil_filters h4 {
        color: #6F758B;
        font-size: 15px;

    }

    .checkout_items_products h4 {
        color: #6F758B;
        font-size: 15px;
    }

    .account_order_lists {
        width: 25%;
        margin-top: 5rem;
    }
}

@media only screen and (max-width: 767px) {}

.ActiveLink {
    color: orange;
}