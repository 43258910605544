.card_wrapper {
    padding: 30px 50px;
    text-align: center !important;

}
.card_wrapper button{
    margin-top: 20px;
}
.heading_description {
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;
    color: #414141;
}
.cancle_btn {
    border: 1px solid #212F63 !important;
    color: #212F63 !important;
    background-color: white !important;
    
}
.card_button_boxes{
display: flex;
    justify-content: center;
    column-gap: 48px;
}
@media only screen and (max-width: 767px){
    .heading_description{
        font-size: 24px;
    }
   
}