.main_wrapper{
    padding: 30px 10rem;
   
}
.checkout_cart{
    display: flex;
    justify-content: space-between;
    margin: 0% 0px 1%;
}
.checkout_cart h1{
    font-size: 40px;
    color: #414141;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: 15px;
}
.checkout_cart p{
    font-size: 16px;
    color: #6F758B    ;
    font-weight: 400;
    line-height: 22px;
    margin-top: 25px;
    text-align: justify;
}
.checkout_cart_coupancode{
    border: 1px solid #E1E1E1;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.checkout_cart_heading{
    display: flex;
    align-items: center;
}
.checkout_cart_heading span{
    display: flex;
    margin-left: 20px;
    color: #6F758B;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
}
.checkout_cart_heading h4{
    color: #212F63    ;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    margin-left: 2px;
}
.checkout_cart_coupancode i{
    color: #212F63;
}
.checkout_cart_customer{
    background: #F7F7F7;
    padding: 30px;
    margin-top: 45px;
}
.checkout_cart_customer span{
    display: flex;
    color: #F86B1F;
    font-size: 26px;
    font-weight: 500;
}
.checkout_cart_customer h3{
    color: #414141;
    font-size: 26px;
    font-weight: 500;
    margin-left: 26px
}
.checkout_form_box input{
    border: 1px solid #E1E1E1;
    padding: 10px;
    width: 300px;
    outline: none;
}
.checkout_form_box{
    display: flex;
    margin-top: 15px;
}
.checkout_form_box button{
    background: #212F63;
    padding: 10px 40px;
    color: white;
    border: none;
    margin-left: 20px;
    cursor: pointer;
}
.checkout_subscribe{
    display: flex;
    align-items: center;
    margin-top: 15px;
}
.checkout_subscribe h3{
    color: #414141;
    font-size: 18px;
    font-weight: 400;
    margin-left: 10px
}
.checkout_cart_customer p{
    display: flex;
    color: #6F758B
    ;
    font-size: 16px;
    text-transform: capitalize;
    margin-top: 15px;
    align-items: center;

}
.checkout_cart_customer h5{
    display: flex;
    color: #212F63;
    font-size: 16px;
    text-transform: capitalize;
    margin-left: 2px;

}

.checkout_cart_gray_box{
    background: #F7F7F7;
    padding: 30px;
    margin-top: 45px;
}
.checkout_cart_gray_box span{
    color: #D1D1D2    ;
    font-size: 16px;
    font-weight: 500;
    display: flex;
}
.checkout_cart_gray_box p{
    color: #6F758B    ;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
}
.checkout_order_summery h2{
    color: #666666 ;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
}
.checkout_order_summery{
    background: #F7F7F7;
    padding: 30px;
    width: 35%;

}
.checkout_cart_customer_product{
    display: flex;
    justify-content: space-between;
}
.checkout_cart_customer_products{
    width: 60%;
}
.checkout_items_products{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    align-items: center;
}
.checkout_items_oil_filters{
    display: flex;
    align-items: center;
}
.checkout_items_oil_filters img{
    margin-right: 30px;
}
.checkout_items_products h5{
    font-size: 16px;
    color: #414141;
    font-weight: 400;
}
.checkout_items_products{
    border-bottom: 1px solid #cdcdcd;
    padding-bottom:20px
}
.checkout_items_products h4{
    color: #6F758B;
    font-size: 16px;
    font-weight: 400;

}
.checkout_order_summery{
    margin-top: 45px;
}
.order_summary{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

}
.order_summary_section{
    margin-top: 30px;
}
/* .order_summary div{
    width: 28%;
} */
.order_summary_lists p{
    font-size: 15px;
    color: #6F758B;
    font-weight: 400;
    line-height: 25px;
    text-transform: capitalize;
}
.order_summary_lists h2{
    font-size: 16px;
    color: #F86B1F
    ;
    font-weight: 600;
    line-height: 30px;
    text-transform: capitalize;
     margin-top: 15px;
}
.order_summary_lists1 p{
    font-size: 15px;
    color: #414141;
    font-weight: 500;
    line-height: 25px;
    text-transform: capitalize;
}
.order_summary_lists1 h2{
    font-size: 16px;
    color: #F86B1F    ;
    font-weight: 600;
    line-height: 30px;
    text-transform: capitalize;
    margin-top: 15px;
}
@media screen and (max-width: 767px) {
    .main_wrapper{
        padding: 30px 2rem;
        margin-top: 5rem;
    }
    .checkout_cart h1 {
        font-size: 30px;
    }
    .checkout_cart p{
        font-size: 14px;
    }
    .checkout_cart_heading span {
        display: block;
    }
    .checkout_cart_customer_product{
        display: block;
    }
    .checkout_cart_customer_products{
        width: 100%;
    }
    .checkout_form_box{
        display: block;
    }
    .checkout_form_box button{
        width: 100%;
        margin-top: 20px;
        margin-left: 0px;
    }
    .checkout_form_box input{
        width: 100%;
    }
    .checkout_subscribe h3{
        font-size: 15px;
    }
    .checkout_cart_customer p{
        display: block;
    }
    .checkout_subscribe img{
         width: 6%;
    }
    .checkout_order_summery{
        width: 100%;
    }
    .order_summary_lists p {
        font-size: 12px;
        color: #6F758B;
        font-weight: 400;
        line-height: 28px;
        text-transform: capitalize;
     
    
}
.order_summary_lists1 p {
    font-size: 12px;
    color: #414141;
    line-height: 28px;
}
.order_summary_lists h2 {
    font-size: 16px;
    color: #F86B1F;
    font-weight: 600;
    line-height: 8px;
    text-transform: capitalize;
    margin-top: 15px;
}
.order_summary_lists1 h2{
    font-size: 16px;
    color: #F86B1F;
    font-weight: 600;
    line-height: 8px;
}
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .main_wrapper{
        padding: 30px 2rem;
    }
    .checkout_cart h1 {
        font-size: 30px;
    }
    .checkout_cart p{
        font-size: 14px;
    }
    .checkout_cart_heading span {
        display: block;
    }
    .checkout_cart_customer_product{
        display: block;
    }
    .checkout_cart_customer_products{
        width: 100%;
    }
    .checkout_form_box{
        display: flex;
    }
    .checkout_form_box button{
        width: 48%;
        margin-top: 0;
    }
    .checkout_subscribe h3{
        font-size: 15px;
    }
    .checkout_cart_customer p{
        display: flex;
    }
    .checkout_subscribe img{
         width: 3%;
    }
    .checkout_order_summery{
        width: 100%;
    }
    .order_summary_lists p {
        font-size: 14px;
        color: #6F758B;
        font-weight: 400;
        line-height: 28px;
        text-transform: capitalize;
        inline-size: 400px;
    
}
.order_summary_lists1 p {
    font-size: 14px;
    color: #414141;
    line-height: 28px;
}
.order_summary_lists h2 {
    font-size: 16px;
    color: #F86B1F;
    font-weight: 600;
    line-height: 8px;
    text-transform: capitalize;
    margin-top: 15px;
}
.order_summary_lists1 h2{
    font-size: 16px;
    color: #F86B1F;
    font-weight: 600;
    line-height: 8px;
}

}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .main_wrapper{
        padding: 30px 2rem;
    }
    .checkout_cart h1 {
        font-size: 30px;
    }
    .checkout_cart p{
        font-size: 14px;
    }
    .checkout_cart_heading span {
        display: block;
    }
    .checkout_cart_customer_product{
        display: block;
    }
    .checkout_cart_customer_products{
        width: 100%;
    }
    .checkout_form_box{
        display: flex;
    }
    .checkout_form_box button{
        
        margin-top: 0;
    }
    .checkout_subscribe h3{
        font-size: 15px;
    }
    .checkout_cart_customer p{
        display: flex;
    }
    .checkout_subscribe img{
         width: 3%;
    }
    .checkout_order_summery{
        width: 100%;
    }
    .order_summary_lists p {
        font-size: 14px;
        color: #6F758B;
        font-weight: 400;
        line-height: 28px;
        text-transform: capitalize;
        inline-size: 400px;
    
}
.order_summary_lists1 p {
    font-size: 14px;
    color: #414141;
    line-height: 28px;
}
.order_summary_lists h2 {
    font-size: 16px;
    color: #F86B1F;
    font-weight: 600;
    line-height: 8px;
    text-transform: capitalize;
    margin-top: 15px;
}
.order_summary_lists1 h2{
    font-size: 16px;
    color: #F86B1F;
    font-weight: 600;
    line-height: 8px;
}
}
@media screen and (min-width: 1201px) and (max-width: 1366px) {
    .main_wrapper{
        padding: 30px 4rem;
    }
    .checkout_cart h1 {
        font-size: 30px;
    }
    .checkout_cart p{
        font-size: 14px;
    }
    .checkout_cart_heading span {
        display: block;
    }
    .checkout_cart_customer_product{
        display: flex;
    }
    .checkout_cart_customer_products{
        width: 48%;
    }
    .checkout_form_box{
        display: flex;
    }
    .checkout_form_box button{
        width:100%;
        margin-top: 0;
    }
    .checkout_subscribe h3{
        font-size: 15px;
    }
    .checkout_cart_customer p{
        display: flex;
    }
    .checkout_subscribe img{
         width: 3%;
    }
    .checkout_order_summery{
        width: 48%;
    }
    .order_summary_lists p {
        font-size: 14px;
        color: #6F758B;
        font-weight: 400;
        line-height: 28px;
        text-transform: capitalize;
 
}
.order_summary_lists1 p {
    font-size: 14px;
    color: #414141;
    line-height: 28px;
}
.order_summary_lists h2 {
    font-size: 16px;
    color: #F86B1F;
    font-weight: 600;
    line-height: 8px;
    text-transform: capitalize;
    margin-top: 15px;
}
.order_summary_lists1 h2{
    font-size: 16px;
    color: #F86B1F;
    font-weight: 600;
    line-height: 8px;
}
}
@media screen and (min-width: 1367px) and (max-width: 1600px) {
    .main_wrapper{
        padding: 30px 6rem;
    }
    .checkout_cart h1 {
        font-size: 30px;
    }
    .checkout_cart p{
        font-size: 14px;
    }
    .checkout_cart_heading span {
        display: block;
    }
    .checkout_form_box{
        display: flex;
    }
   
    .checkout_subscribe h3{
        font-size: 15px;
    }
    .checkout_cart_customer p{
        display: flex;
    }
    .checkout_subscribe img{
         width: 3%;
    }
    .checkout_order_summery{
        width: 100%;
    }
    .order_summary_lists p {
        font-size: 14px;
        color: #6F758B;
        font-weight: 400;
        line-height: 28px;
        text-transform: capitalize;
        inline-size: 400px;
    
}
.order_summary_lists1 p {
    font-size: 14px;
    color: #414141;
    line-height: 28px;
}
.order_summary_lists h2 {
    font-size: 16px;
    color: #F86B1F;
    font-weight: 600;
    line-height: 8px;
    text-transform: capitalize;
    margin-top: 15px;
}
.order_summary_lists1 h2{
    font-size: 16px;
    color: #F86B1F;
    font-weight: 600;
    line-height: 8px;
}
.checkout_cart_customer_products{
    width: 48%;
}
.checkout_order_summery{
    width: 48%;
}
 .checkout_form_box button{
    width:100%;
    margin-top: 0;
}
}