@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.paginationArrow{
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
background:transparent
}
.menu-list-items{
    width: 100%;
}
.language-select-box select {
    box-sizing: border-box;
    background-clip: padding-box;
    appearance: none;
    width: auto;
    line-height: 1.4286;
    padding: 10px 10px 10px 10px;
    font-size: 14px;
    border: 0;
    background-size: 24px;
    border: 1px solid #212F63;
    border-width: 1px 1px 2px;
    color: #212F63;
    border-radius: 5px;
    border: none;
    /* background: transparent; */
    font-size: 16px;
    font-weight: 600;
    background: transparent;
    margin-top: 0px;
}
select::-ms-expand {
    display: none
}
* {
    font-family: 'Poppins';
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}

.top-header {
    display: flex;
    padding: 12px 10rem;
    justify-content: space-between;
    background: #EDEDED;
    align-items: center;
}

.telephone a {
    font-size: 16px;
    text-decoration: none;
    color: #24295E;
    font-weight: 600;
}

.order-lists a {
    font-size: 16px;
    text-decoration: none;
    color: #24295E;
    font-weight: 600;
    padding: 10px;
}

.order-lists {
    display: flex;
    align-items: center;
}

nav {
    width: 100vw;
    display: flex;
    z-index: 10;
    justify-content: space-between;
    padding: 0px 10rem;
    align-items: start;
    margin-top: 20px;
}

/* .nav-links a::before {
    content: "";
    background-color: #F86B1F;
    transition: 0.4s ease-out;
    width: 0%;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0px;
} */

.nav-links a:hover::before {
    width: 0%;
}

    /* .nav-links p::before {
        content: "";
        background-color: #F86B1F;
        transition: 0.4s ease-out;
        width: 0%;
        height: 2px;
        position: absolute;
        left: 0;
        bottom: 0px;
    } */

/* .nav-links p:hover::before {
    width: 100%;
} */
.nav-links :hover{
    border-bottom: 1px solid #F86B1F;
    
}
/*Styling logo*/


/*Styling Links*/
/* .nav-links {
    display: flex;
    list-style: none;
    padding: 0 0.7vw;
    justify-content: space-evenly;
    align-items: center;
    text-transform: capitalize;
} */

/* .nav-links li a {
    text-decoration: none;
    margin: 0 0.7vw;
    font-size: 16px;
    color: #212F63;
    font-weight: 500;
} */

.nav-links li a:hover  {
    color: #F86B1F;
    
}
.nav-links p:hover  {
    color: #F86B1F !important;
    cursor: pointer;
}

.nav-links p {
    color: #212F63;
    font-weight: 500;
    cursor: pointer;
}
.nav-links a {
    color: #212F63;
    font-weight: 500;
}



/* .nav-links li {
    position: relative;
    padding: 20px 6px;
} */

.nav-links li a::before {
    content: "";
    display: block;
    height: 3px;
    width: 0%;
    /* background-color: #f05454; */
    position: absolute;
    transition: all ease-in-out 250ms;
    margin: 0 0 0 10%;
    bottom: 0;
}

.nav-links li a:hover::before {
    width: 80%;
}

.toggle,
[id^=drop] {
    display: none;
}



nav ul ul {
    display: none;
    position: absolute;
    top: 60px;
    background: #fff;
    width: 300px;
    list-style: none;
    border-radius: 2px;
    padding-bottom: 15px;
}

nav ul li:hover>ul {
    display: inherit;
}

nav ul ul li {
    width: 100%;
    float: none;
    display: list-item;
    position: relative;
    padding: 7px 0px !important;
}



.hamburger {
    display: none;
}

.logo-design {
    display: none;
}
.nav-links{
    margin-left: 0px;
    width: 100%;
}
/*Stying for small screens*/
@media screen and (max-width: 991px) {
    .logo {
        /* padding: 2vh 3vw; */
        text-align: left;
    }

    .logo img {
        width: 6rem;
    }

    .top-header {
        padding: 6px 1rem;
        align-items: center;
    }

    .custom-select {
        width: 100%;
        display: none;
    }

    .telephone a {
        font-size: 14px;
        text-decoration: none;
        color: #24295E;
        font-weight: 500;
    }

    .order-lists a {
        font-size: 14px;
        font-weight: 500;
        padding: 6px 7px 0px 0px;
    }
    .language-select-box select{
        font-size: 14px;
        font-weight: 500;
        color: #212F63;
    }
    /* .logo img {
        width: 15rem;
    } */

    /* nav {
        position: fixed;
        z-index: 3;
        padding: 0px 0%;
        display: none;

    } */

    /* .hamburger {
        display: block;
        position: absolute;
        cursor: pointer;
        right: 5%;
        top: 50%;
        transform: translate(-5%, -50%);
        z-index: 2;
        transition: all 0.7s ease;
    } */

    /* .nav-links {
        position: fixed;
        background: #131418;
        height: 100vh;
        width: 100%;
        flex-direction: column;
        clip-path: circle(50px at 90% -20%);
        -webkit-clip-path: circle(50px at 90% -10%);
        transition: all 1s ease-out;
        pointer-events: none;
        justify-content: center;
    } */

    .nav-links.open {
        clip-path: circle(1000px at 90% -10%);
        -webkit-clip-path: circle(1000px at 90% -10%);
        pointer-events: all;
        padding: 0px 50px;
    }

    .nav-links li {
        opacity: 0;
    }

    .nav-links li:nth-child(1) {
        transition: all 0.5s ease 0.2s;
    }

    .nav-links li:nth-child(2) {
        transition: all 0.5s ease 0.4s;
    }

    .nav-links li:nth-child(3) {
        transition: all 0.5s ease 0.6s;
    }

    .nav-links li:nth-child(4) {
        transition: all 0.5s ease 0.7s;
    }

    .nav-links li:nth-child(5) {
        transition: all 0.5s ease 0.8s;
    }

    .nav-links li:nth-child(6) {
        transition: all 0.5s ease 0.9s;
        margin: 0;
    }

    .nav-links li:nth-child(7) {
        transition: all 0.5s ease 1s;
        margin: 0;
    }

    li.fade {
        opacity: 1;
    }

    .nav-links li a {
        color: #fff;
    }

    .toggle+a,
    .menu {
        display: none;
    }

    .toggle {
        display: block;
        padding: 14px 0px;
        color: #FFF;
        font-size: 15px;
        text-decoration: none;
        border: none;
    }

    .toggle:hover {
        background-color: transparent;
    }

    [id^=drop]:checked+ul {
        display: block;
        width: 100%;
        background: transparent;
        transition: all 0.3s ease;
    }

    nav ul li {
        display: block;
        width: 100%;
    }

    nav ul ul .toggle,
    nav ul ul a {
        padding: 0 40px;
    }


    nav ul li ul li .toggle,
    nav ul ul a {
        padding: 14px 20px;
        color: #FFF;
        font-size: 17px;
    }
    nav ul ul {
        float: none;
        position: static;
        color: #ffffff;
    }

    nav ul ul li:hover>ul,
    nav ul li:hover>ul {
        display: none;
    }


    nav ul ul li {
        display: block;
        width: 100%;
    }

    .hamburger div {
        background: #30475e;
        margin: 5px;

    }
    /* .logo-toggle-bar {
        display: none;
    } */
    nav{
        padding: 0px 1rem;
        position: fixed;
        top: 24px;
        z-index: 10;
    }
    .mobile-menu-button{
        position: absolute;
        right: 5%;
        transform: translate(-5%, -50%);
        z-index: 2;
        transition: all 0.7s ease;
        margin-right: 10px;
    }
    
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
    nav {
        padding: 0px 3%;                            
    }

    .top-header {
        padding: 6px 1rem;
        align-items: center;
    }

    /* .logo img {
        width: 14rem;
    } */

    .nav-links li a {
        margin: 0 0.7vw;
        font-size: 14px;
    }

    .nav-links {
        display: flex;
        list-style: none;
        width: 100%
    }

    .join-button {
        padding: 0.4rem 0.6rem;
        font-size: 13px;
    }

}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
    .nav-links {
        width: 100%;
    }

    /* .logo img {
        width: 16rem;
    } */
    nav {
        padding: 0px 2rem;
    }

    .center-text-info {
        padding: 11% 12%;
        width: 100%;
        text-align: center;
    }

    #digital-sol .list .box h4 {
        font-size: 20px;
        line-height: 28px;
        padding-bottom: 10px;
    }

    #digital-sol .list .box h3 {
        font-size: 70px;
        line-height: 98px;
    }

    .digital-rev .right span {
        font-size: 32px;
        line-height: 38px;
    }

    .digital-rev .right h2 {
        font-size: 48px;
        line-height: 58px;
        margin: 20px 0px 50px;
    }

    .nav-links {
    display: flex;
    list-style: none;
    gap: 18px;
    padding: 0 0.7vw;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    text-transform: capitalize;
    }

    .top-header {
        padding: 6px 2rem;
    }
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
    .nav-links {
        width: 100%;
    }
    nav {
        padding: 20px 4rem;
        align-items: flex-start;
    }

    /* .nav-links {
        display: flex;
        list-style: none;
        padding: 0 0.7vw;
        justify-content: space-evenly;
        align-items: center;
        text-transform: capitalize;
        flex-wrap: wrap;
    } */
    .nav-links {
        display: flex;
    list-style: none;
    gap: 10px;
    padding: 0 0.7vw;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
    }
    .logo img {
        width: 100%;
    }

    .top-header {
        padding: 6px 4rem;
    }

    #digital-sol .list .box h4 {
        font-size: 20px;
        line-height: 28px;
        padding-bottom: 10px;
    }

    #digital-sol .list .box h3 {
        font-size: 70px;
        line-height: 98px;
    }

    .digital-rev .right span {
        font-size: 32px;
        line-height: 38px;
    }

    .digital-rev .right h2 {
        font-size: 48px;
        line-height: 58px;
        margin: 20px 0px 50px;
    }
}
.toggle .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
    transition: all 0.7s ease;
    width: 0;
}

.toggle .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
}
@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .top-header {
        padding: 10px 4rem;
    }
    .nav-links {
        width: 100%;
    }

    nav {
        padding: 20px 4rem;
    }
    .heading-h1 {
        margin-top: 8%;
        position: relative;
        margin-left: 0px;
        z-index: 1;
        padding: 10px 0rem 50px;
    }
    .nav-links {
    display: flex;
    list-style: none;
    gap: 10px;
    padding: 0 0.7vw;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
    }
}
@media screen and (min-width: 1801px) and (max-width: 1920px) {
    .nav-links {
        display: flex;
    list-style: none;
    /* padding: 0 0.7vw; */
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
    width: 100%;
    }
    .nav-links {
        width: 100%;
    }
}


#span-2{
    overflow-y: scroll;
 
}
