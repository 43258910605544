
.article_main_wrapper{
    padding: var(--whitespace);
}
.article_section a{
    margin-bottom: 30px;
}
.article_main_wrapper > h1{
    /* H --/H2 */
    
        font-family: 'Neue Haas Grotesk Text Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 140%;
        text-align: center;
        /* identical to box height, or 45px */
    
        font-feature-settings: 'pnum' on, 'lnum' on;
    
        /* Black */
    
        color: #414141;
}
.article_section{
     display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px 30px;
    margin-top: 30px;
}

.load_more_text {
    padding: 4px 7px;
    border: 0.5px solid #E1E1E1;
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    /* Primary 01 */
    color: #212F63;
}
@media only screen and (max-width: 767px){
    .article_main_wrapper{
        padding: var(--whitespace);
        margin-top: 5rem;
    }
}