.hero_section_wrapper {
    position: relative;
    padding: 30px 10rem;
}

.hero_image_div {
    position: relative;
}

.hero_heading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: var(--white);
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;

}

.content_section {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: var(--white);
    display: flex;
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    align-items: center;
}

.svg_icon {
    padding: 5px 10px;
    background: rgba(255, 255, 255, 0.12);
    height: fit-content;
}

.btn_section {
    display: flex;
    gap: 20px;
    
}

.btn_section>a>p {


    /* UI/Button */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    text-align: center;
    text-transform: capitalize;

    /* White */

    border: 0.5px solid #FFFFFF;
    padding: 10px 20px;

    /* White */

    color: #FFFFFF;

}


.contact_detail {
    display: flex;
    gap: 20px;
    align-items: center;
}

.contact_number_email>h1 {
    /* H --/H3 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    /* identical to box height, or 34px */


    /* White */

    color: #FFFFFF;
}

.contact_number_email>p {
    /* Body --/Body Reg 14 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */


    color: rgba(255, 255, 255, 0.49);
}

.contact_number_email>p>span {
    /* Body --/Body Reg 14 */
    color: var(--white);
}

@media screen and (max-width: 1250px) {
    .content_section {
        display: block;
        width: 90%;

    }

    .btn_section {
        margin-top: 20px;
        display: flex;
        justify-content: space-around;
    }
}


@media screen and (max-width: 550px) {


    .btn_section>a>p {

        font-size: .9rem;


        padding: 7px 15px;

        /* White */

        color: #FFFFFF;

    }

}
@media screen and (min-width: 1800px) and (max-width: 1920px) {
    .hero_section_wrapper {
        position: relative;
        padding: 30px 0rem;
    }
}
@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .hero_section_wrapper {
        position: relative;
        padding: 30px 0rem;
    }
}
@media screen and (min-width: 1367px) and (max-width: 1600px) {
    .hero_section_wrapper {
        position: relative;
        padding: 30px 0rem;
    }

}
    @media screen and (min-width: 1200px) and (max-width: 1367px) {
        .hero_section_wrapper {
            position: relative;
            padding: 30px 0rem;
        }
    }
    @media screen and (min-width: 1025px) and (max-width: 1200px) {
        .hero_section_wrapper {
            position: relative;
            padding: 30px 0rem;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
        .hero_section_wrapper {
            position: relative;
            padding: 30px 0rem;
        }
    }
    @media screen and (max-width: 767px) {
        .hero_section_wrapper {
            position: relative;
            padding: 30px 0rem;
        }
        .btn_section{
            gap:6px
        }
        .content_section {
            display: block;
            width: 100%;
        }
    }