.about_us_section {
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
    align-items: center;
    padding: 25px 10% 0px;
}
.retailers_card svg{
    width: 56px;
    height: 56px;
}
.retailers_card img{
    width: 56px;
    height: 56px;
}
.top_categgories_card_section svg{
    width: 56px;
    height: 56px;
}
.top_categgories_card_section img{
    width: 56px;
    height: 56px;
}
.about_us_section h2 {
    font-size: 18px;
    color: #6F758B;
    text-transform: uppercase;
    font-weight: 400;
}

.about_us_section h1 {
    font-size: 38px;
    font-weight: 700;
    color: #414141;
    margin-top: 16px;
}

.about_us_section p {
    color: #6F758B;
    font-size: 16px;
    width: 80%;
    text-align: justify;
    line-height: 28px;
    margin-top: 8px;
    font-weight: 400;
}
.about_us_section1 {
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
    align-items: center;
    padding: 25px 10% 0px;
}

.about_us_section1 h2 {
    font-size: 18px;
    color: #6F758B;
    text-transform: uppercase;
    font-weight: 400;
}

.about_us_section1 h1 {
    font-size: 38px;
    font-weight: bold;
    color: #414141;
    margin-top: 16px;
}

.about_us_section1 p {
    color: #6F758B;
    font-size: 16px;
    width: 100%;
    text-align: justify;
    line-height: 28px;
    margin-top: 8px;
    font-weight: 400;
}
.about_us_img_wrapper {
    width: 40%;
}

.about_us_text {
    width: 50%;
}




.benefits_section {
    margin-top: 5%;
}

.benifit_section_wrapper {
    padding: var(--whitespace);
}

.benefits_section h2 {
    font-size: 16px;
    color: #6F758B;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;

}

.benefits_section h3 {
    font-size: 40px;
    color: #414141;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 12px;
}

.benefits_section p {
    font-size: 16px;
    color: #6F758B;
    font-weight: 400;
    text-align: center;
    line-height: 22px;
    margin: 0 auto;
    width: 35%;

}

.benefits_lists {
    display: flex;
    justify-content: space-between;
    margin-top: 2%;

    padding: 0px 5%;
}

/* .benefits_lists div {
    width: 50%;
} */

.benefits_ul_lists {
    background: #E1E1E1;
    padding: 75px 50px 42px 70px;
}

.benefits_ul_lists li {
    color: #414141;
    font-size: 18px;
    font-weight: 500;
    line-height: 48px;
    list-style-type: square;
}

.benefits_lists img {
    width: 100%;
    height: 100%;
}

.benefits_ul_lists ul li::before {
    color: #F86B1F;
    font-size: 18px;
}

.benefits_lists button {
    background: #212F63;
    padding: 10px 30px;
    margin-top: 15%;
    color: white;
    border: none;
    cursor: pointer;
    text-transform: capitalize;
}

.custmer_list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5% 0px;
}

.custmer_list1 {
    border: 1px solid#E1E1E1;
    width: 28%;
    height: 100%;
    padding: 28px;
}

.custmer_img_box {
    display: flex;
    justify-content: start;
    align-items: center;
    column-gap: 14px;
}

.custmer_list1 h3 {
    font-size: 18px;
    color: #414141;
    font-weight: 600;
}

.custmer_list1 h4 {
    color: #6F758B;
    font-size: 16px;
    font-weight: 400;
}

.custmer_list1 p {
    color: #6F758B;
    font-size: 15px;
    font-weight: 400;
    text-align: justify;
    line-height: 22px;
    margin-top: 20px;
}

.custmer_img_box img {
    margin-right: 20px;
    width: 56px;
    height: 56px;
}

/*responsive*/

/* top categgories section wrapper start */

.top_categgories_card_section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 40px;
    margin-top: 30px;
    padding: 0px 5%;
}

.top_categgories_card_section>div {
    width: auto;
    background: var(--light-gray2);
    padding: 17% 10%;
    height: 200px;
    /* display: flex; */
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    margin-top: 20px;
}

.top_categgories_card_section p {
    margin-top: 30px;
}

.top_categgories_card_section>div:hover {
    background-color: var(--dark-orange);
    color: var(--white);
    fill: var(--white);
    stroke: var(--white);
}

.top_categgories_card_section>div:hover svg path {
    fill: white !important;
    transition: all ease 0.3s;
}

.car_logo_section>div:hover img {
    transform: scale(1);
    filter: grayscale(0);
    transition: 0.5s ease-in-out;
    transform: scale(0.8);
}

.car_logo_section img {
    filter: grayscale(1);
    cursor: pointer;
    padding-bottom: 1rem;
}

/* top categgories section wrapper end */



/* retailers section start */

.retailers_details_section_wrapper {
    padding: var(--whitespace);
    background: #F7F7F7;
    margin-top: 28px;
}

.retailers_details_section_wrapper>p {
    /* H --/Subtitle */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    /* Primary 01 */

    color: #212F63;
}

.retailers_details_section_wrapper>h1 {
    /* H --/H2 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;
    /* or 45px */
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;

    /* Black */

    color: #414141;
    max-width: 550px;
    margin: 10px auto;
}

.retailers_details_section_wrapper>h4 {
    /* Body --/Body Reg 16 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */

    text-align: center;

    /* Grey 1 */

    color: #6F758B;
    max-width: 400px;
    margin: 10px auto;
}


.retailers_card_section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(293px, 1fr));
    gap: 40px;
    margin-top: 30px;
    padding: 0 10%;
}

.retailers_card {
    /* White */

    background: #FFFFFF;
    padding: 30px;
}

.retailers_card>h4 {
    /* H --/Title SB 16 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */


    /* Black */

    color: #414141;
}

.retailers_card>p {
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* or 20px */


    /* Grey 1 */

    color: #6F758B;
}

/* retailers section end */




.car_campany_section_wrapper {
    padding: 70px 10%;
}


.car_campany_section_wrapper>p {
    /* H --/Subtitle */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    text-align: center;
    text-transform: uppercase;

    /* Grey 1 */

    color: #6F758B;
}

.car_campany_section_wrapper>h1 {
    /* H --/H1 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;
    /* identical to box height, or 56px */

    text-align: center;

    /* Black */

    color: #414141;
}


.car_logo_section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 60px;
    margin-top: 30px;
}

.car_logo_section>div:hover img {
    transform: scale(1);
    filter: grayscale(0);
    transition: 0.5s ease-in-out;
    transform: scale(1.2);
}

.car_logo_section img {
    filter: grayscale(1);
    cursor: pointer;
    padding-bottom: 1rem;
    width: 100px;
    height: 115px;
}




/* hero bottom start */
.hero_section_wrapper {
    position: relative;
}

.hero_heading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: var(--white);


    /* H --/H1 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;
    /* identical to box height, or 56px */

    display: flex;
    align-items: center;
    text-align: center;

    /* White */

    color: #FFFFFF;

}

.hero_content_section {
    color: var(--white);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: var(--white);
}


.hero_content_section>h1 {

    /* H --/H1 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;
    /* or 56px */

    text-align: center;

    /* White */

    color: #FFFFFF;
}

.hero_content_section>p {

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    text-align: center;

    /* White */

    color: #FFFFFF;
}


.contact_icon_section {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
    width: 800px;

}


.contact_icon_section>section {
    display: flex;
    gap: 20px;
    margin-right: 20px;
}

.sivg_icon {
    padding: 5px 10px;
    /* Primary 02 */

    background: #F86B1F;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact_number>p {
    /* H --/Title SB 13 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;


    /* White */

    color: #FFFFFF;
}

/* hero bottom end */


.contact_number>span {
    /* H --/Title SB 18 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    /* identical to box height, or 22px */


    /* White */

    color: #FFFFFF;
}
.arabic_list_style_box{
   column-gap: 15px;
}
.quotes_image {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -15;
}
.about_us_img_wrapper img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 767px) {

    .about_us_section {
        display: block;
    }

    .about_us_img_wrapper {
        width: 100%;
        margin-top: 20px;
    }

    .about_us_img_wrapper img {
        width: 100%;
    }

    .about_us_text {
        width: 100%;
        margin-top: 20px;
    }

    .about_us_section p {
        color: #6F758B;
        font-size: 14px;
        width: 100%;
        text-align: justify;
        line-height: 24px;
        margin-top: 8px;
        font-weight: 400;
    }

    .about_us_section1 h3{
        margin-top: 0px;
        font-size: 28px;
    }
    .benefits_section h3 {
        font-size: 24px;
        color: #414141;
        font-weight: 600;
        text-transform: capitalize;
        text-align: center;
        margin-top: 10px;
    }
    .car_campany_section_wrapper {
        padding: 16px 10%;
    }
    .car_campany_section_wrapper>h1{
        font-size: 24px;
    }

    .benefits_lists {
        display: block;
    }

    .benefits_lists div {
        width: 100%;
        margin-bottom: 20px;
        margin-top: 20px;

    }
    .hero_content_section>p{
        font-size: 14px;
    }
    .contact_icon_section{
        display: block;
        width: 100%;
    }
    .contact_icon_section>section {
        display: flex;
        gap: 20px;
        margin-right: 20px;
        margin-bottom: 16px;
    }
    .hero_content_section>h1{
        font-size: 24px;
    }
    .retailers_card_section{
        padding: 0px;
    }
    .benefits_section {
        margin-top: 5%;
        padding: 0px 22px;
    }
    .contact_number>span{
        font-size: 14px;
    }
    .hero_content_section {
        color: var(--white);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: var(--white);
        width: 90%;
        margin: 8px;
    }
    .benefits_ul_lists {
        padding: 24px;
        list-style-type: none;
    }

    .benefits_ul_lists li {
        color: #414141;
        font-size: 13px;
        font-weight: 500;
        line-height: 24px;
        list-style-type: none;
    }
    .list_style_box {
        width: 100%;
        display: flex;
        align-items: center;
    }
    .benefits_lists button{
        margin-top: 5%;
    }
    .benefits_section p {
        font-size: 14px;
        color: #6F758B;
        font-weight: 400;
        text-align: center;
        line-height: 22px;
        margin-top: 10px;
        width: 100%;
    }
  
    .top_categgories_card_section>div {
        width: auto;
        background: var(--light-gray2);
        padding: 10% 10%;
        height: 200px;
        /* display: flex; */
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        margin-top: 20px;
    }
    .custmer_list {
        display: block;
    }

    .custmer_list1 {
        width: 100%;
        margin-bottom: 20px;
    }

    .custmer_list1 p {
        color: #6F758B;
        font-size: 14px;
        font-weight: 400;
    }

    .about_us_img_wrapper {
        position: relative;
        z-index: 0;
    }

    .border_image {
        position: absolute;
        top: 0;
        left: 7%;
        z-index: -25;
    }

    .border_image1 {
        position: absolute;
        top:0;
        z-index: -85;
        right: 7%;
    }
.about_us_section1{
    display: flex;
    flex-direction: column-reverse;
}
.about_us_section1 p{
    width: 100%;
    font-size: 14px;
    line-height: 24px;
}
.about_us_section h3{
    margin-top: 0px;
    font-size: 28px;
}
.retailers_details_section_wrapper>h1{
    font-size: 24px;
}
.retailers_details_section_wrapper>h4{
    font-size: 14px;
}
.benifit_section_wrapper {
    padding: 16px;
}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

    .about_us_section {
        display: flex;
        padding: 20px 3rem;
    }

    .about_us_img_wrapper {
        width: 48%;
        margin-top: 20px;
    }

    .about_us_img_wrapper img {
        width: 90%;
    }
    .about_us_section1{
        padding: 20px 3rem;
    }
    .about_us_text {
        margin-top: 20px;
        width: 48%;
    }
    .about_us_section1 p{
        width: 100%;
    }

    .about_us_section p {
        color: #6F758B;
        font-size: 14px;
        width: 90%;
        text-align: justify;
        line-height: 28px;
        margin-top: 8px;
        font-weight: 400;
    }

    .benefits_section h3 {
        font-size: 28px;
        color: #414141;
        font-weight: 600;
        text-transform: capitalize;
        text-align: center;
        margin-top: 10px;
    }

    .benefits_section {
        margin-top: 30px;
    }

    .benefits_lists {
        display: flex;
    }

    .benefits_lists div {
        width: 100%;
        margin-bottom: 20px;

    }

    .benefits_ul_lists {
        padding: 40px;
    }

    .benefits_ul_lists li {
        color: #414141;
        font-size: 13px;
        font-weight: 500;
        line-height: 37px;
        list-style-type: square;
    }

    .benefits_section p {
        font-size: 14px;
        color: #6F758B;
        font-weight: 400;
        text-align: center;
        line-height: 22px;
        margin-top: 10px;
    }

    .custmer_list {
        display: flex;
    }

    .custmer_list1 {
        width: 100%;
        margin-bottom: 20px;
    }

    .custmer_list1 p {
        color: #6F758B;
        font-size: 13px;
        font-weight: 400;
    }

    .border_image {
        position: absolute;
        top: 0%;
        left: 18%;
        z-index: -25;
    }

    .border_image1 {
        position: absolute;
        top: 0%;
        z-index: -85;
        right: 15%;
    }

    .about_us_img_wrapper {
        position: relative;
        z-index: 0
    }

    .top_categgories_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(145px, 1fr));
        gap: 12px;
        margin-top: 30px;
        padding: 0px 5%;
    }

    .retailers_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(205px, 1fr));
        gap: 20px;
        margin-top: 30px;
        padding: 0 10%;
    }

    .benefits_ul_lists {
        background: #E1E1E1;
        padding: 28px;
    }

    .list_style_box {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .list_style_box li {
       
        list-style: none;
    }

    .custmer_list1 {
        border: 1px solid#E1E1E1;
        width: 32%;
        height: 100%;
        padding: 12px;
        position: relative;
        z-index: 42;
        height: 270px;
    }

    .quotes_image {
        position: absolute;
        right: 0;
        top: 0;
        z-index: -10;
    }

    .car_logo_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        gap: 30px;
        margin-top: 30px;
        text-align: center;
    }

    .car_logo_section>div:hover img {
        transform: scale(1);
        filter: grayscale(0);
        transition: 0.5s ease-in-out;
        transform: scale(1.2);
    }

    .custmer_list1 h3 {
        font-size: 15px;

    }
    .custmer_list1 h4 {
        color: #6F758B;
        font-size: 15px;
}
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {

    .about_us_img_wrapper img {
        width: 100%;
    }

    .benefits_ul_lists {
        width: 50%;
    }

    .benifit_section_wrapper_image {
        width: 50%;
    }

    .about_us_img_wrapper {
        position: relative;
        z-index: 0;
    }

    .border_image {
        position: absolute;
        top: 0%;
        left: 18%;
        z-index: -25;
    }

    .border_image1 {
        position: absolute;
        top: 0%;
        z-index: -85;
        right: 15%;
    }

    .about_us_section p {
        color: #6F758B;
        font-size: 14px;
        width: 100%;
        text-align: justify;
        line-height: 28px;
        margin-top: 8px;
        font-weight: 400;
    }

    .custmer_list1 p {
        color: #6F758B;
        font-size: 14px;
        font-weight: 400;
        text-align: justify;
        line-height: 22px;
        margin-top: 20px;
    }

    .custmer_list1 h4 {
        color: #6F758B;
        font-size: 14px;
        font-weight: 400;
    }

    .benefits_ul_lists li {
        color: #414141;
        font-size: 16px;
        font-weight: 500;
        line-height: 42px;
        list-style-type: none;
    }

    .list_style_box {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .list_style_box li {
        margin-left: 10px;
    }

    .custmer_list1 {
        border: 1px solid#E1E1E1;
        width: 32%;
        height: 100%;
        padding: 28px;
        position: relative;
        z-index: 42;
    }

    .quotes_image {
        position: absolute;
        right: 0;
        top: 0;
        z-index: -10;
    }

    .car_logo_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
        gap: 60px;
        margin-top: 30px;
        text-align: center;
    }

    .car_logo_section>div:hover img {
        transform: scale(1);
        filter: grayscale(0);
        transition: 0.5s ease-in-out;
        transform: scale(1.2);
    }

    .retailers_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(205px, 1fr));
        gap: 20px;
        margin-top: 30px;
        padding: 0 10%;
    }

    .benefits_ul_lists {
        background: #E1E1E1;
        padding: 75px 50px 42px 45px;
    }

    .top_categgories_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(145px, 1fr));
        gap: 12px;
        margin-top: 30px;
        padding: 0px 5%;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1366px) {

    .about_us_img_wrapper img {
        width: 100%;
    }

    .benefits_ul_lists {
        width: 50%;
    }

    .benifit_section_wrapper_image {
        width: 50%;
    }

    .about_us_img_wrapper {
        position: relative;
        z-index: 0;
    }

    .border_image {
        position: absolute;
        top: 0%;
        left: 18%;
        z-index: -25;
    }

    .border_image1 {
        position: absolute;
        top: 0%;
        z-index: -85;
        right: 15%;
    }

    .about_us_section p {
        color: #6F758B;
        font-size: 14px;
        width: 100%;
        text-align: justify;
        line-height: 28px;
        margin-top: 8px;
        font-weight: 400;
    }

    .custmer_list1 p {
        color: #6F758B;
        font-size: 14px;
        font-weight: 400;
        text-align: justify;
        line-height: 22px;
        margin-top: 20px;
    }

    .custmer_list1 h4 {
        color: #6F758B;
        font-size: 14px;
        font-weight: 400;
    }

    .benefits_ul_lists li {
        color: #414141;
        font-size: 16px;
        font-weight: 500;
        line-height: 42px;
        list-style-type: none;
    }

    .list_style_box {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .list_style_box li {
        margin-left: 10px;
    }

    .custmer_list1 {
        border: 1px solid#E1E1E1;
        width: 32%;
        height: 100%;
        padding: 28px;
        position: relative;
    }

    .quotes_image {
        position: absolute;
        right: 0;
        top: 0;
    }

    .car_logo_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
        gap: 60px;
        margin-top: 30px;
        text-align: center;
    }

    .car_logo_section>div:hover img {
        transform: scale(1);
        filter: grayscale(0);
        transition: 0.5s ease-in-out;
        transform: scale(1.2);
    }

    .retailers_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        gap: 20px;
        margin-top: 30px;
        padding: 0 10%;
    }

    .benefits_ul_lists {
        background: #E1E1E1;
        padding: 75px 50px 42px 45px;
    }
}

@media screen and (min-width: 1367px) and (max-width: 1600px) {
    .benefits_ul_lists {
        width: 50%;
    }

    .benifit_section_wrapper_image {
        width: 50%;
    }

    .about_us_img_wrapper {
        position: relative;
        z-index: 0;
    }

    .border_image {
        position: absolute;
        top: 0%;
        left: 12%;
        z-index: -25;
    }
    .arabic_border_image{
        left: 32px;
    }

    .border_image1 {
        position: absolute;
        top: 0%;
        z-index: -85;
        right: 18%;
    }
    .arabic_border_image1{
        right: 0px;
    }

    .about_us_section p {
        color: #6F758B;
        font-size: 14px;
        width: 100%;
        text-align: justify;
        line-height: 28px;
        margin-top: 8px;
        font-weight: 400;
    }

    .custmer_list1 p {
        color: #6F758B;
        font-size: 14px;
        font-weight: 400;
        text-align: justify;
        line-height: 22px;
        margin-top: 20px;
    }

    .custmer_list1 h4 {
        color: #6F758B;
        font-size: 14px;
        font-weight: 400;
    }

    .benefits_ul_lists li {
        color: #414141;
        font-size: 16px;
        font-weight: 500;
        line-height: 42px;
        list-style-type: none;
    }

    .list_style_box {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .list_style_box li {
        margin-left: 10px;
    }

    .custmer_list1 {
        border: 1px solid#E1E1E1;
        width: 32%;
        height: 100%;
        padding: 28px;
        position: relative;
    }

    .quotes_image {
        position: absolute;
        right: 0;
        top: 0;
    }

    .car_logo_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
        gap: 60px;
        margin-top: 30px;
        text-align: center;
    }

    .car_logo_section>div:hover img {
        transform: scale(1);
        filter: grayscale(0);
        transition: 0.5s ease-in-out;
        transform: scale(1.2);
    }

    .retailers_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(295px, 1fr));
        gap: 20px;
        margin-top: 30px;
        padding: 0 10%;
    }

    .benefits_ul_lists {
        background: #E1E1E1;
        padding: 75px 50px 42px 45px;
    }
}


@media screen and (min-width: 1601px) and (max-width: 1800px) {

    .about_us_img_wrapper img {
        width: 100%;
    }

    .about_us_section p {
        color: #6F758B;
        font-size: 14px;
        width: 100%;
        text-align: justify;
        line-height: 28px;
        margin-top: 8px;
        font-weight: 400;
    }

    .custmer_list1 p {
        color: #6F758B;
        font-size: 14px;
        font-weight: 400;
        text-align: justify;
        line-height: 22px;
        margin-top: 20px;
    }

    .custmer_list1 h4 {
        color: #6F758B;
        font-size: 14px;
        font-weight: 400;
    }

    .benefits_ul_lists li {
        color: #414141;
        font-size: 16px;
        font-weight: 500;
        line-height: 42px;
        list-style-type: square;
    }

    .custmer_list1 {
        border: 1px solid#E1E1E1;
        width: 32%;
        height: 100%;
        padding: 28px;
    }

    .about_us_img_wrapper {
        position: relative;
        z-index: 0;
    }

    .border_image {
        position: absolute;
        top: 0%;
        left: 18%;
        z-index: -25;
    }

    .border_image1 {
        position: absolute;
        top: 0%;
        z-index: -85;
        right: 18%;
    }

    .about_us_section p {
        color: #6F758B;
        font-size: 14px;
        width: 100%;
        text-align: justify;
        line-height: 28px;
        margin-top: 8px;
        font-weight: 400;
    }

    .custmer_list1 p {
        color: #6F758B;
        font-size: 14px;
        font-weight: 400;
        text-align: justify;
        line-height: 22px;
        margin-top: 20px;
    }

    .custmer_list1 h4 {
        color: #6F758B;
        font-size: 14px;
        font-weight: 400;
    }

    .benefits_ul_lists li {
        color: #414141;
        font-size: 18px;
        font-weight: 500;
        line-height: 50px;
        list-style-type: none;
    }

    .list_style_box {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .list_style_box li {
        margin-left: 15px;
    }

    .custmer_list1 {
        border: 1px solid#E1E1E1;
        width: 32%;
        height: 100%;
        padding: 28px;
        position: relative;
    }

    .quotes_image {
        position: absolute;
        right: 0;
        top: 0;
    }

    .car_logo_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
        gap: 40px;
        margin-top: 30px;
        text-align: center;

    }

    .car_logo_section>div:hover img {
        transform: scale(1);
        filter: grayscale(0);
        transition: 0.5s ease-in-out;
        transform: scale(1.2);
    }

    .retailers_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
        gap: 20px;
        margin-top: 30px;
        padding: 0 10%;
    }

    .benefits_ul_lists {
        background: #E1E1E1;
        padding: 75px 50px 42px 45px;
        width: 50%;
    }

    .benifit_section_wrapper_image {
        width: 50%;
    }
}

@media screen and (min-width: 1801px) and (max-width: 1920px) {

    .about_us_img_wrapper img {
        width: 100%;
        height: 100%;
    }

    .about_us_section p {
        color: #6F758B;
        font-size: 14px;
        width: 100%;
        text-align: justify;
        line-height: 28px;
        margin-top: 8px;
        font-weight: 400;
    }

    .custmer_list1 p {
        color: #6F758B;
        font-size: 14px;
        font-weight: 400;
        text-align: justify;
        line-height: 22px;
        margin-top: 20px;
    }

    .custmer_list1 h4 {
        color: #6F758B;
        font-size: 14px;
        font-weight: 400;
    }

    .benefits_ul_lists li {
        color: #414141;
        font-size: 16px;
        font-weight: 500;
        line-height: 42px;
        list-style-type: square;
    }

    .custmer_list1 {
        border: 1px solid#E1E1E1;
        width: 32%;
        height: 100%;
        padding: 28px;
    }

    .about_us_img_wrapper {
        position: relative;
        z-index: 0;
    }

    .border_image {
        position: absolute;
        top: 0%;
        left: 18%;
        z-index: -25;
    }

    .border_image1 {
        position: absolute;
        top: 0;
        z-index: -85;
        right: 18%;
    }

    .about_us_section p {
        color: #6F758B;
        font-size: 14px;
        width: 100%;
        text-align: justify;
        line-height: 28px;
        margin-top: 8px;
        font-weight: 400;
    }

    .custmer_list1 p {
        color: #6F758B;
        font-size: 14px;
        font-weight: 400;
        text-align: justify;
        line-height: 22px;
        margin-top: 20px;
    }

    .custmer_list1 h4 {
        color: #6F758B;
        font-size: 14px;
        font-weight: 400;
    }

    .benefits_ul_lists li {
        color: #414141;
        font-size: 18px;
        font-weight: 500;
        line-height: 50px;
        list-style-type: none;
    }

    .list_style_box {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .list_style_box li {
        margin-left: 15px;
    }

    .custmer_list1 {
        border: 1px solid#E1E1E1;
        width: 32%;
        height: 100%;
        padding: 28px;
        position: relative;
    }

    .quotes_image {
        position: absolute;
        right: 0;
        top: 0;
    }

    .car_logo_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
        gap: 40px;
        margin-top: 30px;
        text-align: center;

    }

    .car_logo_section>div:hover img {
        transform: scale(1);
        filter: grayscale(0);
        transition: 0.5s ease-in-out;
        transform: scale(1.2);
    }

    .retailers_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
        gap: 20px;
        margin-top: 30px;
        padding: 0 10%;
    }

    .benefits_ul_lists {
        background: #E1E1E1;
        padding: 75px 50px 42px 45px;
        width: 50%;
    }

    .benifit_section_wrapper_image {
        width: 50%;
    }
}

@media screen and (min-width: 1921px) and (max-width: 2560px) {

    .about_us_img_wrapper img {
        width: 100%;
    }

    .about_us_section p {
        color: #6F758B;
        font-size: 14px;
        width: 100%;
        text-align: justify;
        line-height: 28px;
        margin-top: 8px;
        font-weight: 400;
    }

    .custmer_list1 p {
        color: #6F758B;
        font-size: 14px;
        font-weight: 400;
        text-align: justify;
        line-height: 22px;
        margin-top: 20px;
    }

    .custmer_list1 h4 {
        color: #6F758B;
        font-size: 14px;
        font-weight: 400;
    }

    .benefits_ul_lists li {
        color: #414141;
        font-size: 16px;
        font-weight: 500;
        line-height: 42px;
        list-style-type: square;
    }

    .custmer_list1 {
        border: 1px solid#E1E1E1;
        width: 32%;
        height: 100%;
        padding: 28px;
    }

    .about_us_img_wrapper {
        position: relative;
        z-index: -24;
    }

    .border_image {
        position: absolute;
        top: 0%;
        left: 18%;
        z-index: -25;
    }

    .border_image1 {
        position: absolute;
        top: 0%;
        z-index: -85;
        right: 18%;
    }

    .about_us_section p {
        color: #6F758B;
        font-size: 14px;
        width: 100%;
        text-align: justify;
        line-height: 28px;
        margin-top: 8px;
        font-weight: 400;
    }

    .custmer_list1 p {
        color: #6F758B;
        font-size: 14px;
        font-weight: 400;
        text-align: justify;
        line-height: 22px;
        margin-top: 20px;
    }

    .custmer_list1 h4 {
        color: #6F758B;
        font-size: 14px;
        font-weight: 400;
    }

    .benefits_ul_lists li {
        color: #414141;
        font-size: 18px;
        font-weight: 500;
        line-height: 50px;
        list-style-type: none;
    }

    .list_style_box {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .list_style_box li {
        margin-left: 15px;
    }

    .custmer_list1 {
        border: 1px solid#E1E1E1;
        width: 32%;
        height: 100%;
        padding: 28px;
        position: relative;
    }

    .quotes_image {
        position: absolute;
        right: 0;
        top: 0;
    }

    .car_logo_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 40px;
        margin-top: 30px;
        text-align: center;

    }

    .car_logo_section>div:hover img {
        transform: scale(1);
        filter: grayscale(0);
        transition: 0.5s ease-in-out;
        transform: scale(1.2);
    }

    .retailers_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
        gap: 20px;
        margin-top: 30px;
        padding: 0 10%;
    }

    .benefits_ul_lists {
        background: #E1E1E1;
        padding: 75px 50px 42px 45px;
        width: 50%;
    }

    .benifit_section_wrapper_image {
        width: 50%;
    }
}

/* @media screen and (max-width: 820px){
/* 
.contact_icon_section {
        display: flex;
        justify-content: space-evenly;
        margin-top: 30px;
        width: 800px;

    }
} */

/* @media screen and (min-width: 1801px) and (max-width: 1920px) {
        .main_wrapper{
            padding: 20px 8rem
        }
        .about_us_img_wrapper img{
            width: 100%;
        }
        .about_us_section p {
            color: #6F758B;
            font-size: 14px;
            width: 100%;
            text-align: justify;
            line-height: 28px;
            margin-top: 8px;
            font-weight: 400;
        }
        .custmer_list1 p {
            color: #6F758B;
            font-size: 14px;
            font-weight: 400;
            text-align: justify;
            line-height: 22px;
            margin-top: 20px;
        }
        .custmer_list1 h4 {
            color: #6F758B;
            font-size: 14px;
            font-weight: 400;
        }
        .benefits_ul_lists li {
            color: #414141;
            font-size: 16px;
            font-weight: 500;
            line-height: 42px;
            list-style-type: square;
        }
        .custmer_list1 {
            border: 1px solid#E1E1E1;
            width: 33%;
            height: 100%;
            padding: 28px;
        }
    } */