

.input_Wrapper{
    margin-bottom: 20px;
}
#pet-select{
    display: none!important;
}
.input_Wrapper > label {
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    
}
#SELECT_bOX select{
    height: 48px!important;
    margin-top: 6px;
}
select{
    height: 48px;
    margin-top: 5px;
    padding: 5px;
}
.input_Wrapper > input {
    background: var(--white);
    border: 0.5px solid #E1E1E1;
    padding: 5px;
    width: 100%;
    margin-top: 6px;
    height: 48px;
    outline: none;
}

.input_Wrapper > input::placeholder {
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #6F758B;
    padding-left: 6px;
}
textarea::placeholder{
    border: none !important;
    color: #6F758B    ;
    font-size: 15px;
   
}
textarea{
    overflow: hidden;
    padding: 5px 5px!important;
}
