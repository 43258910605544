.main_wrapper{
    padding: 20px 18rem;

}
.article_section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 5%;
    margin-bottom: 5%;
}
.article_socail_icons{
    width: 25%;
}
.article_content{
    width: 75%;
}
.article_socail_icons i{
    background: #212F63;
    padding: 0px;
    height: 35px;
    width: 35px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    margin-bottom: 26px;
}
.article_content p{
    color: #6F758B;
    font-size: 16px;
    line-height: 28px;
    text-align: justify;
}
.article_News_section{
    margin-left: 25%;
}
.article_text h2{
    font-size: 20px;
    font-weight: 600;
    color: #414141;
    text-transform: capitalize;
    line-height: 32px;
}
.article_text p{
    color: #6F758B;
    font-size: 16px;
    margin-top: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: justify;
}
.article_img_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 35px;
    margin-bottom: 35px;
}
.article_img_box div{
    width: 48%;
}
.article_img_box img{
    width: 100%;
}
.article_News_section p{
    color: #6F758B;
    font-size: 16px;
    margin-top: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: justify;
}
.article_news_text h3{
    font-size: 18px;
    font-weight: 600;
    color: #414141;
    line-height: 30px;
}
.article_news_text{
    margin-top: 30px;
}
.article_quotes h4{
    font-size: 16px;
    font-weight: 700;
    color: #414141;
}
.article_quotes{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    border-bottom: 1px solid#E1E1E1;
    padding-bottom: 28px;
}
.article_quotes p{
    font-size: 14px;
    color: #6F758B;
    font-weight: 400;
    text-align: justify;
}
.article_quotes i{
    font-size: 35px;
    color: #E1E1E1;
    margin-right: 40px;

}
.article_News_socail_icons{
    display: flex;
    margin-top: 50px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
}
.article_News_socail_icons h4{
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: #414141;
    margin-right: 30px;
    cursor: pointer;
}
.article_News_socail_icons i{
    background: #212F63;
    padding: 0px;
    height: 35px;
    width: 35px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    margin-left: 14px;
}





.related_article_news_section>h1 {
    /* H --/H2 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;
    text-align: center;
    /* identical to box height, or 45px */

    font-feature-settings: 'pnum' on, 'lnum' on;

    /* Black */

    color: #414141;
    margin-top: 6%;
}

.related_article_news_card {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px 30px;
    margin-top: 30px;
}
.news_related_article_news_section{
    margin-top: 5%;
}
@media screen and (max-width: 767px) {
    .main_wrapper{
        padding: 20px 2rem;
        margin-top: 5rem;
    }
    .article_socail_icons{
        width: 100%;
        display: flex;
        justify-content: center;
        column-gap: 14px;
    }
    .article_content{
        width: 100%;
    }
    .article_News_section{
        margin-left: 0%;
    }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .main_wrapper{
        padding: 20px 3rem;
    }
    .article_socail_icons{
        width: 100%;
    }
    .article_content{
        width: 100%;
    }
    .article_News_section{
        margin-left: 0%;
    }
}
@media screen and (min-width: 1201px) and (max-width: 1366px) {
    .main_wrapper{
        padding: 20px 5rem;
    }
}
@media screen and (min-width: 1367px) and (max-width: 1600px) {
    .main_wrapper{
        padding: 20px 7rem;
    }
}