.card_heading {
    text-align: center;
}

.shadow-sm h5 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #212F63;

}

.shadow-sm span {
    color: #BFC2CC;
    font-size: 18px;
    font-weight: 500;
    margin-left: 5px;
}

@media screen and (max-width: 767px) {
.shadow-sm h5 {
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    color: #212F63;
}

.shadow-sm span {
    color: #BFC2CC;
    font-size: 13px;
    font-weight: 500;
    margin-left: 5px;
}
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .shadow-sm h5 {
        text-align: center;
        font-size: 13px;
        font-weight: 600;
        color: #212F63;
    }

    .shadow-sm span {
        color: #BFC2CC;
        font-size: 13px;
        font-weight: 500;
        margin-left: 5px;
    }
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .shadow-sm h5 {
        text-align: center;
        font-size: 13px;
        font-weight: 600;
        color: #212F63;
    }

    .shadow-sm span {
        color: #BFC2CC;
        font-size: 13px;
        font-weight: 500;
        margin-left: 5px;
    }
}
@media screen and (min-width: 1201px) and (max-width: 1366px) {
    .shadow-sm h5 {
        text-align: center;
        font-size: 13px;
        font-weight: 600;
        color: #212F63;
    }

    .shadow-sm span {
        color: #BFC2CC;
        font-size: 13px;
        font-weight: 500;
        margin-left: 5px;
    }
}

@media screen and (min-width: 1367px) and (max-width: 1600px) {
    .shadow-sm h5 {
        text-align: center;
        font-size: 13px;
        font-weight: 600;
        color: #212F63;
    }

    .shadow-sm span {
        color: #BFC2CC;
        font-size: 13px;
        font-weight: 500;
        margin-left: 5px;
    }
}