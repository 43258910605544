.CustomersReviews_heading > h1{
    font-size: 30px;
    font-weight: 600;
    color: #414141;
    text-align: center;
}
.CustomersReviews_heading{
    margin-top: 5%;
}

.main_wrapper{
    padding: 20px 10rem;
}

.heading{
    font-size: 30px;
    font-weight: 600;
    color: #414141;
    text-align: center;
    margin-top: 4%;
}
.CustomersReviews_section{
    background: #F7F7F7;
    padding: 40px;
    margin-top: 3%;
}
.Reviews_section_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Reviews_section_box h2{
    color: #414141;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
}
.Reviews_section_box i{
    color: #212F63;
    font-size: 18px;
    margin-right: 5px;
}
.CustomersReviews_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 45px;
    border-bottom: 1px solid#B8C5D0;
    padding-bottom: 45px;
}
.reviews_text{
    width: 70%;
}
.reviews_content h2{
    font-size: 22px;
    font-weight: 700;
    text-transform: capitalize;
    color: #414141;
}
.reviews_content p{
    font-size: 16px;
    color: #6F758B;
    font-weight: 500;
    line-height: 28px;
    text-transform: capitalize;
}
.reviews_content i{
    color: #6F758B  ;
    font-size: 18px;
    margin-right: 5px;
    line-height: 24px;
    margin-top: 30px;
}
.reviews_content span{
    color: #6F758B  ;
    font-size: 16px;
    margin-right: 5px;
    line-height: 24px;
    margin-top: 30px;
    margin-left: 12px;
}
.reviews_text p{
    font-size: 16px;
    color: #6F758B;
    font-weight: 400;
    text-align: justify;
    line-height: 28px;

}

.bottom_hero_section{
    padding: var(--whitespace);
}

.load_more_text {
    padding: 4px 7px;
    border: 0.5px solid #E1E1E1;
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* Primary 01 */

    color: #212F63;
}
@media screen and (max-width: 767px) {
    .main_wrapper{
        padding: 20px 2rem;
        margin-top: 5rem;
    }
    .heading{
        text-align: left;
    }
    .CustomersReviews_section{
        padding: 24px;
    }
    .Reviews_section_box h2 {
        font-size: 16px;
        line-height: 34px;
    }
    .CustomersReviews_wrapper{
        display: block;
        margin-top: 27px;
        padding-bottom: 27px;
    }
    .reviews_text{
        width: 100%;
    }
  
        .load_more_text{
            width: 32%;
        }
        
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .main_wrapper{
        padding: 20px 3rem;
    }
    .reviews_text{
        width: 60%;
    }
}