.card_wrapper{
    padding: 30px 5%;
}
.heading{
    /* H --/Subtitle */
    
        font-family: 'Neue Haas Grotesk Text Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
    
        display: flex;
        align-items: center;
        text-transform: uppercase;
    
        /* Grey 1 */
    
        color: #6F758B;
}

.heading_description{
    font-family: 'Neue Haas Grotesk Text Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 140%;
        /* identical to box height, or 56px */
    
    
        /* Black */
    
        color: #414141;
}

.form > section{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.form_and_social_login_btn_section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
}

.create_login_btn{
   display: flex;
   gap: 30px;
   display: -webkit-box;
   margin: 30px 0;
}
.register_btn1{
    padding: 10px 50px;
    border: 1px solid var(--dark-brinjal) !important;
    background-color: var(--white);
    border: none;
    font-size: .8rem;
    cursor: pointer;
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-transform: capitalize;
    color: var(--dark-brinjal);
}
.agree_text{
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: #6F758B;
}

.agree_text > b{
    font-weight: 700;
    margin-left: 2px;
}
body.detailsModal{
    overflow: hidden!important;
}



@media screen and (max-width: 650px) {
    .form_and_social_login_btn_section{
        grid-template-columns: 1fr;
    }

    .heading{
        font-size: .8rem;
    }
}
@media screen and (max-width: 767px) {
    .heading_description{
        font-size: 24px;
    }
}

