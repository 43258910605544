.hero_section_wrapper{
    position: relative;
    margin-top: 22px;
   
}

.hero_heading{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: var(--white);


    /* H --/H1 */
    
        font-family: 'Neue Haas Grotesk Text Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 140%;
        /* identical to box height, or 56px */
    
        display: flex;
        align-items: center;
        text-align: center;
    
        /* White */
    
        color: #FFFFFF;

}
@media screen and (max-width: 767px) {
    .hero_heading {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: var(--white);
        font-family: 'Neue Haas Grotesk Text Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        width: 100%;
        padding: 0px 10px;
        justify-content: center;
    }
    .hero_section_wrapper{
        margin-top: 6rem;
    }
}