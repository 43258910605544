.hero_heading {
    color: white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.main_wrapper{
    margin-top: 3%;
}
.subcategories_section_wrapper {
    padding: var(--whitespace);
}

.subcategories_product_card_section {
    padding: var(--whitespace);
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 50px;
}

.subscribe_cateory h4 {
    font-size: 20px;
    font-weight: 500;
    color: #212F63;
    text-transform: capitalize;
}

.subscribe_cateory {
    text-align: center;
    margin-top: 3%;
}

.subscribe_cateory i {
    margin-left: 10px;
}
.select_product select {
    outline: 0;
    border: 1px solid #ede8e8;
    box-shadow: none;
    padding: 0 1em;
    color: #6F758B;
    cursor: pointer;
    width: 100%;
    height: 40px;
}
.select_product{
    width: 24%;
    height: 48px;
}
/* Remove IE arrow */
select::-ms-expand {
    display: none;
}
/* Custom Select wrapper */
.select {
    outline: 0;
    box-shadow: none;
    padding: 0 1em;
    color: #6F758B;
    cursor: pointer;
    width: 100%;
    position: relative;
    height: 40px;
}

/* Arrow */
.select::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 1em;
    background-color: transparent;
    transition: .25s all ease;
    pointer-events: none;
    color: transparent;
}



.select_boxes_lists {
    display: flex;
    justify-content: space-between;
    padding: 20px 10rem;
    margin-top: 5%;
}

.select_boxes_lists button {
    padding: 8px 40px;
    background: #212f63;
    border: none;
    color: white;
    font-size: 15px;
    font-weight: 500;
    height: 40px;
    margin-top: 5px;
}

/*filter-section-start*/
.filter_section {
    padding: 20px 10rem;
    margin-top: 2%;
}
.products_items_boxes_lists{
    display: flex;
    justify-content: space-between;
}
.products_items h3 {
    color: #414141;
    font-size: 20px;
    font-weight: 600;
}

.products_items p {
    font-size: 16px;
    color: #414141;
    font-weight: 500;
    margin-top: 2%;
    text-transform: uppercase;
}

.products_items button {
    font-size: 15px;
    width: 300px;
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    border: none;
    background: #E1E1E1;
    color: #6F758B;
    margin-top: 20px;
}

.products_items i {
    color: #212F63;
    font-size: 18px;
}
.products_items{
    border-bottom: 1px solid #E1E1E1;
    padding-bottom: 25px;
}

.serach_bar_box{
    border-bottom: 1px solid #E1E1E1;
    padding-bottom: 25px;
}
.search {
    position: relative;
    color: #aaa;
    font-size: 16px;
}

.search {
    display: inline-block;
}

.search input {
    width: 300px;
    height: 48px;
    background: transparent;
    border: 1px solid #E1E1E1;
    margin-top: 20px;
}

.search input {
    text-indent: 16px;
}

.search i {
    position: absolute;
    top: 35px;
    left: 10px;
    color: #212F63;
}

.search i {
    left: 10px;
    
}
.serach_bar_box h3{
    color: #414141;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 30px;
}
.Accordion_list h3{
    font-size: 18px;
    color: #414141;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
}
.Accordion_list{
    border-bottom: 1px solid #E1E1E1;
}
.Accordion_list i{
    color: #212F63;
    font-size: 20px;
}
.Accordion_list div{
    /* display: flex; */
    align-items: center;
    margin-top: 8px;
    column-gap: 8px;
}
.Accordion_list label{
    color: #414141;
    font-size: 18px;
    font-weight: 500;
    margin-left: 25px;
}
.check_box_list i{
    color: #E1E1E1;
    font-size: 24px;
}
.check_box_list_blue{
    color: #212F63;
}
.price_list {
    color: #414141;
    display: block !important;
    cursor: pointer;
    padding-left: 40px;
   
    margin-bottom: 20px;
  }
  
  .price_list p {
    margin: 0; /* Remove margins from paragraphs */
    padding: 5px 0;
  }
  
.dropdown_lists{
    width: 70%;
}
.dropdown_lists .select {
    border-bottom: 1px solid #E1E1E1    ;
    width: 48%;
    margin: 0px 5px;
    padding: 0px;
}
.dropdown_lists select {
    /* border-bottom: 1px solid #E1E1E1!important; */
    border: 0;
    height: 35px;
    padding: 0px;
    
    display: flex;
    flex-direction: column;


}

.dropdown_lists1{
    display: flex;
    justify-content: space-between;
    margin-bottom: 38px;
}
.subcategories_product_card_section {
    grid-template-columns: repeat(6, 1fr);
}
.ProductCardsectt{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-template-rows: repeat(1, minmax(300px, 1fr));
    gap: 30px;
    margin-top: 2%;
    
}
/* @media (max-width: 1500px) {
    .subcategories_product_card_section {
        grid-template-columns: repeat(5, 1fr);
    }
    
} */


@media (max-width: 1100px) {
    .subcategories_product_card_section {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (max-width: 767px) {
    body {
        background-size: 100%;
    }
    .ProductCardsectt img{
        width: 100%;
    }
    .subcategories_product_card_section {
        grid-template-columns: repeat(2, 1fr);
        margin-top: 5rem;
    }
    .subscribe_cateory h4 {
        font-size: 16px;
        font-weight: 500;
        color: #212F63;
        text-transform: capitalize;
    }
    .select_boxes_lists {
        display: block;
        justify-content: space-between;
        padding: 20px 2rem;
        margin-top: 5%;
    
    }
    .select{
        margin-bottom: 26px;
        padding: 0px;
    }
    .select_boxes_lists button{
        width: 100%;
    }
    .filter_section{
        padding: 20px 2rem;
    }
    .products_items_boxes_lists{
        display: block;
    }
    .dropdown_lists {
        width: 100%;
        margin-top: 20px;
    }
    .dropdown_lists1{
        display: block;
    }
    .dropdown_lists .select{
        width: 100%;
        margin-bottom: 28px;
    }
    .products_items button{
        width: 100%;
    }
    .search {
        display: inline-block;
        width: 100%;
    }
    .search input {
        display: inline-block;
        width: 100%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

    .subcategories_product_card_section {
        grid-template-columns: repeat(3, 1fr);
    }
    .subscribe_cateory h4 {
        font-size: 18px;
        font-weight: 500;
        color: #212F63;
        text-transform: capitalize;
    }
    .select_boxes_lists button.bttonApply {
        padding: 8px 70px;
        height: 40px;
    }
    .select_boxes_lists {
        display: flex;
        justify-content: space-between;
        padding: 20px 2rem;
        margin-top: 5%;
        
    }
    .select{
        margin-bottom: 26px;
        padding: 0px;
        width: 22%;
    }
    .select_boxes_lists button{
        width: 100%;
    }
    .filter_section{
        padding: 20px 2rem;
    }
    .products_items_boxes_lists{
        display: block;
    }
    .dropdown_lists {
        width: 100%;
        margin-top: 20px;
    }
    .dropdown_lists1{
        display: flex;
    }
    .dropdown_lists .select{
        width: 100%;
        margin-bottom: 28px;
    }
    .products_items button{
        width: 100%;
    }
    .search {
        display: inline-block;
        width: 100%;
    }
    .search input {
        display: inline-block;
        width: 100%;
    }
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .subcategories_product_card_section {
        grid-template-columns: repeat(6, 1fr);
    }
    .subscribe_cateory h4 {
        font-size: 18px;
        font-weight: 500;
        color: #212F63;
        text-transform: capitalize;
    }
    .select_boxes_lists {
        display: flex;
        justify-content: space-between;
        padding: 20px 2rem;
        margin-top: 5%;
      
    }
    .select_boxes_lists button.bttonApply {
        padding: 8px 70px;
        height: 40px;
    }
    .select{
        margin-bottom: 26px;
        padding: 0px;
        width: 22%;
    }
    .select_boxes_lists button{
        width: 100%;
    }
    .filter_section{
        padding: 20px 2rem;
    }
    .products_items_boxes_lists{
        display: flex;
    }
    .dropdown_lists {
        width:65%;
        margin-top: 20px;
    }
    .dropdown_lists1{
        display: flex;
    }
    .dropdown_lists .select{
        width: 100%;
        margin-bottom: 28px;
    }
    .products_items button{
        width: 100%;
    }
    .search {
        display: inline-block;
        width: 100%;
    }
    .search input {
        display: inline-block;
        width: 100%;
    }
    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-template-rows: repeat(1, minmax(300px, 1fr));
        gap: 30px;
        margin-top: 2%;
    }
}
@media screen and (min-width: 1201px) and (max-width: 1366px) {

    .subcategories_product_card_section {
        grid-template-columns: repeat(6, 1fr);
    }
    .subscribe_cateory h4 {
        font-size: 18px;
        font-weight: 500;
        color: #212F63;
        text-transform: capitalize;
    }
    .select_boxes_lists {
        display: flex;
        justify-content: space-between;
        padding: 20px 2rem;
        margin-top: 5%;
      
    }
    .select{
        margin-bottom: 26px;
        padding: 0px;
        width: 24%;
    }
    .select_boxes_lists button.bttonApply{
        width: 100%;
    }
    .filter_section{
        padding: 20px 2rem;
    }
    .products_items_boxes_lists{
        display: flex;
    }
    .dropdown_lists {
        width:65%;
        margin-top: 20px;
    }
    .dropdown_lists1{
        display: flex;
    }
    .dropdown_lists .select{
        width: 100%;
        margin-bottom: 28px;
    }
    .products_items button{
        width: 100%;
    }
    .search {
        display: inline-block;
        width: 100%;
    }
    .search input {
        display: inline-block;
        width: 100%;
    }
    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-template-rows: repeat(1, minmax(300px, 1fr));
        gap: 30px;
        margin-top: 2%;
    }
}
    @media screen and (min-width: 1367px) and (max-width: 1600px) {
        .subcategories_product_card_section {
            grid-template-columns: repeat(6, 1fr);
        }
        .filter_section{
            padding: 20px 5rem;
            margin-top: 2%;
        }
        .select_boxes_lists{
            padding: 20px 5rem;
        }
        .product_select{
            padding: 0px;
        }
        .ProductCardsectt {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            grid-template-rows: repeat(1, minmax(300px, 1fr));
            gap: 30px;
            margin-top: 2%;
        }
    }
.activePrice{
    color: #212F63;
    font-weight: 800;
}
.loaderStyle{

    text-align: center;
        padding-left: 650px;
}
.clickCategory{
    cursor: pointer;
}

.boldPrice {
    font-weight: bold;
    font-size: 20px!important;
  }
  .select_box_wraper_sorting{
    justify-content: end;
    padding: 0px 10%;
    display: flex;
  }


.resetbuttonapply {
position: relative;
bottom: 1px;
left: 14px;
background: white;
border: none;
}


.checkbox_container {
    display: flex;
    /* align-items: center; */
    margin-bottom: 20px;
  }
  
  input[type="checkbox"] {
    display: none;
  }
  
  input[type="checkbox"] + label {
    position: relative;
    padding-left: 40px;
    cursor: pointer;
    display: inline-block;
    color: #8a8383;
    font-size: 16px;
  }
  
  input[type="checkbox"] + label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    border: 2px solid #333;
    border-radius: 3px;
    background-color: #fff;
  }
  
  input[type="checkbox"]:checked + label::before {
    content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IiMyMTJGNjMiLz4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzI0XzEzNDg5KSI+CjxwYXRoIGQ9Ik0xMC45OTk2IDE0LjM3OTFMMTcuODkzNiA3LjQ4NDM4TDE4Ljk1NDggOC41NDQ4OEwxMC45OTk2IDE2LjUwMDFMNi4yMjY1NiAxMS43MjcxTDcuMjg3MDYgMTAuNjY2NkwxMC45OTk2IDE0LjM3OTFaIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzI0XzEzNDg5Ij4KPHJlY3Qgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiBmaWxsPSJ3aGl0ZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMy41IDMpIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==');
    border: none;
    background-color: transparent;
  }