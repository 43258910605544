

.social_login_heading {
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-transform: uppercase;

    /* Black */

    color: #414141;
    margin-bottom: 20px;
}

.social_auth_btns {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}
/* .social_auth_btns svg{
    margin-right: -20px;
} */
.social_auth_btns > aside {
    background-color: #212F63;
    padding: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}