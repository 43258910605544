.card_wrapper {
    height: 50%;
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    padding: 30px 50px;
}


.input_container {
    margin-bottom: 20px;
}

.input_label {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 14px;
}

.input_field {
    height: 40px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
}

.btn1 {
    background-color: #212F63;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    width: 30%;
    margin-top: 10px;
    margin-right: 20px;
}

.btn1:hover {
    background-color: #0e1731;
}
.btn2 {
    background-color: transparent;
    color: #6c757d; /* Text color */
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-top: 15px;
    
    padding: 0;
    text-decoration: underline; 
}

.btn2:hover {
    text-decoration: none; 
    color: #0e1731; 
}


.heading {
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #6F758B;
    margin-bottom: 8px;
}

.heading_description {
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;
    color: #414141;
}

.description_text > p {
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #6F758B;
    margin: 20px 0;
}

.description_text>ul >li {
    /* Body --/Body Reg 14 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */


    /* Grey 1 */

    color: #6F758B;
    margin-bottom: 5px;
    margin-left: 20px;
}

.form{
    margin-top: 30px;
}
@media screen and (max-width: 767px) {
    .heading_description{
        font-size: 24px;
    }
}

