.card_wrapper{
    padding: 70px 5%;
}

.heading{
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #6F758B;
}

.heading_description{
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;
    color: #414141;
}

.login_form{
    margin-top: 20px;
}

.recover_password_text{
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    text-align: right;
    color: #414141;
    cursor: pointer;
}


.forgot_remember_pass_section{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}
.login_button_show {
    padding: 10px 75px;
    background: var(--dark-brinjal);
    color: var(--white);
    border: none;
    font-size: .8rem;
    cursor: pointer;
    font-weight: 600;
}
.register_check_button_show {
    padding: 10px 60px;
    border: 1px solid var(--dark-brinjal) !important;
    background-color: var(--white);
    border: none;
    font-size: .8rem;
    cursor: pointer;
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    text-transform: capitalize;
    color: var(--dark-brinjal);
}
.login_sign_btn_section{
    margin-top: 30px;
display: flex;
    gap: 30px;
}
@media screen and (max-width: 767px) {
    .heading_description{
        font-size: 24px;
    }
}
