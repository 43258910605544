.main_wrapper{
    padding: 20px 10rem;
  
}
.main_wrapper > h1{
    font-size: 34px;
    font-weight: 600;
    color: #414141;
    text-transform: capitalize;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}
.shipping_accordian_lists{
    display: flex;
    justify-content: space-between;
    margin: 30px 0px;
}
.shipping_accordian_bar{
    width: 26%;
}
.shipping_accordian_bar1{
    width: 70%;
}
.shipping_accordian_bar .shipping_Accordion_text{
    background: transparent;
    color: #414141;
    border-bottom: 1px solid #E1E1E1;
    padding-bottom: 10px;
    box-shadow: none;
}
.shipping_accordian_bar .shipping_Accordion_typography{
    font-size: 18px;
    color: #414141;
    font-weight: 500;
}
.shipping_accordian_bar1 .shipping_Accordion_text1{
    background-color: #F7F7F7  ;
    margin-bottom: 20px;
    box-shadow: none;
    border: none;
}
.shipping_accordian_bar1 .shipping_Accordion_typography1{
    font-size: 18px;
    color: #414141;
    font-weight: 500;
    line-height: 22px;
}
.shiipng_border_box{
    border: 1px solid#E1E1E1;
    padding: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
}
.shiipng_border_box h3{
    font-size: 18px;
    color: #6F758B;
    font-weight: 400;
}
.tumbs_icons i{
    background: #212F63;
    width: 40px;
    height: 40px;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    color: #6F758B;
    justify-content: center;
    text-align: center;
    margin-left: 10px;
}
.tumbs_icons i:nth-child(1){
    transform: rotateY(180deg);
}
.tumbs_icons{
    display: flex;
}
.tumbs_icons :nth-child(2){
color: white;
}
@media screen and (max-width: 767px) {
    .main_wrapper{
        padding: 20px 2rem;
        margin-top: 5rem;
    }
    .main_wrapper h1 {
        font-size: 24px;
        margin-top: 10px;
        margin-bottom: 10px;
     
    }
    .shipping_accordian_lists{
        display: block;
    }
    .shipping_accordian_bar{
        width: 100%
    }
    .shipping_accordian_bar1{
        width: 100%;
        margin-top:20px
    }
    .shipping_accordian_bar .shipping_Accordion_typography{
        font-size: 15px;
    }
    .shipping_accordian_bar1 .shipping_Accordion_typography1{
        font-size: 15px;
        line-height: 22px;
    }
    .shiipng_border_box{
        padding: 22px;
    }
    .shiipng_border_box h3{
        font-size: 15px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .main_wrapper{
        padding: 20px 3rem;
    }
    .main_wrapper h1 {
        font-size: 24px;
        margin-top: 10px;
        margin-bottom: 10px;
     
    }
    .shipping_accordian_lists{
        display: block;
    }
    .shipping_accordian_bar{
        width: 100%
    }
    .shipping_accordian_bar1{
        width: 100%;
        margin-top:20px
    }
    .shipping_accordian_bar .shipping_Accordion_typography{
        font-size: 16px;
    }
    .shipping_accordian_bar1 .shipping_Accordion_typography1{
        font-size: 16px;
        line-height: 22px;
    }
    .shiipng_border_box{
        padding: 22px;
    }
    .shiipng_border_box h3{
        font-size: 16px;
    }
}
@media screen and (min-width: 1201px) and (max-width: 1366px) {
    .main_wrapper{
        padding: 20px 4rem;
    }
    .shipping_accordian_bar{
        width: 34%
    }
    .shipping_accordian_bar1{
        width: 60%;
    }
    .shipping_accordian_bar .shipping_Accordion_typography{
        font-size: 16px;
    }
    .shipping_accordian_bar1 .shipping_Accordion_typography1{
        font-size: 16px;
        line-height: 22px;
    }
    .shiipng_border_box{
        padding: 22px;
    }
    .shiipng_border_box h3{
        font-size: 16px;
    }
}
@media screen and (min-width: 1367px) and (max-width: 1600px) {
    .main_wrapper{
        padding: 20px 5rem;
    }
    .shipping_accordian_bar{
        width: 34%
    }
    .shipping_accordian_bar1{
        width: 60%;
    }
    .shipping_accordian_bar .shipping_Accordion_typography{
        font-size: 16px;
    }
    .shipping_accordian_bar1 .shipping_Accordion_typography1{
        font-size: 16px;
        line-height: 22px;
    }
    .shiipng_border_box{
        padding: 22px;
    }
    .shiipng_border_box h3{
        font-size: 16px;
    }
}
@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .main_wrapper{
        padding: 20px 7rem;
    }
    .shipping_accordian_bar{
        width: 34%
    }
    .shipping_accordian_bar1{
        width: 60%;
    }
    .shipping_accordian_bar .shipping_Accordion_typography{
        font-size: 16px;
    }
    .shipping_accordian_bar1 .shipping_Accordion_typography1{
        font-size: 16px;
        line-height: 22px;
    }
    .shiipng_border_box{
        padding: 22px;
    }
    .shiipng_border_box h3{
        font-size: 16px;
    }
}