
.news_card_content {
    margin-top: 30px;
    max-width: 300px;
}

.date_section {
    display: flex;
    gap: 10px;
    align-items: center;
}

.date_text {
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 400;
    font-size: .9rem;
    line-height: 140%;
    color: var(--light-brinjal);
    font-size: 1.1rem;
}

.content_heading {
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 120%;
    color: var(--dark-brinjal);
    margin-top: 15px;
    font-size: 16px;
}

.content {
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 400;
    font-size: .9rem;
    line-height: 140%;
    color: var(--light-brinjal);
    margin: 15px 0;
    font-size: 15px;
}

.read_more_action_btn {
    display: flex;
    align-items: center;

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 140%;
    text-transform: capitalize;
    color: var(--dark-brinjal);
    margin-bottom: 20px;
}

.read_more_action_btn>svg { margin-left: 10px; }

.main_wrapper :hover{
    color: var(--dark-orange)
}

.main_wrapper :hover {
    color: var(--dark-orange)
}