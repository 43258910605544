.main_wrapper {
    padding: var(--whitespace);
}



.main_wrapper>h1 {
    /* H --/H2 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;
    /* identical to box height, or 45px */

    font-feature-settings: 'pnum' on, 'lnum' on;

    /* Black */

    color: #414141;
    text-align: center;
    margin: 30px 0;
}

.search_input_container {
    display: flex;
    justify-content: center;
    align-items: center;

}

.search_input_container>input {
    /* White */
    background: #FFFFFF;
    /* Grey 2 */
    border: 0.5px solid #E1E1E1;
    padding: 8px 15px;
    max-width: 100%;
}

.search_input_container>svg {
    margin-left: -30px;
}
.search_input_container>input::placeholder {
    /* UI/Input Reg 14 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */
    /* Grey 1 */
    color: #6F758B;
}
.help_card_wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
    margin: 30px 0 50px 0;
}

.help_card:hover h4 {
    color: white;
}

.help_card:hover p {
    color: white;
}
.help_card {
    /* Grey 3 */
    background: #F7F7F7;
    padding: 20px;
    cursor: pointer;
}

.help_card:hover {
    background-color: #F86B1F;
    color: green !important;
}
.help_card>h4 {
    /* H --/Title SB 16 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */
    display: flex;
    align-items: center;
    /* Primary 01 */
    color: #212F63;
    padding-bottom: 10px;
}

.help_card>p {
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: justify;
    /* or 24px */


    /* Grey 1 */

    color: #6F758B;
}

.search-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 767px) {
    .help_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
        gap: 15px;
        margin: 48px 0 50px 0;
    }

    .help_card {
        background: #F7F7F7;
       padding: 10px 30px 50px; 
        cursor: pointer;
    }
    .help_card>p {
        font-family: 'Neue Haas Grotesk Text Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        text-align: justify;
        color: #6F758B;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .main_wrapper{
        margin-top: 2rem;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .help_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
        gap: 15px;
        margin: 48px 0 50px 0;
    }

    .help_card {
        background: #F7F7F7;
       padding: 10px 30px 50px; 
        cursor: pointer;
    }
    .help_card>p {
        font-family: 'Neue Haas Grotesk Text Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        text-align: justify;
        color: #6F758B;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .help_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
        gap: 15px;
        margin: 48px 0 50px 0;
    }

    .help_card {
        background: #F7F7F7;
       padding: 10px 30px 50px; 
        cursor: pointer;
    }
    .help_card>p {
        font-family: 'Neue Haas Grotesk Text Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        text-align: justify;
        color: #6F758B;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}
@media screen and (min-width: 1201px) and (max-width: 1367px) {
    .help_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
        gap: 15px;
        margin: 48px 0 50px 0;
    }

    .help_card {
        background: #F7F7F7;
       padding: 10px 30px 50px; 
        cursor: pointer;
    }
    .help_card>p {
        font-family: 'Neue Haas Grotesk Text Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        text-align: justify;
        color: #6F758B;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    
}
@media screen and (min-width: 1367px) and (max-width: 1600px) {
    .help_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        gap: 15px;
        margin: 48px 0 50px 0;
    }

    .help_card {
        background: #F7F7F7;
       padding: 10px 30px 50px; 
        cursor: pointer;
    }
    .help_card>p {
        font-family: 'Neue Haas Grotesk Text Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        text-align: justify;
        color: #6F758B;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    
}
@media screen and (min-width: 1800px) and (max-width: 1920px) {
    .help_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(435px, 1fr));
        gap: 15px;
        margin: 48px 0 50px 0;
    }

    .help_card {
        background: #F7F7F7;
       padding: 10px 30px 50px; 
        cursor: pointer;
    }
    .help_card>p {
        font-family: 'Neue Haas Grotesk Text Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        text-align: justify;
        color: #6F758B;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    
}
@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .help_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(435px, 1fr));
        gap: 15px;
        margin: 48px 0 50px 0;
    }

    .help_card {
        background: #F7F7F7;
       padding: 10px 30px 50px; 
        cursor: pointer;
    }
    .help_card>p {
        font-family: 'Neue Haas Grotesk Text Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        text-align: justify;
        color: #6F758B;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    
}
/* @media screen and (max-width: 1300px) {
.search_input_container>input {

        max-width: 45%;
    }
}

@media screen and (max-width: 900px) {
    .search_input_container>input {

        max-width: 55%;
    }
}

@media screen and (max-width: 700px) {
    .search_input_container>input {

        max-width: 65%;
    }
}

@media screen and (max-width: 550px) {
    .search_input_container>input {

        max-width: 80%;
    }
}
     */