.modalOpener {
    color: var(--light-blue2);
    cursor: pointer;
}

.detailsModal{
    z-index: 1000;
}

.detailsCard {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px 40px;
    padding: 20px 40px;
    border: 1px solid var(--light-grey3);
    border-radius: 5px;
}

.statusText {
    text-transform: capitalize;
}

.modalHeading {
    font-size: 1.05rem;
    font-weight: 500;
    margin-bottom: 35px;
    text-align: center;
}

.detailsCard strong {
    display: block;
    font-weight: 600;
    font-size: 0.75rem;
}

.detailsCard p {
    font-size: 0.85rem;
}


.subSection {
    border-radius: 5px;
    background: var(--light-grey3);
    padding: 20px;
    grid-column: span 3;
}


.subSectionHeading {
    color: var(--light-blue2);
    font-size: 0.7rem;
    font-weight: 500;
}


.innerSection {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.capitalise {
    text-transform: capitalize;
}

.innerVerificationSubSection {
    background: var(--white);
}

.responseMedia {
    display: inline-block;
    width: 500px;
}