.wishlist_image_box_category{
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 11px 18px 50px rgba(0, 0, 0, 0.12);
    padding: 1rem;
    width: 24%;
    margin-bottom: 3rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.wishlist_image_box_category h2{
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: var(--dark-brinjal);
    cursor: pointer;
}
.wishlist_image_box_category h3{
    color: gray;
    font-size: 16px;
    font-weight: 500;
}
.wishlist_images_boxes{
    display: flex;
    column-gap: 14px;
    flex-wrap: wrap;
    margin-top: 3%;
}
.main_wrapper{
    padding: 30px 6rem;
}
.wishlist_image_box_category_buttons{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}
/* .wishlist_image_box_category_buttons button{
    padding: 8px 26px;
    background: #212f63;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    text-transform: capitalize;
} */
.wishlist_image_box_category img{
    width: 100%;
}
.wishlist_image_box_category_buttons button {
    padding: 8px 18px;
    color: #fff;
    border: none;
    background-image: -webkit-linear-gradient(30deg, #212f63 50%, transparent 50%);
    background-image: linear-gradient(30deg, #212f63 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
    border-radius: 5px;
    font-size: 15px;
    text-transform: capitalize;
  }
  .wishlist_image_box_category_buttons button:hover {
    background-position: 100%;
    color: #212f63;
  }
  @media only screen and (min-width: 1601px) and (max-width: 1800px){
    .main_wrapper{
        padding: 30px 3rem;
    }
    
.wishlist_image_box_category_buttons button{
    padding: 8px 14px;
    font-size: 14px;
}
  }
  @media only screen and (min-width: 1367px) and (max-width: 1600px){
    .main_wrapper{
        padding: 30px 3rem;
    }
    
.wishlist_image_box_category_buttons button{
    padding: 8px 10px;
    font-size: 12px;
}
  }
  @media only screen and (min-width: 1201px) and (max-width: 1366px){
    .main_wrapper{
        padding: 30px 3rem;
    }
    
.wishlist_image_box_category_buttons button{
    padding: 8px 8px;
    font-size: 11px;

}
  }
  @media only screen and (min-width: 1025px) and (max-width: 1200px){
    .main_wrapper{
        padding: 30px 3rem;
    }
    .wishlist_images_boxes{
        flex-wrap: wrap;
    }
    .wishlist_image_box_category{
        width: 48%;
    }
.wishlist_image_box_category_buttons button{
    padding: 8px 8px;
    font-size: 14px;
    width: 48%;

}
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .main_wrapper{
        padding: 30px 2rem;
    }
    .wishlist_images_boxes{
        flex-wrap: wrap;
    }
    .wishlist_image_box_category{
        width: 48%;
    }
.wishlist_image_box_category_buttons button{
    padding: 8px 4px;
    font-size: 11px;
    width: 48%;
}
  }
  @media only screen and (max-width: 767px){
    .main_wrapper{
        padding: 30px 2rem;
    }
    .wishlist_images_boxes{
       display: block;
    }
    .wishlist_image_box_category{
        width: 100%;
    }
.wishlist_image_box_category_buttons button{
    padding: 8px 4px;
    font-size: 10px;
    width: 48%;
}
  }