.news_guide_main_wrapper {
    padding: var(--whitespace);
}

.news_guide_main_wrapper>h1 {
    /* H --/H2 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;
    text-align: center;
    /* identical to box height, or 45px */

    font-feature-settings: 'pnum' on, 'lnum' on;
    /* Black */
    color: #414141;
}
.news_guide_section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px 30px;
    margin-top: 30px;
}
.load_more_text {
    padding: 4px 7px;
    border: 0.5px solid #E1E1E1;
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;

    /* Primary 01 */

    color: #212F63;
}
.news_guide_section a{
    margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
    .load_more_text{
        width: 32%;
    }
    .news_guide_main_wrapper{
        margin-top: 5rem;
    }
    }
@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .news_guide_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(205px, 1fr));
        gap: 10px 30px;
        margin-top: 30px;
    }
}
@media screen and (min-width: 1201px) and (max-width: 1366px) {
    .news_guide_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(248px, 1fr));
        gap: 10px 30px;
        margin-top: 30px;
    }
}
@media screen and (min-width: 1367px) and (max-width: 1600px) {
    .news_guide_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(285px, 1fr));
        gap: 10px 30px;
        margin-top: 30px;
    }
}
@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .news_guide_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
        gap: 10px 30px;
        margin-top: 30px;
    }
}
@media screen and (min-width: 1801px) and (max-width: 1920px) {
    .news_guide_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
        gap: 10px 30px;
        margin-top: 30px;
    }
}
@media screen and (min-width: 1921px) and (max-width: 2560px) {
    .news_guide_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
        gap: 10px 30px;
        margin-top: 30px;
    }
}