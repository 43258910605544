.profile_container{
    background-color: #24295E;
    /* background-color: #9094a4; */
    bottom: 0;
    height: 500px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: -30px;
}
.form_body{
    height: 100%;
    width: 400px;
    background-color: white;
    margin: auto;
    padding: 32px;
    border-radius: 10px;
    color: #212f63;
    box-shadow: 0px 0px 10px 0px #E1E1E1;
}
.first_part{
    display: block;
}
.second_part{
   
    display: block;
}
.thirt_part{
    margin-top: 20px;
}
.gender_part{
    display: flex;
}
.female_part{
    margin-left: 10px;
}
.four_part{
    margin-top: 20px;
    text-align: center;
}
.four_part button{
    background-color: #212F63;
    color: white;
    height: 40px;
    width: 100%;
    border-radius: 4px;
    border: none;

}

.form_body input{

    background-color: transparent;
    border: 1px solid #212F63;
    border-radius: 5px;
    height: 40px;
    margin-bottom: 20px;
    outline: none;
    margin-top: 6px;
    
}
/* .last_name_part{
    margin-left: 93px;
}
.phone_no_part{
    margin-left: 93px;

} */

input{
    padding-left: 20px;
}
@media screen and (max-width: 767px) {
    .profile_container{
        margin-top: 6rem;
    }
    .form_body{
        width: 100%;
    }
}