.main_wrapper {
    padding: 30px 15rem;
    
}

.Coupancode_text h1 {
    font-size: 40px;
    font-weight: 600;
    color: #414141;
    text-transform: capitalize;
    line-height: 50px;
}

.Coupancode_text p {
    font-size: 16px;
    font-weight: 500;
    color: #6F758B;
    text-transform: capitalize;
    line-height: 26px;
    text-align: justify;
    margin-top: 24px;
}

.Coupancode_text {
    margin-top: 5%;
    margin-bottom: 5%;
}

.Coupancode_text_lists {
    background: #F7F7F7;
    padding: 35px;
    display: flex;
    justify-content: space-between;

}

.Coupancode_text1 h3 {
    font-size: 18px;
    color: #414141;
    font-weight: 600;
    line-height: 32px;

}

.Coupancode_text1 p {
    width: 70%;
}

.Coupancode_text_lists {
    margin-bottom: 25px;
}

.Coupancode_text1 {
    color: #6F758B;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    margin-left: 45px;

}

.Coupancode_img_wrapper {
    display: flex;
    column-gap: 14px;
}

.Coupancode_items h3 {
    background: #212F63;
    padding: 10px 15px;
    color: white;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
}
.Coupancode_check {
    background: #212F63;
    padding: 10px 15px;
    color: white;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 5px;
}
.just_show_code {
    background: #fff;
    padding: 10px 15px;
    color: black;

    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
    cursor: pointer;
}
.Coupancode_show {
    background: #fff;
    padding: 10px 15px;
    color: black;
    margin-top: '5px';
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
    
}
.Coupancode_icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #212F63;
    margin-top: 18px;

}

.Coupancode_icon small {
    margin-left: 12px;
    cursor: pointer;
}

.Coupancode_socail_icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #212F63;
    margin-top: 18px;
}

.Coupancode_socail_icon i {
    background: #212F63;
    color: white;
    width: 20px;
    height: 20px;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.show_code {
    background: white !important;
    color: #414141 !important;
    font-size: 16px;
    font-weight: 700 !important;
    text-transform: uppercase !important;
}

.Coupancode_icon {
    margin-top: 20px;
    width: 50%;
}

.Coupancode_socail_icon {
    width: 50%;
}

.Coupancode_items {
    width: 28%;
}

.Coupancode_pagination {
    border-top: 1px solid #E1E1E1;
}

.Coupancode_pagination {
    margin-top: 50px;
}

.load_more_text {
    padding: 4px 7px;
    border: 0.5px solid #E1E1E1;
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* Primary 01 */

    color: #212F63;
}

@media screen and (max-width: 767px) {
    .main_wrapper {
        padding: 30px 2rem;
        margin-top: 5rem;
    }

    .Coupancode_text h1 {
        font-size: 30px;
        line-height: 32px;
    }

    .Coupancode_text p {
        font-size: 14px;
    }

    .Coupancode_text_lists {
        padding: 24px;
        display: block;
    }

    .Coupancode_text1 h3 {
        font-size: 14px;
        color: #414141;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .Coupancode_text1 p {
        font-size: 14px;
        color: #6F758B;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .Coupancode_icon {
        margin-top: 20px;
    }

    .Coupancode_img_wrapper {
        width: 100%;
        display: block;
    }

    .Coupancode_text1 {
        margin-left: 0px;
    }

    .Coupancode_text1 p {
        width: 100%;
    }

    .Coupancode_items {
        width: 100%;
    }

    .Coupancode_socail_icon {
        width: 56%;
        align-items: center;
    }

    .Coupancode_socail_icon svg {
        margin-right: 10px;
    }

    .Coupancode_icon {
        margin-top: 20px;
        width: 64%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .main_wrapper {
        padding: 30px 3rem
    }

    .Coupancode_text h1 {
        font-size: 30px;
        line-height: 32px;
    }

    .Coupancode_text p {
        font-size: 14px;
    }

    .Coupancode_text_lists {
        padding: 45px 24px 10px;
        display: flex;
    }

    .Coupancode_text1 h3 {
        font-size: 14px;
        color: #414141;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .Coupancode_img_wrapper {
        width: 60%;
    }

    .Coupancode_items {
        width: 35%;
    }

    .Coupancode_img_wrapper {
        display: flex;
        column-gap: 14px;
    }

    .Coupancode_text1 p {
        font-size: 14px;
        color: #6F758B;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 20px;
        width: 100%;
    }

    .Coupancode_icon {
        margin-top: 20px;
        width: 75%;
    }

    .Coupancode_socail_icon {
        width: 100%;
    }

    .Coupancode_text1 {
        margin-left: 7%;
    }

    .Coupancode_socail_icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #212F63;
        margin-top: 32px;

    }

    .Coupancode_socail_icon p {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
    }

    .Coupancode_socail_icon svg {
        margin-right: 10px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .main_wrapper {
        padding: 30px 4rem
    }

    .Coupancode_text h1 {
        font-size: 30px;
        line-height: 32px;
    }

    .Coupancode_text p {
        font-size: 14px;
    }

    .Coupancode_text_lists {
        padding: 45px 24px 10px;
        display: flex;
    }

    .Coupancode_text1 h3 {
        font-size: 14px;
        color: #414141;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .Coupancode_img_wrapper {
        width: 48%;
    }

    .Coupancode_items {
        width: 25%;
    }

    .Coupancode_img_wrapper {
        display: flex;
        column-gap: 14px;
    }

    .Coupancode_text1 p {
        font-size: 14px;
        color: #6F758B;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 20px;
        width: 100%;
    }

    .Coupancode_icon {
        margin-top: 20px;
        width: 75%;
    }

    .Coupancode_socail_icon {
        width: 100%;
    }

    .Coupancode_text1 {
        margin-left: 7%;
    }

    .Coupancode_socail_icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #212F63;
        margin-top: 32px;

    }

    .Coupancode_socail_icon p {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
    }

    .Coupancode_socail_icon svg {
        margin-right: 10px;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1366px) {
    .main_wrapper {
        padding: 30px 10rem
    }

    .Coupancode_text h1 {
        font-size: 30px;
        line-height: 32px;
    }

    .Coupancode_text p {
        font-size: 14px;
    }

    .Coupancode_text_lists {
        padding: 45px 24px 10px;
        display: flex;
    }

    .Coupancode_text1 h3 {
        font-size: 14px;
        color: #414141;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .Coupancode_img_wrapper {
        width: 48%;
    }

    .Coupancode_items {
        width: 25%;
    }

    .Coupancode_img_wrapper {
        display: flex;
        column-gap: 14px;
    }

    .Coupancode_text1 p {
        font-size: 14px;
        color: #6F758B;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 20px;
        width: 100%;
    }

    .Coupancode_icon {
        margin-top: 20px;
        width: 75%;
    }

    .Coupancode_socail_icon {
        width: 100%;
    }

    .Coupancode_text1 {
        margin-left: 7%;
    }

    .Coupancode_socail_icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #212F63;
        margin-top: 32px;

    }

    .Coupancode_socail_icon p {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
    }

    .Coupancode_socail_icon svg {
        margin-right: 10px;
    }
}

@media screen and (min-width: 1367px) and (max-width: 1600px) {
    .main_wrapper {
        padding: 30px 15rem
    }

    .Coupancode_text h1 {
        font-size: 30px;
        line-height: 32px;
    }

    .Coupancode_text p {
        font-size: 14px;
    }

    .Coupancode_text_lists {
        padding: 45px 24px 10px;
        display: flex;
    }

    .Coupancode_text1 h3 {
        font-size: 14px;
        color: #414141;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .Coupancode_img_wrapper {
        width: 48%;
    }

    .Coupancode_items {
        width: 25%;
    }

    .Coupancode_img_wrapper {
        display: flex;
        column-gap: 14px;
    }

    .Coupancode_text1 p {
        font-size: 14px;
        color: #6F758B;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 20px;
        width: 100%;
    }

    .Coupancode_icon {
        margin-top: 20px;
        width: 78%;
    }

    .Coupancode_socail_icon {
        width: 100%;
    }

    .Coupancode_text1 {
        margin-left: 7%;
    }

    .Coupancode_socail_icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #212F63;
        margin-top: 32px;

    }

    .Coupancode_socail_icon p {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
    }

    .Coupancode_socail_icon svg {
        margin-right: 10px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .main_wrapper {
        padding: 30px 15rem
    }

    .Coupancode_text h1 {
        font-size: 30px;
        line-height: 32px;
    }

    .Coupancode_text p {
        font-size: 14px;
    }

    .Coupancode_text_lists {
        padding: 45px 24px 10px;
        display: flex;
    }

    .Coupancode_text1 h3 {
        font-size: 14px;
        color: #414141;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .Coupancode_img_wrapper {
        width: 48%;
    }

    .Coupancode_items {
        width: 25%;
    }

    .Coupancode_img_wrapper {
        display: flex;
        column-gap: 14px;
    }

    .Coupancode_text1 p {
        font-size: 14px;
        color: #6F758B;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 20px;
        width: 100%;
    }

    .Coupancode_icon {
        margin-top: 20px;
        width: 60%;
    }

    .Coupancode_socail_icon {
        width: 100%;
    }

    .Coupancode_text1 {
        margin-left: 7%;
    }

    .Coupancode_socail_icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #212F63;
        margin-top: 32px;

    }

    .Coupancode_socail_icon p {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
    }

    .Coupancode_socail_icon svg {
        margin-right: 10px;
    }

}
@media screen and (min-width: 1801px) and (max-width: 1920px) {
    .main_wrapper {
        padding: 30px 16rem
    }

    .Coupancode_text h1 {
        font-size: 30px;
        line-height: 32px;
    }

    .Coupancode_text p {
        font-size: 14px;
    }

    .Coupancode_text_lists {
        padding: 45px 24px 10px;
        display: flex;
    }

    .Coupancode_text1 h3 {
        font-size: 14px;
        color: #414141;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .Coupancode_img_wrapper {
        width: 48%;
    }

    .Coupancode_items {
        width: 25%;
    }

    .Coupancode_img_wrapper {
        display: flex;
        column-gap: 14px;
    }

    .Coupancode_text1 p {
        font-size: 14px;
        color: #6F758B;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 20px;
        width: 100%;
    }

    .Coupancode_icon {
        margin-top: 20px;
        width: 50%;
    }

    .Coupancode_socail_icon {
        width: 100%;
    }

    .Coupancode_text1 {
        margin-left: 7%;
    }

    .Coupancode_socail_icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #212F63;
        margin-top: 32px;

    }

    .Coupancode_socail_icon p {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
    }

    .Coupancode_socail_icon svg {
        margin-right: 10px;
    }

}
@media screen and (min-width: 1921px) and (max-width: 2560px) {
    .main_wrapper {
        padding: 30px 20rem
    }

    .Coupancode_text h1 {
        font-size: 30px;
        line-height: 32px;
    }

    .Coupancode_text p {
        font-size: 14px;
    }

    .Coupancode_text_lists {
        padding: 45px 24px 10px;
        display: flex;
    }

    .Coupancode_text1 h3 {
        font-size: 14px;
        color: #414141;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .Coupancode_img_wrapper {
        width: 48%;
    }

    .Coupancode_items {
        width: 25%;
    }

    .Coupancode_img_wrapper {
        display: flex;
        column-gap: 14px;
    }

    .Coupancode_text1 p {
        font-size: 14px;
        color: #6F758B;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 20px;
        width: 100%;
    }

    .Coupancode_icon {
        margin-top: 20px;
        width: 55%;
    }

    .Coupancode_socail_icon {
        width: 100%;
    }

    .Coupancode_text1 {
        margin-left: 7%;
    }

    .Coupancode_socail_icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #212F63;
        margin-top: 32px;

    }

    .Coupancode_socail_icon p {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
    }

    .Coupancode_socail_icon svg {
        margin-right: 10px;
    }
}