@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    font-family: 'Poppins', sans-serif;
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}
button.rmdp-arrow-container.rmdp-left {
    background: transparent;
}
button.rmdp-arrow-container.rmdp-right{
    background: transparent;
}
.pagination {
    display: flex;
    column-gap: 20px;
    margin-top: 14px;
    list-style: none;
}
.page-item{
    padding: 10px;
    height: 30px;
    width: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #212F63;
}
.activePage{
    background-color: #F86B1F;
}
.page-item a{
    color: white!important;
}
body {
    overflow-x: hidden;
    width: 100vw;
}

body.ReactModal__Body--open {
    overflow: hidden;
}
/*top-navbar*/
fieldset {
    border: none;
}

.custom-select-wrapper {
    position: relative;
    display: block;
}

.custom-select-wrapper select {
    display: none;
    overflow: hidden;
    width: 100%;
}

.custom-select {
    position: relative;
    display: block;
    width: 200px;
    height: 50px;
    border: 1px solid #EDEDED;
    outline: none;
    color: #6F758B;
    font-size: 15px;
    padding-left: 10px;
}

.custom-select-trigger {
    position: relative;
    padding: 0;
    display: block;
    overflow: hidden;
    width: 260px;
    border: 1px solid #EDEDED;
    border-radius: 0;
    background-color: #fff;
    font-size: 20px;
    line-height: 160%;
    color: #3e345c;
    cursor: pointer;
    height: 50px;
}

.custom-select-trigger:after {
    position: absolute;
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    top: 50%;
    right: 20px;
    margin-top: -0.13636363em;
    border-bottom: 1px solid #6F758B;
    border-right: 1px solid #6F758B;
    -webkit-transform: rotate(45deg) translateY(-50%);
    -ms-transform: rotate(45deg) translateY(-50%);
    transform: rotate(45deg) translateY(-50%);
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    -webkit-transform-origin: 50% 0;
    -ms-transform-origin: 50% 0;
    transform-origin: 50% 0;
}

.custom-select.opened .custom-select-trigger:after {
    margin-top: 0.13636363em;
    -webkit-transform: rotate(-135deg) translateY(-50%);
    -ms-transform: rotate(-135deg) translateY(-50%);
    transform: rotate(-135deg) translateY(-50%);
}

.custom-select-trigger-text {
    position: relative;
    top: 0.4em;
    padding: 0 45px 0 20px;
    display: inline-block;
    overflow: hidden;
    height: 57px;
    color: #6F758B;
    font-size: 16px;
}

.custom-options {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    margin: 0;
    display: none;
    min-width: 100%;
    border: 1px solid #ececec;
    border-radius: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    -webkit-transition: all .4s ease-in-out;
    opacity: 0;
    z-index: 10;
    transition: all .4s ease-in-out;
}

.custom-options:before {
    position: absolute;
    display: block;
    content: '';
    top: -0.22727272em;
    right: 25px;
    width: 7px;
    height: 7px;
    margin-bottom: -0.18181818em;
    border-top: 1px solid #ececec;
    border-left: 1px solid #ececec;
    background: #fff;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.option-hover:before {
    background: #f9f9f9;
}

.custom-option {
    position: relative;
    display: block;
    padding: 10px;
    border-bottom: 1px solid #ececec;
    font-size: 1em;
    color: #333333;
    cursor: pointer;
    -webkit-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
}

.custom-option:last-of-type {
    border-bottom: 0;
    border-radius: 10px;
}

.custom-option.selection:hover,
.custom-option:hover {
    background: #fff9e6;
}

.search-box {
    font-size: 15px;
    padding: 14px 19px;
    border: 1px solid #EDEDED;
    background-color: white;
    width: 100%;
    transition: .2s;
    display: flex;
    align-items: center;
    height: 50px;
}

.search-box:hover {
    border-color: #AAAAAA;
}

.search-box:focus-within {
    border-color: #212F63;

}
.search-box input {
    font-family: 'Poppins', sans-serif;
    letter-spacing: -0.2px;
    font-size: 16px;
    border: none;
    color: #6F758B;
}

.search-box::placeholder {
    color: #6F758B !important;
}
button:hover {
    cursor: pointer;
}
.search-box input:focus {
    outline: none;
}
.search-box input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
}
.clear:not(:valid)~.search-clear {
    display: none;
}

.top-header-part #pet-select {
    display: none !important;
}

.top-navbar {
    display: flex;
    justify-content: space-between;
    padding: 0px 10rem;
    margin-top: 2%;
}

.order-lists img {
    position: relative;
    top: 5px;
}

.icon-boxes {
    display: flex;
}

.product-icon {
    display: flex;
    align-items: center;
    background: #EDEDED;
    padding: 12px 20px;
    height: 50px;
    margin: 10px;
}

.product-icon p {
    margin-right: 5px;
}

.search-boxes {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
}
.nav-links p:focus  {
    color: #F86B1F!important;
    cursor: pointer;
}
/*section-1*/
.pagination_wrapper .pagnation_color span:last-child>svg path {
    fill: white !important;
}

.bg-image {
    background: #212F63;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 10px
}

.heading-h1 {
    margin-top: 8%;
    position: relative;
    left: 188px;
    z-index: 1;
}

.heading-h1 h1 {
    font-size: 16px;
    color: white;
    font-weight: 400;
}

.heading-h1 h2 {
    font-size: 52px;
    color: white;
    line-height: 62px;
    font-weight: 500;
    width: 90%;
}

.learn-more-btn {
    background: transparent;
    border: 1px solid white;
    padding: 10px 28px;
    font-size: 16px;
    margin-top: 50px;
    color: white;
    transition: .3s;
}

.learn-more-btn a {
    text-decoration: none;
    font-size: 16px;
    color: white;
    font-weight: 500;
    text-transform: capitalize;
}

.background-img {
    width: 60vw;
    height: 100%;
    position: relative;
}

.phone {
    animation: updown 1s ease 0.2s infinite;
    font-size: 18px;
    color: white;
}

.phone svg {
    width: 20px;
    height: 18px;
    float: left;
    margin-top: 6px;
    margin-right: 8px;
    margin-left: 6px;
}

.phone svg path {
    fill: #ffffff;
}


.phone svg path:nth-child(2) {
    -webkit-animation: header-phone 1s infinite cubic-bezier(.1, .57, .5, 1);
    animation: header-phone 1s infinite cubic-bezier(.1, .57, .5, 1);
}

.phone svg path:nth-child(3) {
    -webkit-animation: header-phone2 1s infinite cubic-bezier(.1, .57, .5, 1);
    animation: header-phone2 1s infinite cubic-bezier(.1, .57, .5, 1);
}


/* Animate phone */

@-webkit-keyframes header-phone {

    0%,
    30% {
        opacity: 0;
        -webkit-transform: translate3d(-20px, 20px, 0);
        transform: translate3d(-20px, 20px, 0)
    }

    80% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    100% {
        opacity: 0
    }
}

@keyframes header-phone {

    0%,
    30% {
        opacity: 0;
        -webkit-transform: translate3d(-20px, 20px, 0);
        transform: translate3d(-20px, 20px, 0)
    }

    80% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    100% {
        opacity: 0
    }
}

@-webkit-keyframes header-phone2 {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-20px, 20px, 0);
        transform: translate3d(-20px, 20px, 0)
    }

    70%,
    90% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    100% {
        opacity: 0
    }
}

@keyframes header-phone2 {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-20px, 20px, 0);
        transform: translate3d(-20px, 20px, 0)
    }

    70%,
    90% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    100% {
        opacity: 0
    }
}

.border-box {
    position: absolute;
    top: 58%;
    right: 8%;

}

.border-line-text {
    position: absolute;
    top: 18%;
    display: flex;
    flex-wrap: wrap;
    left: -45%;
}

.call-us-img {
    background: rgba(255, 255, 255, 0.12);
    text-align: center;
    width: 30px;
    height: 30px;
    margin-right: 12px;
}

.border-line-text h3 {
    color: white;
    font-size: 14px;
    font-weight: 500;

}

.border-line-text p {
    color: white;
    font-size: 14px;
    font-weight: 300;
}


.border-line-text1 {
    position: absolute;
    top: 60%;
    display: flex;
    flex-wrap: wrap;
    left: -45%;
}
.call-us-text{
    margin-right: 10px;
}
.call-us-img {
    background: rgba(255, 255, 255, 0.12);
    text-align: center;
    width: 30px;
    height: 30px;
    margin-right: 12px;
}

.border-line-text1 h3 {
    color: white;
    font-size: 14px;
    font-weight: 500;

}

.border-line-text1 p {
    color: white;
    font-size: 14px;
    font-weight: 300;
}

.bounce-map {
    animation: updown 1s ease 0.2s infinite;
    font-size: 18px;
    color: white;
}

@keyframes updown {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(8px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes shadow {
    0% {
        transform: scale(1);
        opacity: 0.20;
    }

    50% {
        transform: scale(0.95);
        opacity: 0.28;
    }

    100% {
        transform: scale(1);
        opacity: 0.20;
    }
}


/*ARABIC-PART-START*/

/* .border-box1 {
    position: absolute;
    top: 58%;
    left: 8%;
} */
.arabic-heading{
    margin-top: 8%;
    position: relative;
    right: 138px;
    z-index: 1;
}

/*section-2*/

.container {
    padding: 0px 10rem;
}

.list-boxes {
    /* display: flex;
    margin-top: 5%;
    margin-bottom: 5%; */
    max-width: 800px;
}

.list-items {
    display: flex;
    align-items: center;
}

.icon-img {
    background: #F7F7F7;
    padding: 13px;
}

.list-boxes h4 {
    color: #414141;
    font-size: 16px;
    font-weight: 600;
    padding: 0px 25px;
}

.list-boxes p {
    color: #6F758B;
    font-size: 15px;
    padding: 0px 24px;
    width: 80%;
    margin-top: 10px;
}


/*CATEGORIES-PART*/
.categories h3 {
    font-size: 14px;
    color: #6F758B;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
}

.categories h4 {
    font-size: 45px;
    color: #414141;
    text-transform: capitalize;
    text-align: center;
}

.features-list {
    display: flex;
    justify-content: space-between;
    margin-top: 3%;
    margin-bottom: 5%;
}

.features-list div {
    background: #F7F7F7;
    padding: 20px 50px;
    width: 16%;
    height: 190px;
}

.features-list p {
    color: #212F63;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.features-list div:hover {
    background-color: #F86B1F;
}

.features-list div:hover p {
    color: white;
}

/*service-sec*/
.services-sec h3 {
    color: #6F758B;
    font-size: 16px;
    text-align: center;
    font-weight: 600;
}

.services-sec {
    margin-top: 5%;
}

.services-sec h4 {
    font-size: 3rem;
    color: #414141;
    font-weight: 700;
    text-align: center;
}

/* Tabs */

ul#tabs-nav {
    list-style: none;
    margin: 0;
    padding: 5px;
    overflow: auto;
    text-align: center;
    display: flex;
    justify-content: center;
}

ul#tabs-nav li {
    font-weight: bold;
    margin-right: 2px;
    padding: 8px 10px;
    border-radius: 5px 5px 5px 5px;
    /*border: 1px solid #d5d5de;
  border-bottom: none;*/
    cursor: pointer;
}

#tabs-nav li a:hover {
    color: #F86B1F;
}

#tabs-nav li a {
    text-decoration: none;
    color: #212F63;
    font-size: 16px;
    font-weight: 500;
}

.tab-content {
    padding: 10px;
    background-color: #FFF;
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

#tab1 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.tab-content img {
    padding: 0px 50px;
}

.clickable-btn {
    position: absolute;
    left: 5%;
    top: 70%;
    padding: 20px 100px;
    background: #212F63;
    color: white;
    font-size: 16px;
    font-weight: 400;
    border: none;
}

.clickable-btn i {
    position: absolute;
    right: 5%;
    top: 40%;
}

.clickable-btn:hover {
    background-color: #F86B1F;
}

.tabs-click-btn {
    position: relative;
    margin-bottom: 25px;
}

/*product-items*/
.tab {
    float: left;
    background-color: white;
    width: 18%;
    height: 100%;
    border-radius: 10px 0 0 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 5%;
}

.tab button {
    display: flex;
    background-color: inherit;
    color: #212F63;
    width: 90%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 17px;
    font-weight: 600;
    margin: 10%;
    border-radius: 4px;
    text-transform: capitalize;
}

.tablinks.orange-text {
    color: #F86B1F;
}

.tab button:hover {
    background-color: white;
    color: #F86B1F;
}

.tablinks img {
    margin-left: 10px;
    margin-right: 10px;
}

.tabcontent {
    float: left;
    padding: 0px 12px;
    width: 80%;
    border-left: none;
    height: 100%;
    border-radius: 0 10px 10px 0;
    margin-top: 5%;
}

.products-items .spare-parts-items {
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
    background: white;
    border: none;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
    margin-left: 20px;
    flex-wrap: wrap;
    width: 85%;
}

.products-items .tabcontent p {
    color: #212F63;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.products-items .tabcontent p:hover {
    color: #F86B1F;
}

.products-items .tabcontent span {
    color: #BFC2CC;
    font-size: 18px;
    font-weight: 600;
    margin-left: 10px;
}

.products-items .tabcontent div {
    margin-bottom: 65px;
}

/*changes-parts-section*/
#tabs-content .products-items-parts .tab-content {
    display: flex !important;
}

.changes-products img {
    padding: 0px;
}

.changes-products {
    width: 22%;
    margin-top: 5%;
    position: relative;
}

.changes-products h4 {
    color: #212F63;
    font-size: 18px;
    font-weight: 600;
    margin: 5px 0px;
}

.changes-products p {
    color: #6F758B;
    font-size: 16px;
    font-weight: 400;
}

.changes-products h5 {
    color: #333333;
    font-size: 16px;
    font-weight: 600;
}

/* .changes-products img:hover{
  border: 1px solid;
    color: #F86B1F;
} */
.changes-products h4:hover {
    color: #F86B1F;
}

.cart-icon {
    position: absolute;
    top: 10%;
    right: 20%;
}

.cart-icon img {
    display: block;
    background: #E1E1E1;
    padding: 12px;
    margin-bottom: 10px;
}

/*logo-section*/
.logo-cars h3 {
    color: #6F758B;
    font-size: 16px;
    text-align: center;
    font-weight: 600;
}

.logo-cars h4 {
    font-size: 3rem;
    color: #414141;
    font-weight: 700;
    text-align: center;
}

.logo-icons {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 38px;
    margin-top: 3%;
}

.logo-cars {
    margin-top: 5%;
}

/*latest-news-sec*/
.latest-news-sec {
    background: #F7F7F7;
    padding: 2%;
}

.date-list {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.date-list img {
    margin-right: 10px;
}

.date-list p {
    color: #6F758B;
    font-size: 16px;
    font-weight: 500;
}

.blog-content h4 {
    font-size: 18px;
    color: #212F63;
    font-weight: 600;
    margin-top: 15px;
    line-height: 23px;
}

.blog-content p {
    color: #6F758B;
    font-size: 14px;
    font-weight: 500;
    margin-top: 28px;
    width: 90%;
}

.blog-content a {
    color: #212F63;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: 36px;
}

.blog-content i {
    margin-left: 20px;
}


.blog-content h4:hover {
    color: #F86B1F;
}

.blog-content a:hover {
    color: #F86B1F;
}

/*footer*/
.footer-sec {
    background: #E1E1E1;
    display: flex;
    justify-content: space-between;
    padding: 100px 10rem;
}

.contact-us h3 {
    color: #9094A4;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
}

.contact-us p {
    color: #9094A4;
    font-size: 14px;
    font-weight: 600;
}

.contact-us span {
    color: #212F63;
    font-weight: 500;
    font-size: 16px;
}

.contact-list img {
    background: white;
    padding: 13px;
    margin-right: 20px;
}

.customer-services h3 {
    color: #9094A4;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
}

.contact-list {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.customer-services ul {
    list-style-type: none;
}

.customer-services li {
    color: #212F63;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
}

.customer-services p {
    color: #9094A4;
    font-size: 16px;
    font-weight: 500;
}

.subscribe-form {
    position: relative;
    overflow: hidden;
}

.subscribe-form input {
    width: 100%;
    padding: 14px 28px;
    background: #2E2E2E;
    border: 1px solid #2E2E2E;
    color: #fff;
}

.subscribe-form button {
    position: absolute;
    right: 0;
    background: #ff5e14;
    padding: 13px 20px;
    border: 1px solid #ff5e14;
    top: 0;
}

.subscribe-form button i {
    color: #fff;
    font-size: 22px;
    transform: rotate(-6deg);
}

.customer-services input {
    padding: 10px 68px 10px 10px;
    border: none;
    margin-top: 27px;
}

.customer-services button {
    padding: 10px;
    border: none;
    margin-left: -8px;
    background: #212F63;
    color: white;
}

.social-icons {
    margin-top: 30px;
    display: flex;
    align-items: center;
}

.social-icons i {
    background: #212F63;
    font-size: 15px;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    margin-right: 22px;
}

/* 
#pet-select{
    display: none;
} */

@media screen and (max-width: 767px) {
    .container {
        padding: 0px 1rem;
    }

    /*top-header*/
    .top-header {
        display: block;
        padding: 20px 1rem;
        justify-content: space-between;
        background: #EDEDED;
        flex-wrap: wrap;
    }

    #pet-select {
        display: block;
        width: 100%;
        margin: 0px auto;
    }

    .order-lists {
        line-height: 35px;
    }

    .logo {
        display: block;
    }

    .top-navbar {
        padding: 0px 1rem;
        display: flex;
        align-items: center;
    }

    .logo-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .product-icon {
        display: flex;
        align-items: center;
        background: #EDEDED;
        padding: 10px 10px;
        height: 35px;
        margin: 5px;
    }

    .search-boxes {
        display: block;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .searchbar-box {
        margin-bottom: 15px;
        display: none;
    }

    .custom-select-trigger {
        width: 100%;
    }

    .search-box {
        justify-content: space-between;
    }

    .icon-boxes {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 0px;
    }
    .nav-links {
        display: flex;
        list-style: none;
        justify-content: center!important;
        align-items: start!important;
        text-transform: capitalize;
        width: 100%!important;
        gap: 18px;
        -webkit-clip-path: circle(1000px at 90% -10%);
        pointer-events: all;
        padding: 35px 35px;
        background: #212F63;
        transition: all 1s ease-out;
        width: 100%;
        height: 100vh;
        margin-left: 0px!important;
        color: white;
        position: fixed;
        clip-path: circle(1000px at 90% -10%);
        left: 0;
        top: 0;
        flex-direction: column;
        text-align: left!important;
    }
    .nav-links p{
        color: white!important;
    }
    /*section-1*/
    .bg-image {
        margin-top: 10px;
        padding: 0px;
        display: block;
        height: 100%;
        z-index: 0;
    }

    .heading-h1 {
        margin-top: 4%;
        position: relative;
        left: 0px;
        z-index: 10;
        padding: 30px 1rem 40px;
    }
 .heading-h1 h1 {
        font-size: 14px;
    }

    .learn-more-btn a {
        text-decoration: none;
        font-size: 14px;
        color: white;
        font-weight: 500;
        text-transform: capitalize;
    }

    .heading-h1 h2 {
        font-size: 22px;
        color: white;
        line-height: 30px;
        font-weight: 500;
        width: 100%;
        margin-top: 12px;
    }
    .learn-more-btn {
        background: transparent;
        border: 1px solid white;
        padding: 8px 22px;
        font-size: 14px;
        margin-top: 22px;
        color: white;
        transition: .3s;
        margin-bottom: 20px;
    }

    .background-img {
        width: 100%;
        height: 100%;
        position: relative;
        top: 5px;
    }

    .border-box {
        position: absolute;
        top: 70%;
        right: 0px;
    }
    .border-line-text p{
        font-size: 12px;
    }
    .border-box img {
        width: 70%;
    }
    .border-line-text1 p{
        font-size: 12px;
    }
    .border-line-text h3{
        font-size: 12px;
    }
    .border-line-text1 h3{
        font-size: 12px;
    }
    .border-line-text {
        position: absolute;
        top: 18%;
        display: flex;
        flex-wrap: wrap;
        right: 0%;
    }

    .border-line-text1 {
        position: absolute;
        top: 52%;
        display: flex;
        flex-wrap: wrap;
        right: 0%;
    }
    .arabic-heading{
        right:0px;
    }
 
       
    /*section-2*/
    .list-boxes {
        display: BLOCK;
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .list-boxes p {
        color: #6F758B;
        font-size: 14px;
        padding: 0px 24px;
        width: 100%;
        margin-top: 10px;
    }

    .list-items {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }

    /*section-3*/
    .list-items {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }

    .features-list {
        display: block;
        margin-top: 7%;
    }

    .features-list div {
        background: #F7F7F7;
        padding: 20px 50px;
        width: 100%;
        height: 190px;
        margin-bottom: 10%;
    }

    /*section-4*/
    .tab-content img {
        padding: 0;
        width: 100%;
    }

    #tab1 {
        display: block;
        width: 100%;
    }

    .clickable-btn {
        position: absolute;
        left: -5%;
        top: 70%;
        padding: 20px 100px;
        background: #212F63;
        color: white;
        font-size: 13px;
        font-weight: 400;
        border: none;
    }
    .categories h4 {
        font-size: 30px;
    }

    /*section-5*/
    .services-sec h4 {
        font-size: 30px;
    }

    .tab {
        width: 100%;
    }
    .products-items .spare-parts-items {
        width: 100%;
        display: block;
        margin-left: 0;
    }

    .tabcontent {
        /* float: none; */
        padding: 0px 12px;
        width: 100%;
        border-left: none;
        height: 100%;
        border-radius: 0 10px 10px 0;
        margin-top: 5%;
    }

    /*section-6*/
    #tabs-nav {
        display: flex;
        flex-wrap: wrap;
    }

    .changes-products {
        width: 100%;
        margin-top: 5%;
        position: relative;
        margin-bottom: 8%;
    }

    /*section-7*/
    ul#tabs-nav {
        display: flex;
        flex-wrap: wrap;
    }

    .changes-products img {
        padding: 0px;
        width: 100%;
    }

    .cart-icon {
        position: absolute;
        top: 5%;
        right: 5%;
    }

    /*section-8*/
    .logo-cars h4 {
        font-size: 30px;
    }

    .logo-icons {
        display: flex;
        justify-content: space-around;
        margin-bottom: 38px;
        margin-top: 3%;
        flex-wrap: wrap;
    }

    /*section-9*/


    .blog-img {
        width: 100%;
    }

    .blogs {
        margin-bottom: 30px;
    }

    /*footer*/

    .footer-sec {
        background: #E1E1E1;
        display: block;
        justify-content: space-between;
        padding: 100px 2rem;
    }

    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(335px, 1fr)) !important;
        gap: 40px !important;
    }
    .slick-dots {
        padding: 0px 1rem!important;
        margin: 0;
       left: 0!important;
       text-align: left!important;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .container {
        padding: 0px 1rem;
    }

    /*top-header*/
    .top-header {
        display: block;
        padding: 20px 1rem;
        justify-content: space-between;
        background: #EDEDED;
        flex-wrap: wrap;
    }


    .order-lists {
        line-height: 35px;
    }

    .logo {
        display: block;
    }

    .logo img {
        width: 100%;
    }

    .top-navbar {
        padding: 0px 1rem;
    }

    .search-boxes {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    .Search-bar-icon {
        display: none;
    }

    .searchbar-box {
        margin-bottom: 0px;
        display: none;
    }

    .custom-select {
        display: none;
    }

    .custom-select-trigger {
        width: 100%;
    }

    .search-box {
        justify-content: space-between;
    }
    .icon-boxes {
        display: flex;
        justify-content: space-between;
        margin-top: 0px;
    }
    .top-header {
        padding: 20px 1rem;
    }
    .nav-links {
        display: flex;
        list-style: none;
        justify-content: center!important;
        align-items: start!important;
        text-transform: capitalize;
        width: 100%!important;
        gap: 18px;
        -webkit-clip-path: circle(1000px at 90% -10%);
        pointer-events: all;
        padding: 35px 35px;
        background: #212F63;
        transition: all 1s ease-out;
        width: 100%;
        height: 100vh;
        margin-left: 0px!important;
        color: white!important;
        position: fixed;
        clip-path: circle(1300px at 80% -10%);
        left: 0;
        top: 0;
        flex-direction: column;
        text-align: left!important;
    }
    .nav-links p{
        color: white!important;
    }
    /*section-1*/
    .bg-image {
        margin-top: 10px;
        padding: 0px;
        display: block;
        height: 100%
    }

    .heading-h1 {
        margin-top: 3%;
        position: relative;
        left: 0px;
        z-index: 2;
        padding: 30px 2rem 50px;
    }

    .heading-h1 h1 {
        font-size: 14px;
    }
    .learn-more-btn a {
        text-decoration: none;
        font-size: 14px;
        color: white;
        font-weight: 500;
        text-transform: capitalize;
    }
    .heading-h1 h2 {
        font-size: 38px;
        color: white;
        line-height: 48px;
        font-weight: 500;
        width: 88%;
        margin-top: 12px;
    }
    .learn-more-btn {
        background: transparent;
        border: 1px solid white;
        padding: 8px 22px;
        font-size: 14px;
        margin-top: 22px;
        color: white;
        transition: .3s;
        margin-bottom: 20px;
    }
    .background-img {
        width: 100%;
        height: 100%;
        position: relative;
        top: 8px;
    }
    .border-box {
        position: absolute;
        top: 70%;
        right:20px;
    }
    .border-box img {
        width: 100%;
    }

    .border-line-text {
        position: absolute;
        top: 18%;
        display: flex;
        flex-wrap: wrap;
        right: 0%;
    }

    .border-line-text1 {
        position: absolute;
        top: 60%;
        display: flex;
        flex-wrap: wrap;
        right: 0%;
    }
    /*section-2*/
    .list-boxes {
        display: flex;
        margin-top: 10%;
        margin-bottom: 10%;
        flex-wrap: wrap;
    }

    .list-boxes p {
        color: #6F758B;
        font-size: 14px;
        padding: 0px 24px;
        width: 100%;
        margin-top: 10px;
    }

    .list-items {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }

    /*section-3*/
    .list-items {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        width: 48%;
    }
    .features-list {
        display: flex;
        margin-top: 7%;
        flex-wrap: wrap;
    }

    .features-list div {
        background: #F7F7F7;
        padding: 20px 50px;
        width: 48%;
        height: 190px;
        margin-bottom: 10%;
    }

    /*section-4*/
    .tab-content img {
        padding: 0;
        width: 100%;
    }

    #tab1 {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .clickable-btn {
        position: absolute;
        left: -5%;
        top: 70%;
        padding: 20px 100px;
        background: #212F63;
        color: white;
        font-size: 13px;
        font-weight: 400;
        border: none;
    }

    .categories h4 {
        font-size: 30px;
    }

    .tabs-click-btn {
        position: relative;
        margin-bottom: 25px;
        width: 48%;
    }

    /*section-5*/
    .services-sec h4 {
        font-size: 30px;
    }
    .tab button {
        display: flex;
        background-color: inherit;
        color: #212F63;
        width: 30%;
        border: none;
        outline: none;
        text-align: left;
        cursor: pointer;
        transition: 0.3s;
        font-size: 17px;
        font-weight: 600;
        margin: 3%;
        border-radius: 4px;
        text-transform: capitalize;
    }
    .tab {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    
    .products-items .spare-parts-items {
        width: 90%;
        display: flex;
        margin-left: 0;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .tabcontent {
        /* float: none; */
        padding: 0px 12px;
        width: 100%;
        border-left: none;
        height: 100%;
        border-radius: 0 10px 10px 0;
        margin-top: 5%;
    }
    /*section-6*/
    #tabs-nav {
        display: flex;
        flex-wrap: wrap;
    }
    .changes-products {
        width: 48%;
        margin-top: 5%;
        position: relative;
        margin-bottom: 8%;
    }
    /*section-7*/
    ul#tabs-nav {
        display: flex;
        flex-wrap: wrap;
    }

    .changes-products img {
        padding: 0px;
        width: 100%;
    }

    .cart-icon {
        position: absolute;
        top: 5%;
        right: 5%;
    }

    /*section-8*/
    .logo-cars h4 {
        font-size: 30px;
    }

    .logo-icons {
        display: flex;
        justify-content: space-around;
        margin-bottom: 38px;
        margin-top: 3%;
        flex-wrap: wrap;
    }

    /*section-9*/

    .blog-img {
        width: 100%;
    }

    .blogs {
        margin-bottom: 30px;
        width: 48%;
    }

    /*footer*/

    .footer-sec {
        background: #E1E1E1;
        display: flex;
        justify-content: space-between;
        padding: 100px 2rem;
        flex-wrap: wrap;
    }

    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr)) !important;
        gap: 40px !important;
    }
    
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {

    .container {
        padding: 0px 1rem;
    }

    .top-navbar {
        padding: 0px 1rem;
    }

    .search-boxes {
        display: flex;
        width: 78%;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    .nav-links li {
        position: relative;
        padding: 12px 0px;
        font-size: 13px;
        color: #212f63;
        font-weight: 600;
    }
/* 
    .bg-image {
        padding: 0px 0px 0px 1rem;
    } */

    .heading-h1 {
        margin-top: 8%;
        position: relative;
        left: 80px;
        z-index: 1;
        padding-bottom: 50px;
    }

    .border-box {
        position: absolute;
        top: 48%;
        right:20px;
    }

    .border-box img {
        width: 100%;
    }

    .border-line-text {
        position: absolute;
        top: 18%;
        display: flex;
        flex-wrap: wrap;
        right: 0%;
    }

    .border-line-text1 {
        position: absolute;
        top: 60%;
        display: flex;
        flex-wrap: wrap;
        right: 0%;
    }
    .searchbar-box {
        margin-top: 5px;
    }
    .heading-h1 h2 {
        font-size: 38px;
        color: white;
        line-height: 44px;
        font-weight: 500;
        margin-top: 11px;
        width: 100%;
    }
    .icon-boxes {
        display: flex;
        margin-top: 14px;
    }

    .top_categgories_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
        gap: 38px;
        margin-top: 30px;
    }
    .service_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
        gap: 60px;
        margin-top: 30px;
    }
    .our_product_tab_section>ul {
        list-style: none;
        inline-size: 200px;
    }

    .our_product_card_section_wrapper,
    .our_product_tab_section {
        padding: 50px 18px;
        background: #FFFFFF;
        height: 100%;
    }
    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(245px, 1fr)) !important;
        gap: 15px !important;
    }
    .nav-links {
        display: flex;
    list-style: none;
    gap: 10px;
    padding: 0 0.7vw;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    text-transform: capitalize;
    }
    .background-img {
        width: 60vw;
        height: 500px!important;
        position: relative;
    }
    .slick-dots {
      
        left: 80px!important;
    }
}
@media screen and (min-width: 1201px) and (max-width: 1366px) {
    .container {
        padding: 0px 1rem;
    }
    /*top-header*/
    .top-header {
        display: block;
        padding: 20px 1rem;
        justify-content: space-between;
        background: #EDEDED;
        flex-wrap: wrap;
    }
    .order-lists {
        line-height: 35px;
    }
    .logo {
        display: block;
    }

    .logo img {
        width: 100%;
    }

    .top-navbar {
        padding: 0px 2rem;
        column-gap: 30px;
    }

    .search-boxes {
        display: flex;
        width: 88%;
        justify-content: space-between;
        align-items: center;

    }
    .nav-links li {
        position: relative;
        padding: 12px 0px;
        font-size: 13px;
        color: #212f63;
        font-weight: 600;
    }
    .product-icon.Search-bar-icon {
        display: none;
    }

    .product-icon.search_bar_icon {
        display: none;
    }

    /* .searchbar-box{
    margin-bottom: 15px;
} */
    .custom-select-trigger {
        width: 100%;
    }

    .search-box {
        justify-content: space-between;
        margin-top: 5px;
    }

    .icon-boxes {
        display: flex;
        justify-content: space-between;

    }

    /*section-1*/
    .bg-image {
        margin-top: 10px;
        padding: 0px;
        display: flex;
        height: 100%
    }

    .heading-h1 {
        margin-top: 8%;
        position: relative;
        left: 188px;
        z-index: 1;
        padding: 30px 0rem 50px;
    }

    .heading-h1 h1 {
        font-size: 16px;
        color: white;
        font-weight: 400;
    }

    .learn-more-btn a {
        text-decoration: none;
        font-size: 14px;
        color: white;
        font-weight: 500;
        text-transform: capitalize;
    }
    .heading-h1 h2 {
        font-size: 40px;
        color: white;
        line-height: 50px;
        font-weight: 500;
        width: 85%;
    }
    .learn-more-btn {
        background: transparent;
        border: 1px solid white;
        padding: 10px 28px;
        font-size: 16px;
        margin-top: 50px;
        color: white;
        transition: .3s;
    }

    .background-img {
        width: 60vw;
        height: 100%;
        position: relative;
    }
    .border-box {
        position: absolute;
        top: 48%;
        right:20px;
    }
    .border-box img {
        width: 100%;
    }
    .border-line-text {
        position: absolute;
        top: 18%;
        display: flex;
        flex-wrap: wrap;
        right: 0%;
    }
    .border-line-text1 {
        position: absolute;
        top: 60%;
        display: flex;
        flex-wrap: wrap;
        right: 0%;
    }
    /*section-2*/
    .list-boxes {
        display: flex;
        margin-top: 10%;
        margin-bottom: 10%;
        flex-wrap: wrap;
    }
    .list-boxes p {
        color: #6F758B;
        font-size: 14px;
        padding: 0px 24px;
        width: 100%;
        margin-top: 10px;
    }

    .list-items {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }
    /*section-3*/
    .list-items {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        width: 48%;
    }

    .features-list {
        display: flex;
        margin-top: 7%;
        flex-wrap: wrap;
    }

    .features-list div {
        background: #F7F7F7;
        padding: 20px 50px;
        width: 48%;
        height: 190px;
        margin-bottom: 10%;
    }

    /*section-4*/
    .tab-content img {
        padding: 0;
        width: 100%;
    }

    #tab1 {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .clickable-btn {
        position: absolute;
        left: -5%;
        top: 70%;
        padding: 20px 100px;
        background: #212F63;
        color: white;
        font-size: 13px;
        font-weight: 400;
        border: none;
    }

    .categories h4 {
        font-size: 30px;
    }

    .tabs-click-btn {
        position: relative;
        margin-bottom: 25px;
        width: 48%;
    }

    /*section-5*/
    .services-sec h4 {
        font-size: 30px;
    }

    .tab button {
        display: flex;
        background-color: inherit;
        color: #212F63;
        width: 30%;
        border: none;
        outline: none;
        text-align: left;
        cursor: pointer;
        transition: 0.3s;
        font-size: 17px;
        font-weight: 600;
        margin: 3%;
        border-radius: 4px;
        text-transform: capitalize;
    }

    .tab {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .products-items .spare-parts-items {
        width: 90%;
        display: flex;
        margin-left: 0;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .tabcontent {
        /* float: none; */
        padding: 0px 12px;
        width: 100%;
        border-left: none;
        height: 100%;
        border-radius: 0 10px 10px 0;
        margin-top: 5%;
    }

    /*section-6*/
    #tabs-nav {
        display: flex;
        flex-wrap: wrap;
    }

    .changes-products {
        width: 48%;
        margin-top: 5%;
        position: relative;
        margin-bottom: 8%;
    }

    /*section-7*/
    ul#tabs-nav {
        display: flex;
        flex-wrap: wrap;
    }
    .changes-products img {
        padding: 0px;
        width: 100%;
    }
    .cart-icon {
        position: absolute;
        top: 5%;
        right: 5%;
    }
    /*section-8*/
    .logo-cars h4 {
        font-size: 30px;
    }

    .logo-icons {
        display: flex;
        justify-content: space-around;
        margin-bottom: 38px;
        margin-top: 3%;
        flex-wrap: wrap;
    }

    /*section-9*/


    .blog-img {
        width: 100%;
    }

    .blogs {
        margin-bottom: 30px;
        width: 48%;
    }

    /*footer*/

    .footer-sec {
        background: #E1E1E1;
        display: flex;
        justify-content: space-between;
        padding: 100px 2rem;
        flex-wrap: wrap;
    }

    .search-box input {
        font-family: 'Poppins', sans-serif;
        letter-spacing: -0.2px;
        font-size: 14px;
        border: none;
        color: #6F758B;
    }

    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr)) !important;
        gap: 20px !important;
    }
}

@media screen and (min-width: 1367px) and (max-width: 1600px) {
    .container {
        padding: 0px 1rem;
    }
    /*top-header*/
    .top-header {
        display: block;
        padding: 20px 1rem;
        justify-content: space-between;
        background: #EDEDED;
        flex-wrap: wrap;
    }
    .nav-links li {
        position: relative;
        padding: 12px 0px;
        font-size: 14px;
        color: #212f63;
        font-weight: 600;
    }
    .order-lists {
        line-height: 35px;
    }
    .logo {
        display: block;
    }
    .logo img {
        width: 100%;
    }
    .top-navbar {
        padding: 0px 4rem;
    }
    .product-icon.Search-bar-icon {
        display: none;
    }
    .product-icon.search_bar_icon {
        display: none;
    }

    .top-header {
        padding: 0px 4rem;
    }

    .nav-links li a {
        text-decoration: none;
        margin: 0 0.7vw;
        font-size: 13px;
        color: #212F63;
        font-weight: 500;
    }

    .search-boxes {
        display: flex;
        width: 85%;
        justify-content: space-between;
        align-items: center;

    }

    /* .searchbar-box{
    margin-bottom: 15px;
} */
    .custom-select-trigger {
        width: 100%;
    }

    .search-box {
        justify-content: space-between;
        margin-top: 5px;
    }

    .icon-boxes {
        display: flex;
        justify-content: space-between;

    }

    /*section-1*/
    .bg-image {
        margin-top: 10px;
        padding: 0px;
        display: flex;
        height: 100%
    }

    .heading-h1 {
        margin-top: 4%;
        position: relative;
        left: 10%;
        z-index: 2;
        padding: 30px 1rem 50px;
    }

    .heading-h1 h1 {
        font-size: 14px;
    }

    .learn-more-btn a {
        text-decoration: none;
        font-size: 14px;
        color: white;
        font-weight: 500;
        text-transform: capitalize;
    }

    .heading-h1 h2 {
        font-size: 50px;
        color: white;
        line-height: 68px;
        font-weight: 500;
        width: 100%;
        margin-top: 12px;

    }

    .learn-more-btn {
        background: transparent;
        border: 1px solid white;
        padding: 8px 22px;
        font-size: 14px;
        margin-top: 22px;
        color: white;
        transition: .3s;
        margin-bottom: 20px;
    }

    .background-img {
        height: 100%;
        position: relative;
    }

    .border-box {
        position: absolute;
        top: 48%;
        right:20px;
    }

    .border-box img {
        width: 100%;
    }

    .border-line-text {
        position: absolute;
        top: 18%;
        display: flex;
        flex-wrap: wrap;
        right: 0%;
    }

    .border-line-text1 {
        position: absolute;
        top: 60%;
        display: flex;
        flex-wrap: wrap;
        right: 0%;
    }

    /*section-2*/
    .list-boxes {
        display: flex;
        margin-top: 10%;
        margin-bottom: 10%;
        flex-wrap: wrap;
    }

    .list-boxes p {
        color: #6F758B;
        font-size: 14px;
        padding: 0px 24px;
        width: 100%;
        margin-top: 10px;
    }

    .list-items {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }


    /*section-3*/
    .list-items {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        width: 48%;
    }

    .features-list {
        display: flex;
        margin-top: 7%;
        flex-wrap: wrap;
    }

    .features-list div {
        background: #F7F7F7;
        padding: 20px 50px;
        width: 48%;
        height: 190px;
        margin-bottom: 10%;
    }

    /*section-4*/
    .tab-content img {
        padding: 0;
        width: 100%;
    }

    #tab1 {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .clickable-btn {
        position: absolute;
        left: -5%;
        top: 70%;
        padding: 20px 100px;
        background: #212F63;
        color: white;
        font-size: 13px;
        font-weight: 400;
        border: none;
    }

    .categories h4 {
        font-size: 30px;
    }

    .tabs-click-btn {
        position: relative;
        margin-bottom: 25px;
        width: 48%;
    }

    /*section-5*/
    .services-sec h4 {
        font-size: 30px;
    }

    .tab button {
        display: flex;
        background-color: inherit;
        color: #212F63;
        width: 30%;
        border: none;
        outline: none;
        text-align: left;
        cursor: pointer;
        transition: 0.3s;
        font-size: 17px;
        font-weight: 600;
        margin: 3%;
        border-radius: 4px;
        text-transform: capitalize;
    }

    .tab {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .products-items .spare-parts-items {
        width: 90%;
        display: flex;
        margin-left: 0;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .tabcontent {
        /* float: none; */
        padding: 0px 12px;
        width: 100%;
        border-left: none;
        height: 100%;
        border-radius: 0 10px 10px 0;
        margin-top: 5%;
    }

    /*section-6*/
    #tabs-nav {
        display: flex;
        flex-wrap: wrap;
    }

    .changes-products {
        width: 48%;
        margin-top: 5%;
        position: relative;
        margin-bottom: 8%;
    }

    /*section-7*/
    ul#tabs-nav {
        display: flex;
        flex-wrap: wrap;
    }

    .changes-products img {
        padding: 0px;
        width: 100%;
    }

    .cart-icon {
        position: absolute;
        top: 5%;
        right: 5%;
    }

    /*section-8*/
    .logo-cars h4 {
        font-size: 30px;
    }

    .logo-icons {
        display: flex;
        justify-content: space-around;
        margin-bottom: 38px;
        margin-top: 3%;
        flex-wrap: wrap;
    }

    /*section-9*/

    .blog-img {
        width: 100%;
    }

    .blogs {
        margin-bottom: 30px;
        width: 48%;
    }

    /*footer*/

    .footer-sec {
        background: #E1E1E1;
        display: flex;
        justify-content: space-between;
        padding: 100px 2rem;
        flex-wrap: wrap;
    }

    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(290px, 1fr)) !important;
        gap: 10px !important;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .product-icon.Search-bar-icon {
        display: none;
    }

    .product-icon.search_bar_icon {
        display: none;
    }

    .top-navbar {
        padding: 20px 4rem;
    }

    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(285px, 1fr)) !important;
        gap: 50px !important;
    }
    .nav-links li {
        position: relative;
        padding: 12px 0px;
        font-size: 15px;
        color: #212f63;
        font-weight: 600;
    }
    .heading-h1 h2 {
        font-size: 48px;
        color: white;
        line-height: 70px;
        font-weight: 500;
        width: 90%;
    }
}
@media screen and (min-width: 1801px) and (max-width: 1920px) {
    .nav-links li {
        position: relative;
        padding: 12px 0px;
        font-size: 15px;
        color: #212f63;
        font-weight: 600;
    }
}
@media screen and (min-width: 1921px) and (max-width: 2560px) {
    .nav-links li {
        position: relative;
        padding: 12px 0px;
        font-size: 17px;
        color: #212f63;
        font-weight: 600;
    }
}




@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


* {
    font-family: 'Poppins';
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}

.top-header {
    display: flex;
    padding: 20px 10rem;
    justify-content: space-between;
    background: #EDEDED;
}

.telephone a {
    font-size: 16px;
    text-decoration: none;
    color: #24295E;
    font-weight: 600;
}

.order-lists a {
    font-size: 16px;
    text-decoration: none;
    color: #24295E;
    font-weight: 600;
    padding: 10px;
}

nav {
    width: 100vw;
    display: flex;
    z-index: 10;
    justify-content: space-between;
    padding: 0px 8%;
    align-items: center;
    margin-top: 20px;
}

.nav-links a::before {
    content: "";
    background-color: #F86B1F;
    transition: 0.4s ease-out;
    width: 0%;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0px;
}

.nav-links a:hover::before {
    width: 100%;
}

.logo-toggle-bar {
    display: flex;
}

/*Styling logo*/


/*Styling Links*/
.nav-links {
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    align-items: center;
    text-transform: capitalize;
    width: 100%;
    margin-left: -14px;
}

.nav-links li a {
    text-decoration: none;
    margin: 0 0.7vw;
    font-size: 14px;
    color: #212F63;
    font-weight: 600;
}

.nav-links li a:hover {
    color: #F86B1F;
}

.nav-links li {
    position: relative;
    padding: 12px 0px;
}

.nav-links li a::before {
    content: "";
    display: block;
    height: 3px;
    width: 0%;
    /* background-color: #f05454; */
    position: absolute;
    transition: all ease-in-out 250ms;
    margin: 0 0 0 10%;
    bottom: 0;
}

.nav-links li a:hover::before {
    width: 80%;
}

.toggle,
[id^=drop] {
    display: none;
}

nav a:hover {
    color: rgb(240 84 84)
}

nav ul ul {
    display: none;
    position: absolute;
    top: 60px;
    background: #fff;
    width: 300px;
    list-style: none;
    border-radius: 2px;
    padding-bottom: 15px;
}

nav ul li:hover>ul {
    display: inherit;
}

nav ul ul li {
    width: 100%;
    float: none;
    display: list-item;
    position: relative;
    padding: 7px 0px !important;
}

li>a:only-child:after {
    content: '';
}



/*Styling Button*/

.join-button {
    color: #fff;
    background-color: #f05454;
    border: 1.5px solid #f05454;
    border-radius: 2em;
    padding: 0.4rem 0.8rem;
    font-size: 1rem;
    cursor: pointer;
}

.join-button:hover {
    color: #fff;
    background-color: #264360;
    border: 1.5px solid #f2f5f7;
    transition: all ease-in-out 350ms;
}

/*Styling Hamburger Icon*/
.hamburger div {
    width: 30px;
    height: 3px;
    background: #f2f5f7;
    margin: 5px;
    transition: all 0.3s ease;
}

.hamburger {
    display: none;
}

.logo-design {
    display: none;
}

/*Stying for small screens*/
@media screen and (max-width: 991px) {
    .logo {
        padding: 2vh 3vw;
        text-align: center;
    }

    /* .logo img {
        width: 15rem;
    } */

    /* nav {
        position: fixed;
        z-index: 3;
        padding: 0px 0%;
        display: none;

    } */

    .hamburger {
        display: block;
        position: absolute;
        cursor: pointer;
        right: 5%;
        top: 50%;
        transform: translate(-5%, -50%);
        z-index: 2;
        transition: all 0.7s ease;
    }
/* 
    .nav-links {
        position: fixed;
        background: #131418;
        height: 100vh;
        width: 100%;
        flex-direction: column;
        clip-path: circle(50px at 90% -20%);
        -webkit-clip-path: circle(50px at 90% -10%);
        transition: all 1s ease-out;
        pointer-events: none;
        justify-content: center;
    } */

    .nav-links.open {
        clip-path: circle(1000px at 90% -10%);
        -webkit-clip-path: circle(1000px at 90% -10%);
        pointer-events: all;
        padding: 0px 50px;
    }

    .nav-links li {
        opacity: 0;
    }

    .nav-links li:nth-child(1) {
        transition: all 0.5s ease 0.2s;
    }

    .nav-links li:nth-child(2) {
        transition: all 0.5s ease 0.4s;
    }

    .nav-links li:nth-child(3) {
        transition: all 0.5s ease 0.6s;
    }

    .nav-links li:nth-child(4) {
        transition: all 0.5s ease 0.7s;
    }

    .nav-links li:nth-child(5) {
        transition: all 0.5s ease 0.8s;
    }

    .nav-links li:nth-child(6) {
        transition: all 0.5s ease 0.9s;
        margin: 0;
    }

    .nav-links li:nth-child(7) {
        transition: all 0.5s ease 1s;
        margin: 0;
    }

    li.fade {
        opacity: 1;
    }

    .nav-links li a {
        color: #fff;
    }

    .toggle+a,
    .menu {
        display: none;
    }

    .toggle {
        display: block;
        padding: 14px 0px;
        color: #FFF;
        font-size: 15px;
        text-decoration: none;
        border: none;
    }

    .toggle:hover {
        background-color: transparent;
    }

    [id^=drop]:checked+ul {
        display: block;
        width: 100%;
        background: transparent;
        transition: all 0.3s ease;
    }

    nav ul li {
        display: block;
        width: 100%;
    }

    nav ul ul .toggle,
    nav ul ul a {
        padding: 0 40px;
    }


    nav ul li ul li .toggle,
    nav ul ul a {
        padding: 14px 20px;
        color: #FFF;
        font-size: 17px;
    }

    nav ul ul {
        float: none;
        position: static;
        color: #ffffff;
    }

    nav ul ul li:hover>ul,
    nav ul li:hover>ul {
        display: none;
    }


    nav ul ul li {
        display: block;
        width: 100%;
    }

    .hamburger div {
        background: #30475e;
        margin: 5px;

    }

    .logo-toggle-bar {
        display: none;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    nav {
        padding: 0px 3%;
    }

    /* .logo img {
        width: 14rem;
    } */

    .nav-links li a {
        margin: 0 0.7vw;
        font-size: 14px;
    }

    .nav-links {
        display: flex;
        list-style: none;
        width: 65vw;
    }

    .join-button {
        padding: 0.4rem 0.6rem;
        font-size: 13px;
    }



}



@media screen and (min-width: 1200px) and (max-width: 1366px) {
    .nav-links {
        width: 100%;
    }

    /* .logo img {
        width: 16rem;
    } */
    nav {
        padding: 0px 3rem;
    }

    .nav-links li a {
        text-decoration: none;
        margin: 0 0.7vw;
        font-size: 13px;
        color: #212F63;
        font-weight: 600;
    }

    .center-text-info {
        padding: 11% 12%;
        width: 100%;
        text-align: center;
    }

    #digital-sol .list .box h4 {
        font-size: 20px;
        line-height: 28px;
        padding-bottom: 10px;
    }

    #digital-sol .list .box h3 {
        font-size: 70px;
        line-height: 98px;
    }

    .digital-rev .right span {
        font-size: 32px;
        line-height: 38px;
    }

    .digital-rev .right h2 {
        font-size: 48px;
        line-height: 58px;
        margin: 20px 0px 50px;
    }

    .nav-links {
        display: flex;
        list-style: none;
        padding: 0 0.7vw;
        justify-content: space-evenly;
        align-items: center;
        text-transform: capitalize;
        /* flex-wrap: wrap; */
    }

}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
    .nav-links {
        width: 100%;
    }

    .nav-links {
        display: flex;
        list-style: none;
        padding: 0 0.7vw;
        justify-content: space-evenly;
        align-items: center;
        text-transform: capitalize;

    }

    .logo img {
        width: 16rem;
    }

    #digital-sol .list .box h4 {
        font-size: 20px;
        line-height: 28px;
        padding-bottom: 10px;
    }

    #digital-sol .list .box h3 {
        font-size: 70px;
        line-height: 98px;
    }

    .digital-rev .right span {
        font-size: 32px;
        line-height: 38px;
    }

    .digital-rev .right h2 {
        font-size: 48px;
        line-height: 58px;
        margin: 20px 0px 50px;
    }

}

@media screen and (min-width: 1801px) and (max-width: 1920px) {
    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(330px, 1fr))!important;
        gap: 60px;
    }
}

.toggle .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
    transition: all 0.7s ease;
    width: 0;
}

.toggle .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
}


.ProductCardsectt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
    gap: 60px;
}

.box {
    background-color: #ccc;
    padding: 20px;
    border-radius: 10px;
    color: #333;
    text-align: center;
    font-size: 24px;
    cursor: pointer;
}

.box:hover {
    background-color: #666;
    color: #fff;
}@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    font-family: 'Poppins', sans-serif;
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
    width: 100vw;
}


/*top-navbar*/
fieldset {
    border: none;
}

.custom-select-wrapper {
    position: relative;
    display: block;
}

.custom-select-wrapper select {
    display: none;
    overflow: hidden;
    width: 100%;
}

.custom-select {
    position: relative;
    display: block;
    width: 200px;
    height: 50px;
    border: 1px solid #EDEDED;
    outline: none;
    color: #6F758B;
    font-size: 15px;
    padding-left: 10px;
}

.custom-select-trigger {
    position: relative;
    padding: 0;
    display: block;
    overflow: hidden;
    width: 260px;
    border: 1px solid #EDEDED;
    border-radius: 0;
    background-color: #fff;
    font-size: 20px;
    line-height: 160%;
    color: #3e345c;
    cursor: pointer;
    height: 50px;
}

.custom-select-trigger:after {
    position: absolute;
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    top: 50%;
    right: 20px;
    margin-top: -0.13636363em;
    border-bottom: 1px solid #6F758B;
    border-right: 1px solid #6F758B;
    -webkit-transform: rotate(45deg) translateY(-50%);
    -ms-transform: rotate(45deg) translateY(-50%);
    transform: rotate(45deg) translateY(-50%);
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    -webkit-transform-origin: 50% 0;
    -ms-transform-origin: 50% 0;
    transform-origin: 50% 0;
}

.custom-select.opened .custom-select-trigger:after {
    margin-top: 0.13636363em;
    -webkit-transform: rotate(-135deg) translateY(-50%);
    -ms-transform: rotate(-135deg) translateY(-50%);
    transform: rotate(-135deg) translateY(-50%);
}

.custom-select-trigger-text {
    position: relative;
    top: 0.4em;
    padding: 0 45px 0 20px;
    display: inline-block;
    overflow: hidden;
    height: 57px;
    color: #6F758B;
    font-size: 16px;
}

.custom-options {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    margin: 0;
    display: none;
    min-width: 100%;
    border: 1px solid #ececec;
    border-radius: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    -webkit-transition: all .4s ease-in-out;
    opacity: 0;
    z-index: 10;
    transition: all .4s ease-in-out;
}

.custom-options:before {
    position: absolute;
    display: block;
    content: '';
    top: -0.22727272em;
    right: 25px;
    width: 7px;
    height: 7px;
    margin-bottom: -0.18181818em;
    border-top: 1px solid #ececec;
    border-left: 1px solid #ececec;
    background: #fff;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.option-hover:before {
    background: #f9f9f9;
}

.custom-option {
    position: relative;
    display: block;
    padding: 10px;
    border-bottom: 1px solid #ececec;
    font-size: 1em;
    color: #333333;
    cursor: pointer;
    -webkit-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
}

.custom-option:last-of-type {
    border-bottom: 0;
    border-radius: 10px;
}

.custom-option.selection:hover,
.custom-option:hover {
    background: #fff9e6;
}

.search-box {
    font-size: 15px;
    padding: 14px 19px;
    border: 1px solid #EDEDED;
    background-color: white;
    width: 100%;
    transition: .2s;
    display: flex;
    align-items: center;
    height: 50px;
}

.search-box:hover {
    border-color: #AAAAAA;
}

.search-box:focus-within {
    border-color: #212F63;

}

.search-box input {
    font-family: 'Poppins', sans-serif;
    letter-spacing: -0.2px;
    font-size: 16px;
    border: none;
    color: #6F758B;
}

.search-box::placeholder {
    color: #6F758B !important;
}

button:hover {
    cursor: pointer;
}

.search-box input:focus {
    outline: none;
}

.search-box input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

.clear:not(:valid)~.search-clear {
    display: none;
}

.top-header-part #pet-select {
    display: none !important;
}

.top-navbar {
    display: flex;
    justify-content: space-between;
    padding: 0px 10rem;
    margin-top: 2%;
}

.order-lists img {
    position: relative;
    top: 5px;
}

.icon-boxes {
    display: flex;
}

.product-icon {
    display: flex;
    align-items: center;
    background: #EDEDED;
    padding: 12px 20px;
    height: 50px;
    margin: 10px;
}

.product-icon p {
    margin-right: 5px;
}

.search-boxes {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
}

/*section-1*/
.pagination_wrapper .pagnation_color span:last-child>svg path {
    fill: white !important;
}

.bg-image {
    background: #212F63;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 10px
}

.heading-h1 {
    margin-top: 8%;
    position: relative;
    left: 230px;
    z-index: 1;
}

.heading-h1 h1 {
    font-size: 16px;
    color: white;
    font-weight: 400;
}

.heading-h1 h2 {
    font-size: 56px;
    color: white;
    line-height: 70px;
    font-weight: 500;
    width: 100%;
}

.learn-more-btn {
    background: transparent;
    border: 1px solid white;
    padding: 10px 28px;
    font-size: 16px;
    margin-top: 50px;
    color: white;
    transition: .3s;
}

.learn-more-btn a {
    text-decoration: none;
    font-size: 16px;
    color: white;
    font-weight: 500;
    text-transform: capitalize;
}

.background-img {
    width: 60vw;
    height: 800px;
    position: relative;
}

.phone {
    animation: updown 1s ease 0.2s infinite;
    font-size: 18px;
    color: white;
}

.phone svg {
    width: 20px;
    height: 18px;
    float: left;
    margin-top: 6px;
    margin-right: 8px;
    margin-left: 6px;
}

.phone svg path {
    fill: #ffffff;
}

.phone svg path:nth-child(2) {
    -webkit-animation: header-phone 1s infinite cubic-bezier(.1, .57, .5, 1);
    animation: header-phone 1s infinite cubic-bezier(.1, .57, .5, 1);
}

.phone svg path:nth-child(3) {
    -webkit-animation: header-phone2 1s infinite cubic-bezier(.1, .57, .5, 1);
    animation: header-phone2 1s infinite cubic-bezier(.1, .57, .5, 1);
}


/* Animate phone */

@-webkit-keyframes header-phone {

    0%,
    30% {
        opacity: 0;
        -webkit-transform: translate3d(-20px, 20px, 0);
        transform: translate3d(-20px, 20px, 0)
    }

    80% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    100% {
        opacity: 0
    }
}

@keyframes header-phone {

    0%,
    30% {
        opacity: 0;
        -webkit-transform: translate3d(-20px, 20px, 0);
        transform: translate3d(-20px, 20px, 0)
    }

    80% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    100% {
        opacity: 0
    }
}

@-webkit-keyframes header-phone2 {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-20px, 20px, 0);
        transform: translate3d(-20px, 20px, 0)
    }

    70%,
    90% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    100% {
        opacity: 0
    }
}

@keyframes header-phone2 {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-20px, 20px, 0);
        transform: translate3d(-20px, 20px, 0)
    }

    70%,
    90% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    100% {
        opacity: 0
    }
}

.border-box {
    position: absolute;
    top: 58%;
    right: 8%;

}

.border-line-text {
    position: absolute;
    top: 18%;
    display: flex;
    flex-wrap: wrap;
    left: -45%;
}

.call-us-img {
    background: rgba(255, 255, 255, 0.12);
    text-align: center;
    width: 30px;
    height: 30px;
    margin-right: 12px;
}

.border-line-text h3 {
    color: white;
    font-size: 14px;
    font-weight: 500;

}

.border-line-text p {
    color: white;
    font-size: 14px;
    font-weight: 300;
}


.border-line-text1 {
    position: absolute;
    top: 60%;
    display: flex;
    flex-wrap: wrap;
    left: -45%;
}
.call-us-text{
    margin-right: 10px;
}
.call-us-img {
    background: rgba(255, 255, 255, 0.12);
    text-align: center;
    width: 30px;
    height: 30px;
    margin-right: 12px;
}

.border-line-text1 h3 {
    color: white;
    font-size: 14px;
    font-weight: 500;

}

.border-line-text1 p {
    color: white;
    font-size: 14px;
    font-weight: 300;
}

.bounce-map {
    animation: updown 1s ease 0.2s infinite;
    font-size: 18px;
    color: white;
}

@keyframes updown {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(8px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes shadow {
    0% {
        transform: scale(1);
        opacity: 0.20;
    }

    50% {
        transform: scale(0.95);
        opacity: 0.28;
    }

    100% {
        transform: scale(1);
        opacity: 0.20;
    }
}


/*ARABIC-PART-START*/

/* .border-box1 {
    position: absolute;
    top: 58%;
    left: 8%;
} */
.arabic-heading{
    margin-top: 8%;
    position: relative;
    right: 138px;
    z-index: 1;
}

/*section-2*/

.container {
    padding: 0px 10rem;
}

.list-boxes {
    /* display: flex;
    margin-top: 5%;
    margin-bottom: 5%; */
    max-width: 800px;
}

.list-items {
    display: flex;
    align-items: center;
}

.icon-img {
    background: #F7F7F7;
    padding: 13px;
}

.list-boxes h4 {
    color: #414141;
    font-size: 16px;
    font-weight: 600;
    padding: 0px 25px;
}

.list-boxes p {
    color: #6F758B;
    font-size: 15px;
    padding: 0px 24px;
    width: 80%;
    margin-top: 10px;
}


/*CATEGORIES-PART*/
.categories h3 {
    font-size: 14px;
    color: #6F758B;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
}

.categories h4 {
    font-size: 45px;
    color: #414141;
    text-transform: capitalize;
    text-align: center;
}

.features-list {
    display: flex;
    justify-content: space-between;
    margin-top: 3%;
    margin-bottom: 5%;
}

.features-list div {
    background: #F7F7F7;
    padding: 20px 50px;
    width: 16%;
    height: 190px;
}

.features-list p {
    color: #212F63;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.features-list div:hover {
    background-color: #F86B1F;
}

.features-list div:hover p {
    color: white;
}

/*service-sec*/
.services-sec h3 {
    color: #6F758B;
    font-size: 16px;
    text-align: center;
    font-weight: 600;
}

.services-sec {
    margin-top: 5%;
}

.services-sec h4 {
    font-size: 3rem;
    color: #414141;
    font-weight: 700;
    text-align: center;
}

/* Tabs */

ul#tabs-nav {
    list-style: none;
    margin: 0;
    padding: 5px;
    overflow: auto;
    text-align: center;
    display: flex;
    justify-content: center;
}

ul#tabs-nav li {
    font-weight: bold;
    margin-right: 2px;
    padding: 8px 10px;
    border-radius: 5px 5px 5px 5px;
    /*border: 1px solid #d5d5de;
  border-bottom: none;*/
    cursor: pointer;
}

#tabs-nav li a:hover {
    color: #F86B1F;
}

#tabs-nav li a {
    text-decoration: none;
    color: #212F63;
    font-size: 16px;
    font-weight: 500;
}

.tab-content {
    padding: 10px;
    background-color: #FFF;
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

#tab1 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.tab-content img {
    padding: 0px 50px;
}

.clickable-btn {
    position: absolute;
    left: 5%;
    top: 70%;
    padding: 20px 100px;
    background: #212F63;
    color: white;
    font-size: 16px;
    font-weight: 400;
    border: none;
}

.clickable-btn i {
    position: absolute;
    right: 5%;
    top: 40%;
}

.clickable-btn:hover {
    background-color: #F86B1F;
}

.tabs-click-btn {
    position: relative;
    margin-bottom: 25px;
}

/*product-items*/
.tab {
    float: left;
    background-color: white;
    width: 18%;
    height: 100%;
    border-radius: 10px 0 0 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 5%;
}

.tab button {
    display: flex;
    background-color: inherit;
    color: #212F63;
    width: 90%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 17px;
    font-weight: 600;
    margin: 10%;
    border-radius: 4px;
    text-transform: capitalize;
}

.tablinks.orange-text {
    color: #F86B1F;
}

.tab button:hover {
    background-color: white;
    color: #F86B1F;
}

.tablinks img {
    margin-left: 10px;
    margin-right: 10px;
}

.tabcontent {
    float: left;
    padding: 0px 12px;
    width: 80%;
    border-left: none;
    height: 100%;
    border-radius: 0 10px 10px 0;
    margin-top: 5%;
}

.products-items .spare-parts-items {
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
    background: white;
    border: none;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
    margin-left: 20px;
    flex-wrap: wrap;
    width: 85%;
}

.products-items .tabcontent p {
    color: #212F63;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.products-items .tabcontent p:hover {
    color: #F86B1F;
}

.products-items .tabcontent span {
    color: #BFC2CC;
    font-size: 18px;
    font-weight: 600;
    margin-left: 10px;
}

.products-items .tabcontent div {
    margin-bottom: 65px;
}

/*changes-parts-section*/
#tabs-content .products-items-parts .tab-content {
    display: flex !important;
}

.changes-products img {
    padding: 0px;
}

.changes-products {
    width: 22%;
    margin-top: 5%;
    position: relative;
}

.changes-products h4 {
    color: #212F63;
    font-size: 18px;
    font-weight: 600;
    margin: 5px 0px;
}

.changes-products p {
    color: #6F758B;
    font-size: 16px;
    font-weight: 400;
}

.changes-products h5 {
    color: #333333;
    font-size: 16px;
    font-weight: 600;
}

/* .changes-products img:hover{
  border: 1px solid;
    color: #F86B1F;
} */
.changes-products h4:hover {
    color: #F86B1F;
}

.cart-icon {
    position: absolute;
    top: 10%;
    right: 20%;
}

.cart-icon img {
    display: block;
    background: #E1E1E1;
    padding: 12px;
    margin-bottom: 10px;
}

/*logo-section*/
.logo-cars h3 {
    color: #6F758B;
    font-size: 16px;
    text-align: center;
    font-weight: 600;
}

.logo-cars h4 {
    font-size: 3rem;
    color: #414141;
    font-weight: 700;
    text-align: center;
}

.logo-icons {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 38px;
    margin-top: 3%;
}

.logo-cars {
    margin-top: 5%;
}

/*latest-news-sec*/
.latest-news-sec {
    background: #F7F7F7;
    padding: 2%;
}

.date-list {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.date-list img {
    margin-right: 10px;
}

.date-list p {
    color: #6F758B;
    font-size: 16px;
    font-weight: 500;
}

.blog-content h4 {
    font-size: 18px;
    color: #212F63;
    font-weight: 600;
    margin-top: 15px;
    line-height: 23px;
}

.blog-content p {
    color: #6F758B;
    font-size: 14px;
    font-weight: 500;
    margin-top: 28px;
    width: 90%;
}

.blog-content a {
    color: #212F63;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: 36px;
}

.blog-content i {
    margin-left: 20px;
}


.blog-content h4:hover {
    color: #F86B1F;
}

.blog-content a:hover {
    color: #F86B1F;
}

/*footer*/
.footer-sec {
    background: #E1E1E1;
    display: flex;
    justify-content: space-between;
    padding: 100px 10rem;
}

.contact-us h3 {
    color: #9094A4;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
}

.contact-us p {
    color: #9094A4;
    font-size: 14px;
    font-weight: 600;
}

.contact-us span {
    color: #212F63;
    font-weight: 500;
    font-size: 16px;
}

.contact-list img {
    background: white;
    padding: 13px;
    margin-right: 20px;
}

.customer-services h3 {
    color: #9094A4;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
}

.contact-list {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.customer-services ul {
    list-style-type: none;
}

.customer-services li {
    color: #212F63;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
}

.customer-services p {
    color: #9094A4;
    font-size: 16px;
    font-weight: 500;
}

.subscribe-form {
    position: relative;
    overflow: hidden;
}

.subscribe-form input {
    width: 100%;
    padding: 14px 28px;
    background: #2E2E2E;
    border: 1px solid #2E2E2E;
    color: #fff;
}

.subscribe-form button {
    position: absolute;
    right: 0;
    background: #ff5e14;
    padding: 13px 20px;
    border: 1px solid #ff5e14;
    top: 0;
}

.subscribe-form button i {
    color: #fff;
    font-size: 22px;
    transform: rotate(-6deg);
}

.customer-services input {
    padding: 10px 68px 10px 10px;
    border: none;
    margin-top: 27px;
}

.customer-services button {
    padding: 10px;
    border: none;
    margin-left: -8px;
    background: #212F63;
    color: white;
}

.social-icons {
    margin-top: 30px;
    display: flex;
    align-items: center;
}

.social-icons i {
    background: #212F63;
    font-size: 15px;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    margin-right: 22px;
}

/* 
#pet-select{
    display: none;
} */

@media screen and (max-width: 767px) {
    .container {
        padding: 0px 1rem;
    }

    /*top-header*/
    .top-header {
        display: block;
        padding: 20px 1rem;
        justify-content: space-between;
        background: #EDEDED;
        flex-wrap: wrap;
        position: fixed;
    z-index: 2;
    top: 0;
    }

    #pet-select {
        display: block;
        width: 100%;
        margin: 0px auto;
    }
.banner-section-wrapper{
    margin-top: 1rem;
}
    .order-lists {
        line-height: 35px;
    }

    .logo {
        display: block;
    }

    .top-navbar {
        padding: 0px 1rem;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 0;
        top: 100px;
    }

    .logo-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .product-icon {
        display: flex;
        align-items: center;
        background: #EDEDED;
        padding: 5px 4px;
        height: 32px;
        font-size: 13px;
        margin: 5px;
    }

    .search-boxes {
        display: block;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .searchbar-box {
        margin-bottom: 15px;
        display: none;
    }

    .custom-select-trigger {
        width: 100%;
    }

    .search-box {
        justify-content: space-between;
    }

    .icon-boxes {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 0px;
    }

    /*section-1*/
    .bg-image {
        margin-top: 10px;
        padding: 0px;
        display: block;
        height: 100%;
        z-index: 0;
    }

    .heading-h1 {
        margin-top: 4%;
        position: relative;
        left: 0px;
        z-index: 10;
        padding: 30px 1rem 40px;
    }
 .heading-h1 h1 {
        font-size: 14px;
    }

    .learn-more-btn a {
        text-decoration: none;
        font-size: 14px;
        color: white;
        font-weight: 500;
        text-transform: capitalize;
    }

    .heading-h1 h2 {
        font-size: 22px;
        color: white;
        line-height: 30px;
        font-weight: 500;
        width: 100%;
        margin-top: 12px;
    }

    .learn-more-btn {
        background: transparent;
        border: 1px solid white;
        padding: 8px 22px;
        font-size: 14px;
        margin-top: 22px;
        color: white;
        transition: .3s;
        margin-bottom: 20px;
    }

    .background-img {
        width: 100%;
        height: 100%;
        position: relative;
        top: 20px;
    }

    .border-box {
        position: absolute;
        top: 70%;
        right: -38px;
    }
    .border-line-text p{
        font-size: 12px;
    }
    .border-box img {
        width: 70%;
    }
    .border-line-text1 p{
        font-size: 12px;
    }
    .border-line-text h3{
        font-size: 12px;
    }
    .border-line-text1 h3{
        font-size: 12px;
    }
    .border-line-text {
        position: absolute;
        top: 18%;
        display: flex;
        flex-wrap: wrap;
        right: 0%;
    }

    .border-line-text1 {
        position: absolute;
        top: 52%;
        display: flex;
        flex-wrap: wrap;
        right: 0%;
    }
    .arabic-heading{
        right:0px;
    }
 
       
    /*section-2*/
    .list-boxes {
        display: BLOCK;
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .list-boxes p {
        color: #6F758B;
        font-size: 14px;
        padding: 0px 24px;
        width: 100%;
        margin-top: 10px;
    }

    .list-items {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }

    /*section-3*/
    .list-items {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }

    .features-list {
        display: block;
        margin-top: 7%;
    }

    .features-list div {
        background: #F7F7F7;
        padding: 20px 50px;
        width: 100%;
        height: 190px;
        margin-bottom: 10%;
    }

    /*section-4*/
    .tab-content img {
        padding: 0;
        width: 100%;
    }

    #tab1 {
        display: block;
        width: 100%;
    }

    .clickable-btn {
        position: absolute;
        left: -5%;
        top: 70%;
        padding: 20px 100px;
        background: #212F63;
        color: white;
        font-size: 13px;
        font-weight: 400;
        border: none;
    }

    .categories h4 {
        font-size: 30px;
    }

    /*section-5*/
    .services-sec h4 {
        font-size: 30px;
    }

    .tab {
        width: 100%;
    }

    .products-items .spare-parts-items {
        width: 100%;
        display: block;
        margin-left: 0;
    }

    .tabcontent {
        /* float: none; */
        padding: 0px 12px;
        width: 100%;
        border-left: none;
        height: 100%;
        border-radius: 0 10px 10px 0;
        margin-top: 5%;
    }

    /*section-6*/
    #tabs-nav {
        display: flex;
        flex-wrap: wrap;
    }

    .changes-products {
        width: 100%;
        margin-top: 5%;
        position: relative;
        margin-bottom: 8%;
    }

    /*section-7*/
    ul#tabs-nav {
        display: flex;
        flex-wrap: wrap;
    }

    .changes-products img {
        padding: 0px;
        width: 100%;
    }

    .cart-icon {
        position: absolute;
        top: 5%;
        right: 5%;
    }

    /*section-8*/
    .logo-cars h4 {
        font-size: 30px;
    }

    .logo-icons {
        display: flex;
        justify-content: space-around;
        margin-bottom: 38px;
        margin-top: 3%;
        flex-wrap: wrap;
    }

    /*section-9*/


    .blog-img {
        width: 100%;
    }

    .blogs {
        margin-bottom: 30px;
    }

    /*footer*/

    .footer-sec {
        background: #E1E1E1;
        display: block;
        justify-content: space-between;
        padding: 100px 2rem;
    }

    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(335px, 1fr)) !important;
        gap: 40px !important;
    }
    .ProductCardsectt img{
        width: 100%;
    }
    .accordion-bral {
        min-height: 0;
        min-width: 220px;
        width: 100%;
        height: 100%;
        background-color: #FFF;
        margin: 0px!important;
      }
      .accordion-bral .ac-label {
        font-family: Arial, sans-serif;
        padding: 5px 20px;
        position: relative;
        display: block;
        height: auto;
        cursor: pointer;
        color: #777;
        line-height: 33px;
        font-size: 19px;
        background: #EFEFEF;
        border: 1px solid #CCC;
      }
      .accordion-bral .ac-label:hover {
        background: #BBB;
      }
      .accordion-bral input + .ac-label  {
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }
      .accordion-bral input:checked + .ac-label,
      .accordion-bral input:checked + .ac-label:active {
        background-color: rgba(79, 79, 79, 0.5) !important;
        color: #FFF;
        box-shadow: 0px 0px 0px 1px rgba(155, 155, 155, 0.3), 0px 2px 2px rgba(0, 0, 0, 0.1);
      }
      .accordion-bral input.ac-input {
        display: none;
      }
      .accordion-bral .article {
        background: rgb(240, 240, 240);
        overflow: hidden;
        height: 0px;
        max-height: auto;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }
      .accordion-bral .article p {
        color: #777;
        line-height: 23px;
        font-size: 14px;
        padding: 20px;
      }
      .accordion-bral input:checked ~ .article i {
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
      }
      .accordion-bral input:checked ~ .article.ac-content {
        height: auto;
      }
      
      .accordion-bral i {
        position: absolute;
        transform: translate(-30px, 0);
        margin-top: 16px;
        right: 0;
      }
      .accordion-bral input:checked ~ .ac-label i:before {
        transform: translate(2px, 0) rotate(-45deg);
      }
      .accordion-bral input:checked ~ .ac-label i:after {
        transform: translate(-2px, 0) rotate(45deg);
      }
      .accordion-bral i:before, .accordion-bral i:after {
        content: "";
        position: absolute;
        background-color: #808080;
        width: 3px;
        height: 9px;
      }
      .accordion-bral i:before {
        transform: translate(-2px, 0) rotate(-45deg);
      }
      .accordion-bral i:after {
        transform: translate(2px, 0) rotate(45deg);
      }
      ul.ac-list {
        padding-left: 40px;
        list-style-type: disc;  
      }
      
      table.ac-table {
        margin: 20px 0 20px 20px;
      }
      table.ac-table th{
        text-align: left;
      }
      
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .container {
        padding: 0px 1rem;
    }

    /*top-header*/
    .top-header {
        justify-content: flex-start!important;
        gap: 36px;
        position: fixed;
        z-index: 2;
        top: 0;
        width:100vw;
    
    }

    nav {
        padding: 0px 1rem;
        position: fixed;
        top: 12px!important;
        z-index: 10;
    }
    .order-lists {
        line-height: 35px;
    }
.order-lists a{
    padding: 0px 7px 0px 0px!important;
}
    .logo {
        display: block;
    }

    .logo img {
        width: 100%;
    }

    .top-navbar {
        padding: 0px 1rem;
        margin-top: 5rem;
    }

    .search-boxes {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    .Search-bar-icon {
        display: none;
    }

    .searchbar-box {
        margin-bottom: 0px;
        display: none;
    }

    .custom-select {
        display: none;
    }

    .custom-select-trigger {
        width: 100%;
    }

    .search-box {
        justify-content: space-between;
    }

    .icon-boxes {
        display: flex;
        justify-content: space-between;
        margin-top: 0px;
    }

    .top-header {
        padding: 20px 1rem;
    }

    /*section-1*/
    .bg-image {
        margin-top: 10px;
        padding: 0px;
        display: block;
        height: 100%
    }

    .heading-h1 {
        margin-top: 3%;
        position: relative;
        left: 0px;
        z-index: 2;
        padding: 30px 2rem 50px;
    }

    .heading-h1 h1 {
        font-size: 14px;
    }

    .learn-more-btn a {
        text-decoration: none;
        font-size: 14px;
        color: white;
        font-weight: 500;
        text-transform: capitalize;
    }

    .heading-h1 h2 {
        font-size: 38px;
        color: white;
        line-height: 48px;
        font-weight: 500;
        width: 100%;
        margin-top: 12px;
    }

    .learn-more-btn {
        background: transparent;
        border: 1px solid white;
        padding: 8px 22px;
        font-size: 14px;
        margin-top: 22px;
        color: white;
        transition: .3s;
        margin-bottom: 20px;
    }

    .background-img {
        width: 100%;
        height: 100%;
        position: relative;
        top: 8px;
    }

    .border-box {
        position: absolute;
        top: 70%;
        right:20px;
    }

    .border-box img {
        width: 100%;
    }

    .border-line-text {
        position: absolute;
        top: 18%;
        display: flex;
        flex-wrap: wrap;
        right: 0%;
    }

    .border-line-text1 {
        position: absolute;
        top: 60%;
        display: flex;
        flex-wrap: wrap;
        right: 0%;
    }
    /*section-2*/
    .list-boxes {
        display: flex;
        margin-top: 10%;
        margin-bottom: 10%;
        flex-wrap: wrap;
    }

    .list-boxes p {
        color: #6F758B;
        font-size: 14px;
        padding: 0px 24px;
        width: 100%;
        margin-top: 10px;
    }

    .list-items {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }

    /*section-3*/
    .list-items {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        width: 48%;
    }

    .features-list {
        display: flex;
        margin-top: 7%;
        flex-wrap: wrap;
    }

    .features-list div {
        background: #F7F7F7;
        padding: 20px 50px;
        width: 48%;
        height: 190px;
        margin-bottom: 10%;
    }

    /*section-4*/
    .tab-content img {
        padding: 0;
        width: 100%;
    }

    #tab1 {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .clickable-btn {
        position: absolute;
        left: -5%;
        top: 70%;
        padding: 20px 100px;
        background: #212F63;
        color: white;
        font-size: 13px;
        font-weight: 400;
        border: none;
    }

    .categories h4 {
        font-size: 30px;
    }

    .tabs-click-btn {
        position: relative;
        margin-bottom: 25px;
        width: 48%;
    }

    /*section-5*/
    .services-sec h4 {
        font-size: 30px;
    }

    .tab button {
        display: flex;
        background-color: inherit;
        color: #212F63;
        width: 30%;
        border: none;
        outline: none;
        text-align: left;
        cursor: pointer;
        transition: 0.3s;
        font-size: 17px;
        font-weight: 600;
        margin: 3%;
        border-radius: 4px;
        text-transform: capitalize;
    }

    .tab {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .products-items .spare-parts-items {
        width: 90%;
        display: flex;
        margin-left: 0;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .tabcontent {
        /* float: none; */
        padding: 0px 12px;
        width: 100%;
        border-left: none;
        height: 100%;
        border-radius: 0 10px 10px 0;
        margin-top: 5%;
    }

    /*section-6*/
    #tabs-nav {
        display: flex;
        flex-wrap: wrap;
    }

    .changes-products {
        width: 48%;
        margin-top: 5%;
        position: relative;
        margin-bottom: 8%;
    }

    /*section-7*/
    ul#tabs-nav {
        display: flex;
        flex-wrap: wrap;
    }

    .changes-products img {
        padding: 0px;
        width: 100%;
    }

    .cart-icon {
        position: absolute;
        top: 5%;
        right: 5%;
    }

    /*section-8*/
    .logo-cars h4 {
        font-size: 30px;
    }

    .logo-icons {
        display: flex;
        justify-content: space-around;
        margin-bottom: 38px;
        margin-top: 3%;
        flex-wrap: wrap;
    }

    /*section-9*/

    .blog-img {
        width: 100%;
    }

    .blogs {
        margin-bottom: 30px;
        width: 48%;
    }

    /*footer*/

    .footer-sec {
        background: #E1E1E1;
        display: flex;
        justify-content: space-between;
        padding: 100px 2rem;
        flex-wrap: wrap;
    }

    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr)) !important;
        gap: 40px !important;
    }
    .ProductCardsectt img{
        width: 100%;
    }
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .container {
        padding: 0px 1rem;
    }

    .top-navbar {
        padding: 0px 1rem;
    }

    .search-boxes {
        display: flex;
        width: 78%;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    .nav-links li {
        position: relative;
        padding: 12px 0px;
        font-size: 13px;
        color: #212f63;
        font-weight: 600;
    }
/* 
    .bg-image {
        padding: 0px 0px 0px 1rem;
    } */

    .heading-h1 {
        margin-top: 8%;
        position: relative;
        left: 80px;
        z-index: 1;
        padding-bottom: 50px;
    }

    .border-box {
        position: absolute;
        top: 48%;
        right:70px;
    }

    .border-box img {
        width: 100%;
    }

    .border-line-text {
        position: absolute;
        top: 18%;
        display: flex;
        flex-wrap: wrap;
        right: 0%;
    }

    .border-line-text1 {
        position: absolute;
        top: 60%;
        display: flex;
        flex-wrap: wrap;
        right: 0%;
    }
    .searchbar-box {
        margin-top: 5px;
    }

    .heading-h1 h2 {
        font-size: 38px;
        color: white;
        line-height: 44px;
        font-weight: 500;
        margin-top: 11px;
        width: 100%;
    }

    .icon-boxes {
        display: flex;
        margin-top: 14px;
    }

    .top_categgories_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
        gap: 38px;
        margin-top: 30px;
    }

    .service_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
        gap: 60px;
        margin-top: 30px;
    }

    .our_product_tab_section>ul {
        list-style: none;
        inline-size: 200px;
    }

    .our_product_card_section_wrapper,
    .our_product_tab_section {
        padding: 50px 18px;
        background: #FFFFFF;
        height: 100%;
    }

    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(245px, 1fr)) !important;
        gap: 15px !important;
    }
}
@media screen and (min-width: 1201px) and (max-width: 1366px) {
    .container {
        padding: 0px 1rem;
    }

    /*top-header*/
    .top-header {
        display: block;
        padding: 20px 1rem;
        justify-content: space-between;
        background: #EDEDED;
        flex-wrap: wrap;
    }

    .order-lists {
        line-height: 35px;
    }

    .logo {
        display: block;
    }

    .logo img {
        width: 100%;
    }

    .top-navbar {
        padding: 0px 2rem;
        column-gap: 30px;
    }

    .search-boxes {
        display: flex;
        width: 88%;
        justify-content: space-between;
        align-items: center;

    }
    .nav-links li {
        position: relative;
        padding: 12px 0px;
        font-size: 13px;
        color: #212f63;
        font-weight: 600;
    }
    .product-icon.Search-bar-icon {
        display: none;
    }

    .product-icon.search_bar_icon {
        display: none;
    }

    /* .searchbar-box{
    margin-bottom: 15px;
} */
    .custom-select-trigger {
        width: 100%;
    }

    .search-box {
        justify-content: space-between;
        margin-top: 5px;
    }

    .icon-boxes {
        display: flex;
        justify-content: space-between;

    }

    /*section-1*/
    .bg-image {
        margin-top: 10px;
        padding: 0px;
        display: flex;
        height: 100%
    }

    .heading-h1 {
        margin-top: 8%;
        position: relative;
        left: 100px;
        z-index: 1;
        padding: 30px 0rem 50px;
    }

    .heading-h1 h1 {
        font-size: 16px;
        color: white;
        font-weight: 400;
    }

    .learn-more-btn a {
        text-decoration: none;
        font-size: 14px;
        color: white;
        font-weight: 500;
        text-transform: capitalize;
    }

    .heading-h1 h2 {
        font-size: 31px;
        color: white;
        line-height: 50px;
        font-weight: 500;
        width: 100%;
    }

    .learn-more-btn {
        background: transparent;
        border: 1px solid white;
        padding: 10px 28px;
        font-size: 16px;
        margin-top: 50px;
        color: white;
        transition: .3s;
    }

    .background-img {
        width: 60vw;
        height: 600px;
        position: relative;
    }

    .border-box {
        position: absolute;
        top: 48%;
        right:88px;
    }

    .border-box img {
        width: 100%;
    }

    .border-line-text {
        position: absolute;
        top: 18%;
        display: flex;
        flex-wrap: wrap;
        right: 0%;
    }

    .border-line-text1 {
        position: absolute;
        top: 60%;
        display: flex;
        flex-wrap: wrap;
        right: 0%;
    }

    /*section-2*/
    .list-boxes {
        display: flex;
        margin-top: 10%;
        margin-bottom: 10%;
        flex-wrap: wrap;
    }

    .list-boxes p {
        color: #6F758B;
        font-size: 14px;
        padding: 0px 24px;
        width: 100%;
        margin-top: 10px;
    }

    .list-items {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }

    /*section-3*/
    .list-items {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        width: 48%;
    }

    .features-list {
        display: flex;
        margin-top: 7%;
        flex-wrap: wrap;
    }

    .features-list div {
        background: #F7F7F7;
        padding: 20px 50px;
        width: 48%;
        height: 190px;
        margin-bottom: 10%;
    }

    /*section-4*/
    .tab-content img {
        padding: 0;
        width: 100%;
    }

    #tab1 {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .clickable-btn {
        position: absolute;
        left: -5%;
        top: 70%;
        padding: 20px 100px;
        background: #212F63;
        color: white;
        font-size: 13px;
        font-weight: 400;
        border: none;
    }

    .categories h4 {
        font-size: 30px;
    }

    .tabs-click-btn {
        position: relative;
        margin-bottom: 25px;
        width: 48%;
    }

    /*section-5*/
    .services-sec h4 {
        font-size: 30px;
    }

    .tab button {
        display: flex;
        background-color: inherit;
        color: #212F63;
        width: 30%;
        border: none;
        outline: none;
        text-align: left;
        cursor: pointer;
        transition: 0.3s;
        font-size: 17px;
        font-weight: 600;
        margin: 3%;
        border-radius: 4px;
        text-transform: capitalize;
    }

    .tab {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .products-items .spare-parts-items {
        width: 90%;
        display: flex;
        margin-left: 0;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .tabcontent {
        /* float: none; */
        padding: 0px 12px;
        width: 100%;
        border-left: none;
        height: 100%;
        border-radius: 0 10px 10px 0;
        margin-top: 5%;
    }

    /*section-6*/
    #tabs-nav {
        display: flex;
        flex-wrap: wrap;
    }

    .changes-products {
        width: 48%;
        margin-top: 5%;
        position: relative;
        margin-bottom: 8%;
    }

    /*section-7*/
    ul#tabs-nav {
        display: flex;
        flex-wrap: wrap;
    }

    .changes-products img {
        padding: 0px;
        width: 100%;
    }

    .cart-icon {
        position: absolute;
        top: 5%;
        right: 5%;
    }

    /*section-8*/
    .logo-cars h4 {
        font-size: 30px;
    }

    .logo-icons {
        display: flex;
        justify-content: space-around;
        margin-bottom: 38px;
        margin-top: 3%;
        flex-wrap: wrap;
    }

    /*section-9*/


    .blog-img {
        width: 100%;
    }

    .blogs {
        margin-bottom: 30px;
        width: 48%;
    }

    /*footer*/

    .footer-sec {
        background: #E1E1E1;
        display: flex;
        justify-content: space-between;
        padding: 100px 2rem;
        flex-wrap: wrap;
    }

    .search-box input {
        font-family: 'Poppins', sans-serif;
        letter-spacing: -0.2px;
        font-size: 14px;
        border: none;
        color: #6F758B;
    }

    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr)) !important;
        gap: 20px !important;
    }
}

@media screen and (min-width: 1367px) and (max-width: 1600px) {
    .container {
        padding: 0px 1rem;
    }

    /*top-header*/
    .top-header {
        display: block;
        padding: 20px 1rem;
        justify-content: space-between;
        background: #EDEDED;
        flex-wrap: wrap;
    }
    .nav-links li {
        position: relative;
        padding: 12px 0px;
        font-size: 14px;
        color: #212f63;
        font-weight: 600;
    }
    .order-lists {
        line-height: 35px;
    }

    .logo {
        display: block;
    }

    .logo img {
        width: 100%;
    }

    .top-navbar {
        padding: 0px 4rem;
    }

    .product-icon.Search-bar-icon {
        display: none;
    }

    .product-icon.search_bar_icon {
        display: none;
    }

    .top-header {
        padding: 0px 4rem;
    }

    .nav-links li a {
        text-decoration: none;
        margin: 0 0.7vw;
        font-size: 13px;
        color: #212F63;
        font-weight: 500;
    }

    .search-boxes {
        display: flex;
        width: 85%;
        justify-content: space-between;
        align-items: center;

    }

    /* .searchbar-box{
    margin-bottom: 15px;
} */
    .custom-select-trigger {
        width: 100%;
    }

    .search-box {
        justify-content: space-between;
        margin-top: 5px;
    }

    .icon-boxes {
        display: flex;
        justify-content: space-between;

    }

    /*section-1*/
    .bg-image {
        margin-top: 10px;
        padding: 0px;
        display: flex;
        height: 100%
    }

    .heading-h1 {
        margin-top: 4%;
        position: relative;
        left: 230px;
        z-index: 2;
        padding: 30px 0rem 50px;
    
    }

    .heading-h1 h1 {
        font-size: 14px;
    }

    .learn-more-btn a {
        text-decoration: none;
        font-size: 14px;
        color: white;
        font-weight: 500;
        text-transform: capitalize;
    }

    .heading-h1 h2 {
        font-size: 50px;
        color: white;
        line-height: 68px;
        font-weight: 500;
        width: 100%;
        margin-top: 12px;

    }
    .learn-more-btn {
        background: transparent;
        border: 1px solid white;
        padding: 8px 22px;
        font-size: 14px;
        margin-top: 22px;
        color: white;
        transition: .3s;
        margin-bottom: 20px;
    }

    .background-img {
        height: 100%;
        position: relative;
    }

    .border-box {
        position: absolute;
        top: 48%;
        right:70px;
    }

    .border-box img {
        width: 100%;
    }

    .border-line-text {
        position: absolute;
        top: 18%;
        display: flex;
        flex-wrap: wrap;
        right: 0%;
    }

    .border-line-text1 {
        position: absolute;
        top: 60%;
        display: flex;
        flex-wrap: wrap;
        right: 0%;
    }

    /*section-2*/
    .list-boxes {
        display: flex;
        margin-top: 10%;
        margin-bottom: 10%;
        flex-wrap: wrap;
    }

    .list-boxes p {
        color: #6F758B;
        font-size: 14px;
        padding: 0px 24px;
        width: 100%;
        margin-top: 10px;
    }

    .list-items {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }


    /*section-3*/
    .list-items {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        width: 48%;
    }

    .features-list {
        display: flex;
        margin-top: 7%;
        flex-wrap: wrap;
    }

    .features-list div {
        background: #F7F7F7;
        padding: 20px 50px;
        width: 48%;
        height: 190px;
        margin-bottom: 10%;
    }

    /*section-4*/
    .tab-content img {
        padding: 0;
        width: 100%;
    }

    #tab1 {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .clickable-btn {
        position: absolute;
        left: -5%;
        top: 70%;
        padding: 20px 100px;
        background: #212F63;
        color: white;
        font-size: 13px;
        font-weight: 400;
        border: none;
    }

    .categories h4 {
        font-size: 30px;
    }

    .tabs-click-btn {
        position: relative;
        margin-bottom: 25px;
        width: 48%;
    }

    /*section-5*/
    .services-sec h4 {
        font-size: 30px;
    }

    .tab button {
        display: flex;
        background-color: inherit;
        color: #212F63;
        width: 30%;
        border: none;
        outline: none;
        text-align: left;
        cursor: pointer;
        transition: 0.3s;
        font-size: 17px;
        font-weight: 600;
        margin: 3%;
        border-radius: 4px;
        text-transform: capitalize;
    }

    .tab {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .products-items .spare-parts-items {
        width: 90%;
        display: flex;
        margin-left: 0;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .tabcontent {
        /* float: none; */
        padding: 0px 12px;
        width: 100%;
        border-left: none;
        height: 100%;
        border-radius: 0 10px 10px 0;
        margin-top: 5%;
    }

    /*section-6*/
    #tabs-nav {
        display: flex;
        flex-wrap: wrap;
    }

    .changes-products {
        width: 48%;
        margin-top: 5%;
        position: relative;
        margin-bottom: 8%;
    }

    /*section-7*/
    ul#tabs-nav {
        display: flex;
        flex-wrap: wrap;
    }

    .changes-products img {
        padding: 0px;
        width: 100%;
    }

    .cart-icon {
        position: absolute;
        top: 5%;
        right: 5%;
    }

    /*section-8*/
    .logo-cars h4 {
        font-size: 30px;
    }

    .logo-icons {
        display: flex;
        justify-content: space-around;
        margin-bottom: 38px;
        margin-top: 3%;
        flex-wrap: wrap;
    }

    /*section-9*/

    .blog-img {
        width: 100%;
    }

    .blogs {
        margin-bottom: 30px;
        width: 48%;
    }

    /*footer*/

    .footer-sec {
        background: #E1E1E1;
        display: flex;
        justify-content: space-between;
        padding: 100px 2rem;
        flex-wrap: wrap;
    }

    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(290px, 1fr)) !important;
        gap: 10px !important;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .product-icon.Search-bar-icon {
        display: none;
    }

    .product-icon.search_bar_icon {
        display: none;
    }
    .top-navbar {
        padding: 20px 4rem;
    }

    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(285px, 1fr)) !important;
        gap: 50px !important;
    }
    /* .ProductCardsectt img{
        width: 100%;
    } */
    .nav-links li {
        position: relative;
        padding: 12px 0px;
        font-size: 15px;
        color: #212f63;
        font-weight: 600;
    }
    .heading-h1 h2 {
        font-size: 52px;
        color: white;
        line-height: 70px;
        font-weight: 500;
        width: 100%;
    }
  
}
@media screen and (min-width: 1801px) and (max-width: 1920px) {
    .nav-links li {
        position: relative;
        padding: 12px 0px;
        font-size: 15px;
        color: #212f63;
        font-weight: 600;
    }
}
@media screen and (min-width: 1921px) and (max-width: 2560px) {
    .nav-links li {
        position: relative;
        padding: 12px 0px;
        font-size: 17px;
        color: #212f63;
        font-weight: 600;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


* {
    font-family: 'Poppins';
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}

.top-header {
    display: flex;
    padding: 20px 10rem;
    justify-content: space-between;
    background: #EDEDED;
}

.telephone a {
    font-size: 16px;
    text-decoration: none;
    color: #24295E;
    font-weight: 600;
}

.order-lists a {
    font-size: 16px;
    text-decoration: none;
    color: #24295E;
    font-weight: 600;
    padding: 10px;
}

nav {
    width: 100vw;
    display: flex;
    z-index: 10;
    justify-content: space-between;
    padding: 0px 8%;
    align-items: center;
    margin-top: 20px;
}

.nav-links a::before {
    content: "";
    background-color: #F86B1F;
    transition: 0.4s ease-out;
    width: 0%;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0px;
}

.nav-links a:hover::before {
    width: 100%;
}

.logo-toggle-bar {
    display: flex;
}

/*Styling logo*/


/*Styling Links*/
.nav-links {
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    align-items: center;
    text-transform: capitalize;
    width: 100%;
    margin-left: -14px;
}

.nav-links li a {
    text-decoration: none;
    margin: 0 0.7vw;
    font-size: 14px;
    color: #212F63;
    font-weight: 600;
}

.nav-links li a:hover {
    color: #F86B1F;
}

.nav-links li {
    position: relative;
    padding: 12px 0px;
}

.nav-links li a::before {
    content: "";
    display: block;
    height: 3px;
    width: 0%;
    /* background-color: #f05454; */
    position: absolute;
    transition: all ease-in-out 250ms;
    margin: 0 0 0 10%;
    bottom: 0;
}

.nav-links li a:hover::before {
    width: 80%;
}

.toggle,
[id^=drop] {
    display: none;
}

nav a:hover {
    color: rgb(240 84 84)
}

nav ul ul {
    display: none;
    position: absolute;
    top: 60px;
    background: #fff;
    width: 300px;
    list-style: none;
    border-radius: 2px;
    padding-bottom: 15px;
}

nav ul li:hover>ul {
    display: inherit;
}

nav ul ul li {
    width: 100%;
    float: none;
    display: list-item;
    position: relative;
    padding: 7px 0px !important;
}

li>a:only-child:after {
    content: '';
}



/*Styling Button*/

.join-button {
    color: #fff;
    background-color: #f05454;
    border: 1.5px solid #f05454;
    border-radius: 2em;
    padding: 0.4rem 0.8rem;
    font-size: 1rem;
    cursor: pointer;
}

.join-button:hover {
    color: #fff;
    background-color: #264360;
    border: 1.5px solid #f2f5f7;
    transition: all ease-in-out 350ms;
}

/*Styling Hamburger Icon*/
.hamburger div {
    width: 30px;
    height: 3px;
    background: #f2f5f7;
    margin: 5px;
    transition: all 0.3s ease;
}

.hamburger {
    display: none;
}

.logo-design {
    display: none;
}

/*Stying for small screens*/
@media screen and (max-width: 991px) {
    .logo {
        padding: 2vh 3vw;
        text-align: center;
    }

    /* .logo img {
        width: 15rem;
    } */

    /* nav {
        position: fixed;
        z-index: 3;
        padding: 0px 0%;
        display: none;

    } */

    .hamburger {
        display: block;
        position: absolute;
        cursor: pointer;
        right: 5%;
        top: 50%;
        transform: translate(-5%, -50%);
        z-index: 2;
        transition: all 0.7s ease;
    }

    /* .nav-links {
        position: fixed;
        background: #131418;
        height: 100vh;
        width: 100%;
        flex-direction: column;
        clip-path: circle(50px at 90% -20%);
        -webkit-clip-path: circle(50px at 90% -10%);
        transition: all 1s ease-out;
        pointer-events: none;
        justify-content: center;
    } */

    .nav-links.open {
        clip-path: circle(1000px at 90% -10%);
        -webkit-clip-path: circle(1000px at 90% -10%);
        pointer-events: all;
        padding: 0px 50px;
    }

    .nav-links li {
        opacity: 0;
    }

    .nav-links li:nth-child(1) {
        transition: all 0.5s ease 0.2s;
    }

    .nav-links li:nth-child(2) {
        transition: all 0.5s ease 0.4s;
    }

    .nav-links li:nth-child(3) {
        transition: all 0.5s ease 0.6s;
    }

    .nav-links li:nth-child(4) {
        transition: all 0.5s ease 0.7s;
    }

    .nav-links li:nth-child(5) {
        transition: all 0.5s ease 0.8s;
    }

    .nav-links li:nth-child(6) {
        transition: all 0.5s ease 0.9s;
        margin: 0;
    }

    .nav-links li:nth-child(7) {
        transition: all 0.5s ease 1s;
        margin: 0;
    }

    li.fade {
        opacity: 1;
    }

    .nav-links li a {
        color: #fff;
    }

    .toggle+a,
    .menu {
        display: none;
    }

    .toggle {
        display: block;
        padding: 14px 0px;
        color: #FFF;
        font-size: 15px;
        text-decoration: none;
        border: none;
    }

    .toggle:hover {
        background-color: transparent;
    }

    [id^=drop]:checked+ul {
        display: block;
        width: 100%;
        background: transparent;
        transition: all 0.3s ease;
    }

    nav ul li {
        display: block;
        width: 100%;
    }

    nav ul ul .toggle,
    nav ul ul a {
        padding: 0 40px;
    }


    nav ul li ul li .toggle,
    nav ul ul a {
        padding: 14px 20px;
        color: #FFF;
        font-size: 17px;
    }

    nav ul ul {
        float: none;
        position: static;
        color: #ffffff;
    }

    nav ul ul li:hover>ul,
    nav ul li:hover>ul {
        display: none;
    }


    nav ul ul li {
        display: block;
        width: 100%;
    }

    .hamburger div {
        background: #30475e;
        margin: 5px;

    }

    /* .logo-toggle-bar {
        display: none;
    } */
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    nav {
        padding: 0px 3%;
    }

    /* .logo img {
        width: 14rem;
    } */

    .nav-links li a {
        margin: 0 0.7vw;
        font-size: 14px;
    }

    .nav-links {
        display: flex;
        list-style: none;
        width: 65vw;
    }

    .join-button {
        padding: 0.4rem 0.6rem;
        font-size: 13px;
    }



}



@media screen and (min-width: 1200px) and (max-width: 1366px) {
    .nav-links {
        width: 100%;
    }

    /* .logo img {
        width: 16rem;
    } */
    nav {
        padding: 0px 3rem;
    }

    .nav-links li a {
        text-decoration: none;
        margin: 0 0.7vw;
        font-size: 13px;
        color: #212F63;
        font-weight: 600;
    }

    .center-text-info {
        padding: 11% 12%;
        width: 100%;
        text-align: center;
    }

    #digital-sol .list .box h4 {
        font-size: 20px;
        line-height: 28px;
        padding-bottom: 10px;
    }

    #digital-sol .list .box h3 {
        font-size: 70px;
        line-height: 98px;
    }

    .digital-rev .right span {
        font-size: 32px;
        line-height: 38px;
    }

    .digital-rev .right h2 {
        font-size: 48px;
        line-height: 58px;
        margin: 20px 0px 50px;
    }

    .nav-links {
        display: flex;
        list-style: none;
        padding: 0 0.7vw;
        justify-content: space-evenly;
        align-items: center;
        text-transform: capitalize;
        /* flex-wrap: wrap; */
    }

}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
    .nav-links {
        width: 100%;
    }

    .nav-links {
        display: flex;
        list-style: none;
        padding: 0 0.7vw;
        justify-content: space-evenly;
        align-items: center;
        text-transform: capitalize;

    }

    .logo img {
        width: 16rem;
    }

    #digital-sol .list .box h4 {
        font-size: 20px;
        line-height: 28px;
        padding-bottom: 10px;
    }

    #digital-sol .list .box h3 {
        font-size: 70px;
        line-height: 98px;
    }

    .digital-rev .right span {
        font-size: 32px;
        line-height: 38px;
    }

    .digital-rev .right h2 {
        font-size: 48px;
        line-height: 58px;
        margin: 20px 0px 50px;
    }

}

@media screen and (min-width: 1801px) and (max-width: 1920px) {
    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(330px, 1fr))!important;
        gap: 60px;
    }
}

.toggle .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
    transition: all 0.7s ease;
    width: 0;
}

.toggle .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
}


.ProductCardsectt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
    gap: 60px;
}

.box {
    background-color: #ccc;
    padding: 20px;
    border-radius: 10px;
    color: #333;
    text-align: center;
    font-size: 24px;
    cursor: pointer;
}

.box:hover {
    background-color: #666;
    color: #fff;
}


/* slider css */
.slick-dots {
    position: absolute;
    bottom: 28%!important;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: left;
    right: 0;
    left: 230px;
}
.slick-dots li button:before {
    opacity: 5.75!important;
    color: white!important;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 20px!important;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 20px;
    /* content: 'واحد'!important; */
    content: '1'!important;
    text-align: center;
    opacity: .25;
    color: black;
    border: 1px solid;
    padding: 4px 15px;
}
.slick-dots li:nth-child(2) button:before {
    font-family: 'slick';
    font-size: 20px!important;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 20px;
    /* content: 'اثنان'!important; */
    content: '2'!important;
    text-align: center;
    opacity: .25;
    color: black;
    border: 1px solid;
    padding: 4px 15px;
}

.slick-dots li:nth-child(3) button:before {
    font-family: 'slick';
    font-size: 20px!important;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 20px;
    /* content: 'تلاتة'!important; */
    content: '3'!important;
    text-align: center;
    opacity: .25;
    color: black;
    border: 1px solid;
    padding: 4px 15px;
}
.slick-dots li:nth-child(4) button:before {
    font-family: 'slick';
    font-size: 20px!important;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 20px;
    content: 'أربعة'!important;
    text-align: center;
    opacity: .25;
    color: black;
    border: 1px solid;
    padding: 4px 15px;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 20px;
    margin: 0;
    padding: 0;
    margin-right: 18px;
    cursor: pointer;

}

@media screen and (min-width: 1201px) and (max-width: 1366px) {

    .slick-dots {
        position: absolute;
        bottom: 10%!important;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: left;
        right: 0;
        left: 100px;
    }
}
@media screen and (min-width: 1921px) and (max-width: 2560px) {
    .nav-links {
        display: flex;
        list-style: none;
        justify-content: space-between;
        align-items: center;
        text-transform: capitalize;
        width: 100%;
        flex-wrap: wrap;
        gap: 14px
    }
}


  
.inline_text {
    display: inline-flex;
    align-items: center; 
    margin-left: 3px;
  }
  
  .dropdown_text {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: #212F63;
    margin-right: 20px;
  }

  .icon_image{
    margin-left: 30px;
    margin-right: 15px;
    margin-bottom: 1px;

  }
  .autoparts_text{
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #212F63;
    text-align: left;

  }
/*   
  .dropdown {
    position: relative; 
    display: inline-flex;
    align-items: center; 
  }
  
  
  
  .dropdown svg {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%); 
    cursor: pointer;
  } */
  