/* .login_modal_wrapper{
    max-width: 700px !important;
} */


.header_section {
    display: flex;
    justify-content: space-between;
    padding: 20px 5%;
    background: #EDEDED;
}

.order-lists img{
    position: relative;
    top:5px;
}
.header_section>a {
    /* UI/Header Menu 1st line */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 140%;
    /* or 18px */


    color: #24295E;
}
.main_dropdown{
    font-family: 'Neue Haas Grotesk Text Pro';
    font-size: 14px;
    font-weight: 400;
    line-height:19.6px ;
    color: #212F63;

}
.header_content {
    display: flex;
    gap: 10px
}

.header_content>a {
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 140%;
    /* identical to box height, or 17px */


    color: #24295E;
}

.search_modal_opener{
    top: 0% !important;
    left: 0% !important;
    bottom: 0% !important;
    transform: translate(-0%, -0%) !important;
    max-height: 100% !important;
    max-width: 100% !important;
}

/* media query */
@media screen and (max-width: 560px) {
    .header_section {
        display: grid;
        position: relative;
    }
}


.dropdown_main_wrapper {
    /* z-index: 1000px;
    background: #FFFFFF;
    padding: var(--whitespace);
   */
   position: relative;
}

.dropdown_main_wrapper>section {
    /* display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 30px; */
    display: flex;
    gap: 20px;
    position: absolute;
    z-index: 1;
    flex-wrap: wrap;
    background: white;
    padding: 15px 13px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    top: 8px;
    left: 8%;
    right: 8%;
}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
    
.dropdown_main_wrapper>section {
    /* display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 30px; */
    display: flex;
    gap: 20px;
    position: absolute;
    z-index: 1;
    flex-wrap: wrap;
    background: white;
    padding: 15px 13px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    top: 8px;
    left: 6%;
    right: 6%;
}
}
@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .dropdown_main_wrapper>section {
        /* display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 30px; */
        display: flex;
        gap: 20px;
        position: absolute;
        z-index: 1;
        flex-wrap: wrap;
        background: white;
        padding: 15px 13px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        top: 8px;
        left: 6%;
        right: 6%;
    
    } 
}

@media only screen and (max-width: 600px) {
    .nav-links {
      display: none;
      background-color: rgb(220, 104, 15); 
    }
  }
