.heading {
    color: var(--light-blue1);
    background-color: var(--green);
}
.our_product_tab_section>ul>li>p {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    gap: 30px;
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */
    text-transform: capitalize;
    /* Primary 01 */

    color: #212F63;
}
.pagnation_color>span {
    color: white !important
}

.pagnation_color>span>svg {

    stroke: var(--white) !important;
}

.pagination_view_all_wrapper.pagnation_color {
    display: block !important;
}

.pagnation_colorspan:last-child>svg {
    position: absolute !important;
    top: 50% !important ;
    left: 50% !important;
    ;
    transform: translate(-50%, -50%) !important;
    ;
}


/* info section wrapper start */

.info_section_wrapper {
    padding: var(--whitespace);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 30px;
    margin: 30px 0;
}

.info_section_icon {
    width: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--light-gray2);
}

.info_section_wrapper>aside {
    max-width: 320px;
    display: grid;
    grid-template-columns: 20% 80%;
}

.info_section_content {
    margin-left: 30px;
}

.info_section_content>h4 {
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 140%;
    margin-bottom: 5px;
    color: var(--dark-gray);
}

.info_section_content>p {
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 400;
    font-size: .9rem;
    line-height: 140%;
    color: var(--light-brinjal);
}

/* info section wrapper end */


.no_product_found_text_wrapper{
    text-align: center;
}
/* top categgories section wrapper start */
.top_categgories_section_wrapper {
    padding: var(--whitespace);
}

.top_categgories_section_sub_heading {
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 500;
    font-size: .9rem;
    line-height: 15px;
    text-transform: uppercase;
    text-align: center;
    color: var(--light-brinjal);
    margin-bottom: 10px;
}

.top_categgories_section_heading {
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: bolder;
    font-size: 2.2rem;
    line-height: 140%;
    text-align: center;
    color: var(--black);
}

.top_categgories_card_section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    gap: 38px;
    margin-top: 30px;
}

.top_categgories_card_section>div {
    width: auto;
    background: var(--light-gray2);
    height: 200px;
    /* display: flex; */
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 35px 10% 10px;
    align-items: center;
}

.top_categgories_card_section p {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: end;
    align-items: center;
}

.top_categgories_card_section>div:hover {
    background-color: var(--dark-orange);
    color: var(--white);
    fill: var(--white);
    stroke: var(--white);
}

.top_categgories_card_section>div:hover svg path {
    fill: white !important;
    transition: all ease 0.3s;
}

.car_logo_section>div:hover img {
    transform: scale(1);
    filter: brightness(1000%) grayscale(100%) opacity(0.1) drop-shadow(rgb(249 125 61) 0px 0px 0px) drop-shadow(rgb(249 125 61) 0px 0px 0px) drop-shadow(rgb(249 125 61) 0px 0px 0px) drop-shadow(rgb(249 125 61) 0px 0px 0px) drop-shadow(rgb(249 125 61) 0px 0px 0px);
    transition: 0.5s ease-in-out;
    transform: scale(1.2);
}

.car_logo_section img {
    filter: grayscale(1);
    cursor: pointer;
    padding-bottom: 1rem;
    
}

/* top categgories section wrapper end */

/* service offer section start */
.service_section_wrapper {
    padding: var(--whitespace);
}

.service_section_wrapper>p {

    /* H --/Subtitle */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-transform: uppercase;
    text-align: center;

    /* Grey 1 */

    color: #6F758B;

}

.service_section_wrapper>h1 {
    /* H --/H1 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;
    /* identical to box height, or 56px */

    text-align: center;

    /* Black */

    color: #414141;
}

ul.sevice_tabs {
    list-style: none;
    margin: 0;
    padding: 5px;
    overflow: auto;
    text-align: center;
    display: flex;
    justify-content: center;
}

ul.sevice_tabs li {
    font-size: 18px !important;
    font-weight: '500' !important;
    margin-right: 2px;
    padding: 8px 10px;
    border-radius: 5px 5px 5px 5px;
    /*border: 1px solid #d5d5de;
  border-bottom: none;*/
    cursor: pointer;
    color: #212F63 !important;
}

ul.sevice_tabs>li:hover {
    color: var(--dark-orange);

}

ul.sevice_tabs li {
    /* UI/Tab */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;

    color: #212F63;
}


.service_card_wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    gap: 60px;
    margin-top: 30px;
}



/* service offer section end */



/* our product start */

.our_product_section_wrapper {
    padding: var(--whitespace);
    background: #F7F7F7;

}

.our_product_section {
    display: grid;
    grid-template-columns: 25% 70%;
    gap: 30px;
}

.our_product_section_wrapper>p {
    /* H --/Subtitle */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    /* Grey 1 */

    color: #6F758B;
}

.our_product_section_wrapper>h1 {
    /* H --/H1 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;
    /* identical to box height, or 56px */

    text-align: center;

    /* Black */

    color: #414141;
    margin-bottom: 30px;
}


.our_product_card_section_wrapper,
.our_product_tab_section {
    /* White */
    padding: 50px 40px;
    background: #FFFFFF;
    position: relative;
}

.our_product_card_section_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.our_product_card_section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    gap: 20px;

}

.our_product_tab_section>ul {
    list-style: none;
}

.our_product_tab_section>ul>li>p {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    gap: 30px;

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */

    text-transform: capitalize;

    /* Primary 01 */

    color: #212F63;
}

.our_product_tab_section>ul>li>p:hover {
    color: #F86B1F;
    cursor: pointer;
}

/* .top_categgories_card_section > div:hover  svg path */

.our_product_tab_section>ul>li>:hover svg path {
    fill: #F86B1F;
   
}

.product_categories_image_text{
    display: flex;
    gap: 10px;
}

.product_categories_image_text > img{
    height: 20px;
     width: 20px;
     filter: grayscale(1);
}
.product_categories_image_text > img span:hover{
    color: #F86B1F;
}
.product_categories_image_text > img:hover {
    filter: grayscale(0)!important;
}
/* our product end */

/* product to chnage start  */


.products_to_change_section_wrapper {
    padding: var(--whitespace);

}

.products_to_change_section_wrapper>p {
    /* H --/Subtitle */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    /* Grey 1 */

    color: #6F758B;
}

.products_to_change_section_wrapper>h1 {
    /* H --/H1 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;
    /* identical to box height, or 56px */

    text-align: center;

    /* Black */

    color: #414141;

    margin-bottom: 30px;
}


/* product to chnage end  */



/* products by ervices start */


.products_by_services_section_wrapper {
    padding: var(--whitespace);
    background: #F7F7F7;
}


.products_by_services_section_wrapper>p {
    /* H --/Subtitle */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;

    /* Grey 1 */

    color: #6F758B;
}

.products_by_services_section_wrapper>h1 {
    /* H --/H1 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;
    /* identical to box height, or 56px */

    text-align: center;


    /* Black */

    color: #414141;
    margin-bottom: 10px;
}


.products_by_services_tab {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    margin-bottom: 30px;
    justify-content: center;
}

.products_by_services_tab>li {
    /* UI/Tab */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;

    /* Primary 01 */

    color: #212F63;
}

.products_by_services_tab>li:hover {
    color: #F86B1F;
    ;
    cursor: pointer;
}

/* products by ervices end */




.car_campany_section_wrapper {
    padding: 70px 10%;
}


.car_campany_section_wrapper>p {
    /* H --/Subtitle */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    text-align: center;
    text-transform: uppercase;

    /* Grey 1 */

    color: #6F758B;
}

.car_campany_section_wrapper>h1 {
    /* H --/H1 */
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;
    /* identical to box height, or 56px */

    text-align: center;

    /* Black */

    color: #414141;
}
.car_logo_section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    gap: 40px;
    margin-top: 30px;
    align-items: center;
    text-align: center;
}
/* latest news section start */
.latest_news_section_wrapper {
    padding: var(--whitespace);
    background: var(--light-gray2);
}
.latest_news_section_wrapper .latest_news_sub_heading {
    text-align: center;
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 500;
    font-size: .8rem;
    line-height: 140%;
    text-align: center;
    color: var(--light-brinjal)
}

.latest_news_section_wrapper .latest_news_heading {
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 140%;
    text-align: center;
    color: var(--black);
    margin: 20px 0;
}

.latest_news_section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px 30px;
}
/*ARABIC-PART-START*/

.arabic_info_section_content{
    margin-right: 28px;
}
/* latest news section end */

/* media query */


/* @media screen and (max-width: 1360px) {
    .our_product_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
        gap: 20px;

    }
} */

/* @media screen and (max-width: 1000px) {
    .our_product_section {
        display: block;
    }

    .our_product_tab_section>ul {
        display: flex;
        flex-wrap: wrap;
        column-gap: 30px;

    }
}*/
@media screen and (max-width: 767px) {
    .top_categgories_section_heading {
        font-size: 22px;
        color: #414141;
    }

    .service_section_wrapper>h1 {
        font-size: 22px;

    }

    .our_product_section_wrapper>h1 {
        font-size: 22px;
    }

    .products_to_change_section_wrapper>h1 {
        font-size: 22px;
    }

    .products_by_services_section_wrapper>h1 {
        font-size: 22px;
    }

    .car_campany_section_wrapper>h1 {
        font-size: 22px;
    }

    .latest_news_section_wrapper .latest_news_heading {
        font-size: 22px;
    }

    .our_product_tab_section {
        margin-bottom: 30px;
    }

    .our_product_section {
        display: block;
    }

    .our_product_card_section_wrapper,
    .our_product_tab_section {
        padding: 5px 25px;
    }

    .products_by_services_tab {
        list-style: none;
        display: flex;
        margin-bottom: 30px;
        justify-content: flex-start;
        column-gap: 8px;
    }

    .products_by_services_tab>li {
        margin-bottom: 10px;
        line-height: 22px;
    }

    .service_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(335px, 1fr));
        gap: 60px;
        margin-top: 30px;
    }

    .car_logo_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
        gap: 40px;
        margin-top: 30px;
        align-items: center;
        text-align: center;
    }

    .our_product_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(110px, 1fr)) !important;
        gap: 5px;
    }
    .our_product_card_section img{
        width: 100%!important;
    }
    .our_product_tab_section h3{
        display: none;
    }
    /* .our_product_tab_section{
        background: transparent!important;
    }
    .our_product_card_section_wrapper, .our_product_tab_section{
    background: transparent!important;
} */
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .info_section_wrapper {
        padding: var(--whitespace);
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(230px, 2fr));
        gap: 42px;
        margin: 30px 0;
    }
    .info_section_content>p {
        font-size: 14px;
    }

    .top_categgories_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        gap: 34px;
        margin-top: 30px;
    }

    .service_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
        gap: 40px;
        margin-top: 30px;
    }

    .image_container>img {
        width: 100% !important;
    }

    .our_product_tab_section>ul>li>p {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        gap: 12px;
        font-family: 'Neue Haas Grotesk Text Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        text-transform: capitalize;
        color: #212F63;
    }
    .car_logo_section img {
        filter: grayscale(1);
        cursor: pointer;
        padding-bottom: 1rem;
        width: auto;
    }

    .our_product_card_section_wrapper,
    .our_product_tab_section {
        padding: 50px 18px;
        background: #FFFFFF;
        height: 100%;

    }

    .car_campany_section_wrapper {
        padding: 70px 2%;
    }

    .car_logo_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
        gap: 10px;
        margin-top: 30px;
        align-items: center;
        text-align: center;
    }

    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        gap: 40px;
    }

    .our_product_tab_section>ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        width: 100%;
        inline-size: 200px;
    }

    .our_product_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
        gap: 20px;
    }

    .products_by_services_tab {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
        margin-bottom: 30px;
        justify-content: space-between;
    }

    .products_by_services_tab>li {
        margin-bottom: 10px;
    }

    .products_to_change_section_wrapper>h1 {
        font-size: 30px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .top_categgories_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
        gap: 38px;
        margin-top: 30px;
    }

    .service_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
        gap: 60px;
        margin-top: 30px;
    }

    .our_product_tab_section>ul {
        list-style: none;
        inline-size: 200px;
    }

    .our_product_card_section_wrapper,
    .our_product_tab_section {
        padding: 50px 18px;
        background: #FFFFFF;
        height: 100%;
    }

    .our_product_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
        gap: 20px;
    }

    .car_logo_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
        gap: 40px;
        margin-top: 30px;
        align-items: center;
        text-align: center;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1366px) {
    .info_section_wrapper {
        padding: 20px 3rem;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
        gap: 30px;
        margin: 45px 0;
    }

    .service_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
        gap: 40px;
        margin-top: 30px;
    }

    .top_categgories_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(145px, 1fr));
    }

    .top_categgories_card_section>div {
        height: 150px;
    }

    .top_categgories_card_section p {
        font-size: 14px;
    }

    .info_section_content>p {
        font-family: var(--NeueHaasGrotesk);
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        color: var(--light-brinjal);
    }

    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
        gap: 20px;
    }

    .car_campany_section_wrapper {
        padding: var(--whitespace);
    }

    .car_logo_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        gap: 40px;
        margin-top: 30px;
        align-items: center;
        text-align: center;
    }

    .our_product_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)) !important;
        gap: 20px;
    }
}

@media screen and (min-width: 1367px) and (max-width: 1600px) {
    .info_section_wrapper {
        padding: 20px 4rem;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
        gap: 30px;
        margin: 45px 0;
    }

    .top_categgories_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
    }

    .top_categgories_card_section>div {
        height: 150px;
    }

    .top_categgories_card_section p {
        font-size: 13px;
    }

    .car_logo_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        gap: 40px;
        margin-top: 30px;
        align-items: center;
        text-align: center;
    }

    .service_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
        gap: 40px;
        margin-top: 30px;

    }

    .our_product_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(170px, 1fr)) !important;
        gap: 20px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .info_section_wrapper {
        padding: var(--whitespace);
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 30px;
        margin: 30px 0;
    }

    .top_categgories_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(205px, 1fr));
        gap: 38px;
        margin-top: 30px;
    }

    .service_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        gap: 50px;
        margin-top: 30px;
    }
    .car_logo_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(245px, 1fr));
        gap: 40px;
        margin-top: 30px;
        align-items: center;
        text-align: center;
    }

    .car_campany_section_wrapper {
        padding: 20px 10rem;
    }
}



.no_product_found_text_wrapper{
    display: block !important;
}


.no_product_found_text_wrapper > h3{
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 15px;
    text-transform: uppercase;
    text-align: center;
    color: var(--light-brinjal);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
}
.imgActive{
    filter:
    brightness(1000%) grayscale(100%) /* To change the image color to the white */
    opacity(0.1) /* To apply the color all over the image */
    drop-shadow(0 0 0 #f86b1f) /* To apply my costum color */
    drop-shadow(0 0 0 #f86b1f) /* To apply my costum color more stronger */
    drop-shadow(0 0 0 #f86b1f)
    drop-shadow(0 0 0 #f86b1f)
    drop-shadow(0 0 0 #f86b1f);
}