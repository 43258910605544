.contact_section_wrapper {
    padding: 5% 10% 0px;
}
.contact_section_wrapper input{
    height: 48px;
    border: 0.5px solid #E1E1E1;
    padding: 12px;
    margin-top: 6px;
    outline: none;
    margin-bottom: 28px;
}
.contact_detail_section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
}
#submit_button{
    text-align: center;
}
#SELECT_bOX select {
    height: 48px !important;
    margin-top: 5px;
}

.contact_detail_card {
    background: #F7F7F7;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contact_detail_card:hover p {
    color: white !important;

}

.contact_detail_card:hover span {
    color: white;
}

.contact_detail_card:hover {
    background-color: rgb(248, 107, 31) !important;
}

.contact_detail_section div:hover svg path {
    fill: #F7F7F7;
}

#span-2 textarea {
    height: 50px !important;
    margin-top: 30px;
}

.contact_detail_card>p {
    /* H --/Title SB 18 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    /* identical to box height, or 22px */

    text-align: center;

    /* Black */

    color: #414141;
    margin: 10px 0px 2px 0;
}

.contact_detail_card>span {
    /* Body --/Body Reg 16 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    /* identical to box height, or 26px */

    text-align: center;

    /* Grey 1 */

    color: #6F758B;
}



.contact_form_section_wrapper {
    margin: 8% 0px;
}

.sub_heading {
    /* H --/Subtitle */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */


    text-transform: uppercase;
    text-align: center;

    /* Grey 1 */

    color: #6F758B;

}

.contact_form_section_wrapper>h1 {
    /* H --/H1 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;
    /* identical to box height, or 56px */

    text-align: center;

    /* Black */

    color: #414141;
    margin-bottom: 20px;
}

.contact_us_desc {


    /* Body --/Body Reg 16 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;


    /* Grey 1 */

    color: #6F758B;
    max-width: 450px;
    margin: 0 auto;
    text-align: center;
}



#contact_us_from {
    margin-top: 30px;
    padding: 30px;
    background: #F7F7F7;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    row-gap: 10px;
}

#contact_us_from>button {
    width: fit-content;
    margin: 0 auto;
}

.map_section>img {
    width: 100% !important;
    padding: 0 50px !important;
}

@media screen and (max-width: 850px) {
    #contact_us_from {
        display: block;
    }

    #contact_us_from>button {
        width: 100%;
    }
   

}

@media screen and (max-width: 550px) {
    .contact_form_section_wrapper {
        margin: 50px 0 50px 0;
    }

    .map_section>img {
        padding: 0 !important;
    }
    .contact_detail_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .contact_detail_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        gap: 24px;
    }
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .contact_detail_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 24px;
    }
}
@media screen and (min-width: 1201px) and (max-width: 1366px) {
    .contact_detail_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 24px;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

