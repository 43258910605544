.gift_cards_list {
    display: flex;
    justify-content: space-between;
    margin: 5% 0px;
    align-items: center;
}

.main_wrapper {
    padding: 20px 10rem;
  
}

.gift_cards_list div {
    width: 40%;
}

.gift_cards_text h2 {
    font-size: 18px;
    color: #414141;
    font-weight: 600;
}

.gift_cards_text h3 {
    font-size: 40px;
    color: #414141;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: 15px;
}

.gift_cards_text p {
    font-size: 16px;
    color: #6F758B;
    font-weight: 400;
    line-height: 22px;
    margin-top: 25px;
    text-align: justify;
}

.benefits_section h2 {
    font-size: 16px;
    color: #6F758B;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;

}

.benefits_section h3 {
    font-size: 40px;
    color: #414141;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
    margin-top: 15px;
}

.benefits_section p {
    font-size: 16px;
    color: #6F758B;
    font-weight: 400;
    text-align: center;
    line-height: 22px;
    margin-top: 25px;

}

.Gift_cards_items {
    display: flex;
    justify-content: space-between;
    margin: 5% 0px;
}

.Gift_cards_items div {
    width: 30%;
}

.Gift_cards_items1 {
    border: 1px solid #E1E1E1;
    padding: 70px;
    height: 100%;
}

.Gift_cards_items1 {
    position: relative;
    margin-bottom: 25px;
}

.Gift_cards_items h2 {

    padding: 12px 100px;
    background: #6F758B;
    color: white;
    font-size: 22px;
    font-weight: 600;
    border: none;
    width: 100%;
    right: 5%;

}

.Gift_cards_items1 h3 {

    font-size: 30px;
    margin-top: 24%;
    color: #F86B1F;
    font-weight: 700;
    border-bottom: 1px solid#E1E1E1;
    padding-bottom: 28px;

}

.Gift_cards_items1 span {
    font-size: 24px;
    margin-top: 24%;
    color: #F86B1F;
    font-weight: 500;
    margin-left: 4px;
}

.Gift_cards_items ul li {
    color: #6F758B;
    font-size: 16px;
    font-weight: 500;
    line-height: 36px;
}

.Gift_cards_items1 button {
    background: transparent;
    border: 1px solid #212F63;
    padding: 10px 75px;
    font-size: 16px;
    text-transform: capitalize;
    margin-top: 65px;
    width: 100%;
    cursor: pointer;
    font-weight: 400;
}

.Gift_cards_items ul {
    margin-top: 20px;
}

.Gift_cards_items2 {
    border: none;
    padding: 70px;
    height: 100%;
    background: #6F758B;
}

.Gift_cards_items2 {
    position: relative;
    margin-bottom: 25px;
}

.Gift_cards_items2 h2 {

    position: absolute;
    top: 10%;
    padding: 12px 100px;
    background: #F86B1F;
    color: white;
    font-size: 22px;
    font-weight: 600;
    border: none;
    width: 100%;
    right: 5%;

}

.Gift_cards_items2 h3 {

    font-size: 30px;
    margin-top: 24%;
    color: #fff;
    font-weight: 700;
    border-bottom: 1px solid#E1E1E1;
    padding-bottom: 28px;

}

.Gift_cards_items2 span {
    font-size: 24px;
    margin-top: 24%;
    color: #fff;
    font-weight: 500;
    margin-left: 4px;
}

.Gift_cards_items2 ul li {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 36px;
}

.Gift_cards_items2 button {
    background: #212F63;
    border: 1px solid #212F63;
    padding: 10px 75px;
    font-size: 16px;
    text-transform: capitalize;
    margin-top: 65px;
    width: 100%;
    cursor: pointer;
    font-weight: 400;
    color: white;
}

/*responsive*/



@media screen and (max-width: 767px) {
    .main_wrapper {
        padding: 20px 2rem;
        margin-top: 5rem;
    }

    .gift_cards_list {
        display: block;
    }

    .gift_cards_list div {
        width: 100%;
    }

    .gift_cards_text h2 {
        font-size: 16px;
        color: #414141;
        font-weight: 600;
    }

    .gift_cards_text h3 {
        font-size: 26px;
        color: #414141;
        font-weight: 600;
        text-transform: capitalize;
        margin-top: 8px;
    }

    .gift_cards_text p {
        margin-top: 8px;
        font-size: 14px;
    }

    .gift_cards_list img {
        width: 100%;
        margin-top: 20px;
    }

    .benefits_section h3 {
        font-size: 26px;
        color: #414141;
        font-weight: 600;
        text-transform: capitalize;
        text-align: center;
        margin-top: 3px;
    }

    .benefits_section p {
        margin-top: 8px;
        font-size: 14px;
    }

    .Gift_cards_items {
        display: block;
        margin-top: 15%;
    }

    .Gift_cards_items div {
        width: 100%;
    }

    .Gift_cards_items h2 {
        position: absolute;
        top: 2%;
        padding: 10px 70px;
        color: white;
        font-size: 20px;
        font-weight: 600;
        border: none;
        width: 100%;
        right: 5%;
    
    }

    .Gift_cards_items1 {
        border: 1px solid #E1E1E1;
        padding: 34px;
        height: 100%;
    }
    .Gift_cards_items ul li{
        font-size: 14px;
        line-height: 26px;
    }
    .Gift_cards_items1 h3 {
        font-size: 30px;
        margin-top: 16%;
        color: #F86B1F;
        font-weight: 700;
        border-bottom: 1px solid#E1E1E1;
        padding-bottom: 16px;
    }

    .Gift_cards_items1 button {
        background: transparent;
        border: 1px solid #212F63;
        padding: 14px 40px;
        font-size: 14px;
        text-transform: capitalize;
        margin-top: 40px;
        width: 100%;
        cursor: pointer;
        font-weight: 400;
    }

    .Gift_cards_items2 button {
        background: #212F63;
        border: 1px solid #212F63;
        padding: 14px 40px;
        font-size: 14px;
        text-transform: capitalize;
        margin-top: 40px;
        width: 100%;
        cursor: pointer;
        font-weight: 400;
        color: white;
    }

    .Gift_cards_items2 span {
        font-size: 24px;
        margin-top: 24%;
        color: #fff;
        font-weight: 500;
        margin-left: 4px;
    }

    .Gift_cards_items2 ul li {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
    }

    .Gift_cards_items2 h3 {

        font-size: 30px;
        margin-top: 24%;
        color: #fff;
        font-weight: 700;
        border-bottom: 1px solid#E1E1E1;
        padding-bottom: 28px;

    }

    .Gift_cards_items2 {
        border: none;
        padding: 45px;
        height: 100%;
        background: #6F758B;
    }

    .Gift_cards_items2 h3 {
        font-size: 30px;
        margin-top: 16%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .main_wrapper {
        padding: 20px 3rem;
    }

    .gift_cards_list {
        display: block;
    }

    .gift_cards_list div {
        width: 100%;
    }

    .gift_cards_text h2 {
        font-size: 16px;
        color: #414141;
        font-weight: 600;
    }

    .gift_cards_text h3 {
        font-size: 26px;
        color: #414141;
        font-weight: 600;
        text-transform: capitalize;
        margin-top: 8px;
    }

    .gift_cards_text p {
        margin-top: 8px;
    }

    .gift_cards_list img {
        width: 100%;
        margin-top: 20px;
    }

    .benefits_section h3 {
        font-size: 26px;
        color: #414141;
        font-weight: 600;
        text-transform: capitalize;
        text-align: center;
        margin-top: 3px;
    }

    .benefits_section p {
        margin-top: 8px;
    }

    .Gift_cards_items {
        display: block;
        margin-top: 15%;
    }

    .Gift_cards_items div {
        width: 100%;
    }

    .Gift_cards_items h2 {
        position: absolute;
        top: 2%;
    }

    .Gift_cards_items1 {
        border: 1px solid #E1E1E1;
        padding: 45px;
        height: 100%;
    }

    .Gift_cards_items1 h3 {
        font-size: 30px;
        margin-top: 10%;
        color: #F86B1F;
        font-weight: 700;
        border-bottom: 1px solid#E1E1E1;
        padding-bottom: 28px;
    }

    .Gift_cards_items1 button {
        background: transparent;
        border: 1px solid #212F63;
        padding: 14px 40px;
        font-size: 14px;
        text-transform: capitalize;
        margin-top: 40px;
        width: 100%;
        cursor: pointer;
        font-weight: 400;
    }

    .Gift_cards_items2 button {
        background: #212F63;
        border: 1px solid #212F63;
        padding: 14px 40px;
        font-size: 14px;
        text-transform: capitalize;
        margin-top: 40px;
        width: 100%;
        cursor: pointer;
        font-weight: 400;
        color: white;
    }

    .Gift_cards_items2 span {
        font-size: 24px;
        margin-top: 24%;
        color: #fff;
        font-weight: 500;
        margin-left: 4px;
    }

    .Gift_cards_items2 ul li {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        line-height: 36px;
    }

    .Gift_cards_items2 h3 {

        font-size: 30px;
        margin-top: 24%;
        color: #fff;
        font-weight: 700;
        border-bottom: 1px solid#E1E1E1;
        padding-bottom: 28px;

    }

    .Gift_cards_items2 {
        border: none;
        padding: 45px;
        height: 100%;
        background: #6F758B;
    }

    .Gift_cards_items2 h3 {
        font-size: 30px;
        margin-top: 10%;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .main_wrapper {
        padding: 20px 4rem;
    }

    .gift_cards_list img {
        width: 100%;
    }

    .Gift_cards_items1 {
        border: 1px solid #E1E1E1;
        padding: 26px;
        height: 100%;
    }

    .Gift_cards_items1 h3 {
        font-size: 30px;
        margin-top:30%;
        color: #F86B1F;
        font-weight: 700;
        border-bottom: 1px solid#E1E1E1;
        padding-bottom: 12px;
    }

    .Gift_cards_items h2 {
        position: absolute;
        top: 5%;
    }

    .Gift_cards_items2 h3 {
        font-size: 30px;
        margin-top: 30%;
        color: white;
        font-weight: 700;
        border-bottom: 1px solid#E1E1E1;
        padding-bottom: 12px;
    }

    .Gift_cards_items h2 {
        position: absolute;
        top: 5%;
    }

    .Gift_cards_items2 {
        border: none;
        padding: 26px;
        height: 100%;
        background: #6F758B;
    }
    .gift_cards_text h3 {
        font-size: 36px;
    }
    .Gift_cards_items1 button {
        background: transparent;
        border: 1px solid #212F63;
        padding: 10px 50px;
        font-size: 13px;
        margin-top: 12px;
    
    }
    .Gift_cards_items2 button{
        font-size: 13px;
        padding: 10px 50px;
        margin-top: 12px;
    }
    .Gift_cards_items ul li{
        font-size: 13px;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1366px) {
    .main_wrapper {
        padding: 20px 4rem;
    }

    .gift_cards_list img {
        width: 100%;
    }

    .Gift_cards_items1 {
        border: 1px solid #E1E1E1;
        padding: 26px;
        height: 100%;
    }

    .Gift_cards_items1 h3 {
        font-size: 30px;
        margin-top: 30%;
        color: #F86B1F;
        font-weight: 700;
        border-bottom: 1px solid#E1E1E1;
        padding-bottom: 14px;
    }

    .Gift_cards_items h2 {
        position: absolute;
        top: 5%;
    }

    .Gift_cards_items2 h3 {
        font-size: 30px;
        margin-top: 30%;
        color: white;
        font-weight: 700;
        border-bottom: 1px solid#E1E1E1;
        padding-bottom: 14px;
    }

    .Gift_cards_items h2 {
        position: absolute;
        top: 5%;
    }

    .Gift_cards_items2 {
        border: none;
        padding: 26px;
        height: 100%;
        background: #6F758B;
    }
    .Gift_cards_items1 button{
        margin-top: 16px;
    }
    .Gift_cards_items2 button{
        margin-top: 16px;
    }
}

@media screen and (min-width: 1367px) and (max-width: 1600px) {
    .main_wrapper {
        padding: 20px 6rem;
    }

    .gift_cards_list img {
        width: 100%;
    }

    .Gift_cards_items1 {
        border: 1px solid #E1E1E1;
        padding: 40px;
        height: 100%;
    }

    .Gift_cards_items1 h3 {
        font-size: 30px;
        margin-top: 30%;
        color: #F86B1F;
        font-weight: 700;
        border-bottom: 1px solid#E1E1E1;
        padding-bottom: 14px;
    }

    .Gift_cards_items h2 {
        position: absolute;
        top: 5%;
    }

    .Gift_cards_items2 h3 {
        font-size: 30px;
        margin-top: 30%;
        color: white;
        font-weight: 700;
        border-bottom: 1px solid#E1E1E1;
        padding-bottom: 14px;
    }

    .Gift_cards_items h2 {
        position: absolute;
        top: 5%;
    }

    .Gift_cards_items2 {
        border: none;
        padding: 40px;
        height: 100%;
        background: #6F758B;
    }
    .Gift_cards_items1 button{
        margin-top: 16px;
    }
    .Gift_cards_items2 button{
        margin-top: 16px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .main_wrapper {
        padding: 20px 7rem;
    }

    .gift_cards_list img {
        width: 100%;
    }

    .Gift_cards_items1 {
        border: 1px solid #E1E1E1;
        padding: 30px;
        height: 100%;
    }

    .Gift_cards_items1 h3 {
        font-size: 30px;
        margin-top: 35%;
        color: #F86B1F;
        font-weight: 700;
        border-bottom: 1px solid#E1E1E1;
        padding-bottom: 20px;
    }

    .Gift_cards_items h2 {
        position: absolute;
        top: 5%;
    }

    .Gift_cards_items2 h3 {
        font-size: 30px;
        margin-top: 35%;
        color: white;
        font-weight: 700;
        border-bottom: 1px solid#E1E1E1;
        padding-bottom: 20px;
    }

    .Gift_cards_items h2 {
        position: absolute;
        top: 5%;
    }

    .Gift_cards_items2 {
        border: none;
        padding: 30px;
        height: 100%;
        background: #6F758B;
    }
    .Gift_cards_items1 button{
        margin-top: 16px;
    }
    .Gift_cards_items2 button{
        margin-top: 16px;
    }
}

@media screen and (min-width: 1801px) and (max-width: 1920px) {
    .main_wrapper {
        padding: 20px 8rem;
    }

    /* .gift_cards_list img{
        width: 100%;
    } */
    .Gift_cards_items1 {
        border: 1px solid #E1E1E1;
        padding: 38px;
        height: 100%;
    }

    .Gift_cards_items1 h3 {
        font-size: 30px;
        margin-top: 24%;
        color: #F86B1F;
        font-weight: 700;
        border-bottom: 1px solid#E1E1E1;
        padding-bottom: 28px;
    }

    .Gift_cards_items h2 {
        position: absolute;
        top: 5%;
    }

    .Gift_cards_items2 h3 {
        font-size: 30px;
        margin-top: 24%;
        color: #fff;
        font-weight: 700;
        border-bottom: 1px solid#E1E1E1;
        padding-bottom: 28px;
    }

    .Gift_cards_items h2 {
        position: absolute;
        top: 5%;
    }

    .Gift_cards_items2 {
        border: none;
        padding: 38px;
        height: 100%;
        background: #6F758B;
    }
}