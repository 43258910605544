.main_wrapper {}
.wholesale_wrapper_image img{
    width: 100%;
    height: 252px;
}
.wholesale_wrapper_image{
    padding: var(--whitespace);
    position: relative;
}
.wholesale_wrapper_image h2{
    position: absolute;
    top: 20%;
    color: white;
    text-align: center;
    left: 0;
    right: 0;
    font-size: 40px;
    font-weight: 600;
    width: 28%;
    margin: 0 auto;

}
.wholesale_wrapper_image button{
    position: absolute;
    top: 65%;
    left: 0;
    right: 0;
    width: 11%;
    margin: 0 auto;
    padding: 7px;
    font-size: 14px;
    border: 1px solid #fff;
    background: transparent;
    color: white;
    text-transform: capitalize;
}

.description_section {
    padding: 40px 20%;
}

.description_section>h1 {
    /* H --/H2 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;
    /* identical to box height, or 45px */

    font-feature-settings: 'pnum' on, 'lnum' on;

    /* Black */

    color: #414141;
}

.description_section>p {
    /* Body --/Body Reg 16 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */


    /* Grey 1 */

    color: #6F758B;
    margin-top: 20px;
}


.contact_form_section_wrapper {
    margin: 40px 10%;
}

.sub_heading {
    /* H --/Subtitle */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */


    text-transform: uppercase;
    text-align: center;

    /* Grey 1 */

    color: #6F758B;

}

.contact_form_section_wrapper>h1 {
    /* H --/H1 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;
    /* identical to box height, or 56px */

    text-align: center;

    /* Black */

    color: #414141;
    margin-bottom: 20px;
}

.contact_us_desc {


    /* Body --/Body Reg 16 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;


    /* Grey 1 */

    color: #6F758B;
    max-width: 450px;
    margin: 0 auto;
    text-align: center;
}



#contact_us_from {
    margin-top: 30px;
    padding: 30px;
    background: #F7F7F7;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    row-gap: 10px;
}

#contact_us_from>button {
    width: fit-content;
    margin: 0 auto;
}

/* card logo start */

.car_campany_section_wrapper {
    padding: 70px 10%;
}


.car_campany_section_wrapper>p {
    /* H --/Subtitle */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    text-align: center;
    text-transform: uppercase;

    /* Grey 1 */

    color: #6F758B;
}

.car_campany_section_wrapper>h1 {
    /* H --/H1 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 140%;
    /* identical to box height, or 56px */

    text-align: center;

    /* Black */

    color: #414141;
}


.car_logo_section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 60px;
    margin-top: 30px;
}

.car_logo_section>div:hover img {
    transform: scale(1);
    filter: grayscale(0);
    transition: 0.5s ease-in-out;
    transform: scale(1.2);
}

.car_logo_section img {
    filter: grayscale(1);
    cursor: pointer;
    padding-bottom: 1rem;
}

/* card logo end */

/* retailers section start */

.retailers_details_section_wrapper {
    padding: var(--whitespace);
    background: #F7F7F7;
}

.retailers_details_section_wrapper>p {
    /* H --/Subtitle */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    /* Primary 01 */

    color: #212F63;
}

.retailers_details_section_wrapper>h1 {
    /* H --/H2 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;
    /* or 45px */
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;

    /* Black */

    color: #414141;
    max-width: 550px;
    margin: 10px auto;
}

.retailers_details_section_wrapper>h4 {
    /* Body --/Body Reg 16 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */

    text-align: center;

    /* Grey 1 */

    color: #6F758B;
    max-width: 400px;
    margin: 10px auto;
}


.retailers_card_section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 40px;
    margin-top: 30px;
    padding: 0 10%;
}

.retailers_card {
    /* White */

    background: #FFFFFF;
    padding: 30px;
}

.retailers_card>h4 {
    /* H --/Title SB 16 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */


    /* Black */

    color: #414141;
}

.retailers_card>p {
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* or 20px */


    /* Grey 1 */

    color: #6F758B;
}

/* retailers section end */



/* service offer section start */
.service_section_wrapper {
    padding: var(--whitespace);
}

.service_section_wrapper>p {

    /* H --/Subtitle */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    /* Primary 01 */

    color: #212F63;

}

.service_section_wrapper>h1 {
    /* H --/H2 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;
    /* identical to box height, or 45px */

    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;

    /* Black */

    color: #414141;
}

ul.sevice_tabs {
    list-style: none;
    margin: 0;
    padding: 5px;
    overflow: auto;
    text-align: center;
    display: flex;
    justify-content: center;
}

ul.sevice_tabs li {
    font-weight: bold;
    margin-right: 2px;
    padding: 8px 10px;
    border-radius: 5px 5px 5px 5px;
    /*border: 1px solid #d5d5de;
  border-bottom: none;*/
    cursor: pointer;
}

ul.sevice_tabs>li:hover {
    color: var(--dark-orange);

}

ul.sevice_tabs li {
    /* UI/Tab */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;

    color: #212F63;
}
.service_card_wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    gap: 40px;
    margin-top: 30px;
}
/* top categgories section wrapper start */
.top_categgories_section_wrapper {
    padding: var(--whitespace);
}
.top_categgories_section_sub_heading {
    /* H --/Subtitle */
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    text-align: center;
    text-transform: uppercase;
    /* Primary 02 */
    color: #F86B1F;
}
.top_categgories_section_heading {
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;
    /* identical to box height, or 45px */
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    /* Black */
    color: #414141;
}
.top_categgories_card_section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 40px;
    margin-top: 30px;
}
.top_categgories_card_section>div {
    width: auto;
    background: var(--light-gray2);
    padding: 10% 10%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    align-items: center;
}

.top_categgories_card_section>div:hover {
    background-color: var(--dark-orange);
    color: var(--white);
    fill: var(--white);
    stroke: var(--white);
}


.top_categgories_card_section>div:hover svg path {
    fill: white !important;
    transition: all ease 0.3s;
}
/* top categgories section wrapper end */
/* service offer section end */
@media screen and (max-width: 850px) {

    .description_section {
        padding: 40px 10%;
    }

    #contact_us_from {
        display: block;
    }

    #contact_us_from>button {
        width: 100%;
    }
}
@media screen and (max-width: 767px) {
    .wholesale_wrapper_image img {
        width: 100%;
        height: 200px;
    
}
.wholesale_wrapper_image h2{
    font-size: 20px;
    width: 64%;
}
.wholesale_wrapper_image button {
    position: absolute;
    top: 56%;
    left: 0;
    right: 0;
    width: 44%;
    margin: 0 auto;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #fff;
    background: transparent;
    color: white;
    text-transform: capitalize;
}
.main_wrapper{
    margin-top: 5rem;
}
}
@media screen and (max-width: 550px) {
    .description_section {
        padding: 40px 5%;
    }

    .contact_form_section_wrapper {
        margin: 5%;
    }

    .map_section>img {
        padding: 0 !important;
    }
    .wholesale_wrapper_image img {
        width: 100%;
        height: 170px;
    
}
.wholesale_wrapper_image h2{
    font-size: 20px;
    width: 64%;
}
.wholesale_wrapper_image button {
    position: absolute;
    top: 54%;
    left: 0;
    right: 0;
    width: 44%;
    margin: 0 auto;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #fff;
    background: transparent;
    color: white;
    text-transform: capitalize;
}
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .description_section {
        padding: 40px 6%;
    }

    .car_campany_section_wrapper {
        padding: 70px 4%;
    }

    .car_logo_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        gap: 38px;
        margin-top: 48px;
        text-align: center;
    }

    .retailers_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
        gap: 40px;
        margin-top: 30px;
        padding: 0 0%;
    }

    .service_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 40px;
        margin-top: 30px;
    }

    .top_categgories_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(148px, 1fr));
        gap: 40px;
        margin-top: 30px;
    }
    .wholesale_wrapper_image button {
        position: absolute;
        top: 54%;
        left: 0;
        right: 0;
        width: 22%;
        margin: 0 auto;
        padding: 8px;
        font-size: 14px;
        border: 1px solid #fff;
        background: transparent;
        color: white;
        text-transform: capitalize;
    
    }
    .wholesale_wrapper_image h2{
        font-size: 30px;
        width: 50%;
    }
    .wholesale_wrapper_image img {
        width: 100%;
        height: 248px;
    
}
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .description_section {
        padding: 40px 6%;
    }
    .car_campany_section_wrapper {
        padding: 70px 8%;
    }

    .car_logo_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
        gap: 38px;
        margin-top: 48px;
        text-align: center;
    }
    .retailers_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(215px, 1fr));
        gap: 40px;
        margin-top: 30px;
        padding: 0 10%;
    }
    .service_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 40px;
        margin-top: 30px;
    }

    .top_categgories_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(148px, 1fr));
        gap: 40px;
        margin-top: 30px;
    }
    .wholesale_wrapper_image h2{
        font-size: 26px;
    }
    .wholesale_wrapper_image button{
        top: 60%;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1366px) {
    .description_section {
        padding: 40px 8%;
    }

    .car_campany_section_wrapper {
        padding: 70px 8%;
    }

    .car_logo_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        gap: 38px;
        margin-top: 48px;
        text-align: center;
    }

    .service_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 40px;
        margin-top: 30px;
    }

    .top_categgories_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(148px, 1fr));
        gap: 40px;
        margin-top: 30px;
    }
    .wholesale_wrapper_image h2{
        font-size: 32px;
        width: 32%;
    }
    .wholesale_wrapper_image button{
        top: 60%;
    }
}

@media screen and (min-width: 1367px) and (max-width: 1600px) {
    .description_section {
        padding: 40px 10%;
    }

    .car_logo_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(182px, 1fr));
        gap: 38px;
        margin-top: 48px;
        text-align: center;
    }

    .service_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
        gap: 40px;
        margin-top: 30px;
    }

    .top_categgories_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(172px, 1fr));
        gap: 40px;
        margin-top: 30px;
    }
    .wholesale_wrapper_image h2{
        font-size: 36px;
    }
    .wholesale_wrapper_image button{
        top: 60%;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .description_section {
        padding: 40px 10%;
    }

    .car_logo_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
        gap: 38px;
        margin-top: 48px;
        text-align: center;
    }

    .service_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
        gap: 40px;
        margin-top: 30px;
    }

    .top_categgories_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 40px;
        margin-top: 30px;
    }
    .wholesale_wrapper_image h2{
        font-size: 34px;
    }
    .wholesale_wrapper_image button{
        top: 60%;
    }
}

@media screen and (min-width: 1801px) and (max-width: 1920px) {
    .description_section {
        padding: 40px 15%;
    }

    .car_logo_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(228px, 1fr));
        gap: 38px;
        margin-top: 48px;
        text-align: center;
    }

    .service_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(460px, 1fr));
        gap: 40px;
        margin-top: 30px;
    }

    .top_categgories_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
        gap: 40px;
        margin-top: 30px;
    }
}

@media screen and (min-width: 1921px) and (max-width: 2560px) {
    .car_logo_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
        gap: 38px;
        margin-top: 48px;
        text-align: center;
    }

    .service_card_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(460px, 1fr));
        gap: 40px;
        margin-top: 30px;
    }

    .top_categgories_card_section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
        gap: 40px;
        margin-top: 30px;
    }
}