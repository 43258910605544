.text {
    color: var(--green);
}
.product_details_img{
    width: 48%;
}
.product_details_sec {
    display: flex;
    justify-content: space-between;
    padding: 30px 10rem;
    margin-top: 5%;
}
.product_details_img{
    width: 48%;
}
.product_details_right_bar {
    width: 60%;
}

.product_details_right_bar ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    width: 70%;
}

.product_details_right_bar ul li {
    font-size: 16px;
    color: #212F63;
    font-weight: 500;
}

.product_details_right_bar h1 {
    font-size: 3rem;
    font-weight: 600;
    color: #414141;
    margin-top: 60px
}
.product_details_right_bar p {
    font-size: 16px;
    color: #6F758B;
    font-weight: 400;
    width: 80%;
    margin-top: 10px;
    margin-bottom: 30px;
}

.product_details_right_bar h3 {
    color: #F86B1F;
    font-size: 40px;
    font-weight: 700;
}

.product_details_right_bar span {
    font-size: 34px;
    font-weight: 600;
}

.active_li {
    color: #6F758B !important;
}

.product_details_right_bar i {
    padding-left: 1px;
    color: #6F758B;
    font-size: 18px;
}

.list_bar {
    display: flex;
    justify-content: space-between;
    width: 42%;
    margin-top: 2%;
}

.list_bar h4 {
    font-size: 20px;
    font-weight: 500;
    color: #414141;
    line-height: 42px;
    margin: 0;
}

.list_bar p {
    font-size: 20px;
    font-weight: 400;
    color: #6F758B;
    line-height: 42px;
    margin: 0;
}

.Button_box {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin-top: 5%;
}

.increamnet_decreament_btn {
    background: transparent;
    border: 1px solid #E1E1E1;
    width: 235px;
    display: flex;
    justify-content: space-evenly;
    height: 50px;
}

.increamnet_decreament_btn span {
    font-size: 22px;
    font-weight: 400;
    margin-top: 10px;
    color: #212F63;
}

.add_cart {
    color: white;
    background: #212F63 !important;
    width: 200px;
    font-size: 14px !important;
    font-weight: 500;
    border-radius: 0px !important;
}
.add_cart_unlogin {
    color: white;
    background: #212F63 !important;
    width: 150px !important;
    font-size: 14px !important;
    font-weight: 500;
    border-radius: 0px !important;
}

.Add_list_btn {
    border: 1px solid #212F63 !important;
    width: 200px;
    font-size: 14px !important;
    font-weight: 500;
    color: #212F63 !important;
    border-radius: 0px !important;
}

.logo_img {
    margin-top: 5%;
    width: 100%;
}

.product_details_img img {
    width: 100%;
}

/*tabs-list*/

.tabs_box_lists {
    margin-top: 4%;
}

.tabs_box_lists ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    width: 50%;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 10px;
}

.tabs_box_lists .active_li_tab {
    text-decoration: none;
    color: #6F758B !important;
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid #F86B1F;
    padding-bottom: 10px
}

.tabs_box_lists a {
    color: #212F63;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    padding-bottom: 10px
}

.items_list p {
    font-size: 16px;
    color: #6F758B;
    font-weight: 400;
    width: 60%;
    text-align: justify;
    margin-top: 25px;
}

.heading2 h2 {
    text-align: center;
    color: #414141;
    font-size: 32px;
    font-weight: 600;
}

.product_details_wrapper {
    padding: 20px 10rem;
    margin-top: 3%;
}

.subcategories_product_card_section {
    grid-template-columns: repeat(3, 1fr);
}

.ProductCardsectt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-template-rows: repeat(1, minmax(300px, 1fr));
    gap: 30px;
    margin-top: 2%;

}

.increamnet_decreament_btn {
    width: 48%;
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    padding-bottom: 8px;
}

.add_cart {
    margin-bottom: 20px !important;
    border: none;
    padding: 12px;
}
.add_cart_unlogin {
    margin-bottom: 20px !important;
    border: none;
    padding: 12px;
}

.Button_box a {
    width: 30%;
}
.product_details_imges_list{
    display: flex;
    column-gap: 20px;
    margin-top: 25px;
    position: relative;
    left: -90px
}
.product_details_imges_list img{
    width: 90px;
    height: 90px;
}
/*responsive-design*/

@media screen and (max-width: 767px) {

    /*product-detail-section*/
    .product_details_sec {
        display: block;
        padding: 30px 2rem;
    }

    /* .product_details_sec img {
        width: 100%;
    } */

    .product_details_right_bar {
        width: 100%;
    }

    .product_details_right_bar ul {
        margin-top: 45px;
        flex-wrap: wrap;
    }

    .product_details_right_bar ul li {
        font-size: 16px;
        margin-bottom: 16px;
    }

    .product_details_right_bar h1 {
        font-size: 22px;
        margin-top: 28px;
    }

    .product_details_right_bar p {
        font-size: 14px;
        width: 100%;
        margin-top: 15px;
        text-align: justify;
        line-height: 24px;
    }

    .product_details_right_bar h3 {
        font-size: 22px;
    }

    .product_details_right_bar span {
        font-size: 22px;
    }

    .list_bar {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 5%;
    }

    .list_bar h4 {
        font-size: 14px;
        line-height: 30px;
        margin: 0;
    }

    .list_bar p {
        font-size: 14px;
        line-height: 30px;
        margin: 0;
    }

    .Button_box {
        display: block;
        width: 100%;
        margin-top: 5%;
    }

    .increamnet_decreament_btn {
        background: transparent;
        border: 1px solid #E1E1E1;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        height: 50px;
        margin-bottom: 18px;
    }

    .logo_img {
        margin-top: 12%;
        width: 100%;
    }

    .tabs_box_lists {
        margin-top: 8%;
    }

    .add_cart {
        width: 100%;
        font-size: 14px !important;
        margin-bottom: 20px !important;
        height: 50px;
    }
    .add_cart_unlogin {
        width: 100%;
        font-size: 14px !important;
        margin-bottom: 20px !important;
        height: 50px;
    }

    .Add_list_btn {
        width: 100%;
        font-size: 14px !important;
        height: 48px;
    }
.heading2 h2{
    font-size: 22px;
    margin-bottom: 22px;
}
    .tabs_box_lists ul {
        width: 100%;
    }

    .tabs_box_lists .active_li_tab {
        padding-bottom: 26px;
        font-size: 13px;
    }

    .product_details_wrapper {
        padding: 0px 2rem;
        margin-top: 3%;
    }

    .logo_img {
        display: flex;
        column-gap: 14px;

    }

    .tabs_box_lists a {
        font-size: 13px;
    }
    .product_details_imges_list {
        display: flex;
        margin-top: 25px;
        flex-wrap: wrap;
        gap: 20px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

    /*product-detail-section*/
    .product_details_sec {
        display: block;
        justify-content: space-between;
        padding: 30px 3rem;
        margin-top: 5%;
    }

    .product_details_img {
        width: 100%;
    }

    .product_details_right_bar {
        width: 100%;
    }

    .product_details_right_bar ul {
        flex-wrap: wrap;
    }

    .product_details_right_bar ul li {
        font-size: 15px;
        margin-bottom: 20px;
    }

    .product_details_right_bar h1 {
        font-size: 38px;
        margin-top: 28px;
    }

    .product_details_right_bar p {
        font-size: 15px;
        width: 100%;
        margin-top: 13px;
        text-align: justify;
    }

    .list_bar {
        margin-top: 5%;
        width: 48%;
    }

    .Button_box {
        width: 100%;
        border-top: 1px solid #E1E1E1;
        padding-top: 28px;

    }

    .increamnet_decreament_btn {
        width: 30%;
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        padding-bottom: 8px;
        height: 48px;


    }

    .add_cart {

        margin-bottom: 20px !important;
        border: none;
        padding: 12px;
        height: 48px;
        width: 100%;

    }
    .add_cart_unlogin {

        margin-bottom: 20px !important;
        border: none;
        padding: 12px;
        height: 48px;
        width: 25% !important;

    }

    .product_details_right_bar h3 {
        font-size: 32px;
    }

    .product_details_right_bar span {
        font-size: 28px;
        font-weight: 600;
    }

    .list_bar p {
        line-height: 20px;

    }

    .list_bar h4 {
        font-size: 16px;
        line-height: 32px;
    }

    .Add_list_btn {
        width: 30%;
        height: 48px;
    }

    .tabs_box_lists ul {

        width: 100%;
        margin-top: 4rem;
    }

    /* .product_details_sec img {
        width: 90%;
    } */

    .items_list p {
        width: 100%;
    }

    .product_details_wrapper {
        padding: 20px 3rem;
        margin-top: 0%;
    }

    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
        grid-template-rows: repeat(1, minmax(300px, 1fr));
        gap: 30px;
        margin-top: 2%;
    }

    .logo_img {
        display: flex;
        column-gap: 28px;

    }

    .product_details_img {
        width: 100%;
        height: 100%;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .product_details_sec {
        display: flex;
        justify-content: space-between;
        padding: 30px 4rem;
        margin-top: 5%;
    }

    .product_details_right_bar {
        width: 60%;
    }

    .product_details_right_bar ul {
        flex-wrap: wrap;
    }

    .product_details_right_bar ul li {
        font-size: 15px;
        margin-bottom: 20px;
    }

    .product_details_right_bar h1 {
        font-size: 38px;
        margin-top: 28px;
    }

    .product_details_right_bar p {
        font-size: 15px;
        width: 100%;
        margin-top: 13px;
        text-align: justify;
    }

    .list_bar {
        margin-top: 5%;
        width: 58%;
    }

    .Button_box {
        width: 100%;
        border-top: 1px solid #E1E1E1;
        padding-top: 28px;
    }

    .increamnet_decreament_btn {
        width: 30%;
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        padding-bottom: 8px;
        height: 48px;


    }

    .add_cart {

        margin-bottom: 20px !important;
        border: none;
        padding: 12px;
        height: 48px;
        width: 100%;



    }
    .add_cart_unlogin {

        margin-bottom: 20px !important;
        border: none;
        padding: 12px;
        height: 48px;
        width: 40%;



    }

    .product_details_right_bar h3 {
        font-size: 32px;
    }

    .product_details_right_bar span {
        font-size: 28px;
        font-weight: 600;
    }

    .list_bar p {
        line-height: 20px;

    }

    .list_bar h4 {
        font-size: 16px;
        line-height: 32px;
    }

    .Add_list_btn {
        width: 30%;
        height: 48px;

    }

    .tabs_box_lists ul {

        width: 100%;
        margin-top: 6rem;
    }

    /* .product_details_sec img {
        width: 80%;
    } */

    .items_list p {
        width: 100%;
    }

    .product_details_wrapper {
        padding: 20px 3rem;
        margin-top: 0%;
    }

    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
        grid-template-rows: repeat(1, minmax(300px, 1fr));
        gap: 30px;
        margin-top: 2%;
    }

    .logo_img {
        display: flex;
        column-gap: 34px;

    }

    .product_details_img {
        width: 35%;
    }

}

@media screen and (min-width: 1201px) and (max-width: 1366px) {
    .product_details_sec {
        display: flex;
        justify-content: space-between;
        padding: 30px 8rem;
        margin-top: 5%;
    }

    .product_details_right_bar {
        width: 58%;
    }

    .product_details_right_bar ul {
        flex-wrap: wrap;
    }

    .product_details_right_bar ul li {
        font-size: 15px;
        margin-bottom: 20px;
    }

    .product_details_right_bar h1 {
        font-size: 38px;
        margin-top: 28px;
    }

    .product_details_right_bar p {
        font-size: 15px;
        width: 100%;
        margin-top: 13px;
        text-align: justify;
    }

    .list_bar {
        margin-top: 5%;
        width: 58%;
    }

    .Button_box {
        width: 100%;
        border-top: 1px solid #E1E1E1;
        padding-top: 28px;
    }

    .increamnet_decreament_btn {
        width: 30%;
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        padding-bottom: 8px;
        height: 48px;


    }

    .add_cart {

        margin-bottom: 20px !important;
        border: none;
        padding: 12px;
        height: 48px;

    }
    .add_cart_unlogin {

        margin-bottom: 20px !important;
        border: none;
        padding: 12px;
        height: 48px;
        width: 40%;


    }

    .product_details_right_bar h3 {
        font-size: 32px;
    }

    .product_details_right_bar span {
        font-size: 28px;
        font-weight: 600;
    }

    .list_bar p {
        line-height: 20px;

    }

    .list_bar h4 {
        font-size: 16px;
        line-height: 32px;
    }

    .Add_list_btn {
        width: 30%;
        height: 48px;

    }

    .tabs_box_lists ul {

        width: 100%;
        margin-top: 6rem;
    }

    /* .product_details_sec img {
        width: 80%;
    } */

    .items_list p {
        width: 100%;
    }

    .product_details_wrapper {
        padding: 20px 3rem;
        margin-top: 0%;
    }

    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
        grid-template-rows: repeat(1, minmax(300px, 1fr));
        gap: 30px;
        margin-top: 2%;
    }

    .logo_img {
        display: flex;
        column-gap: 34px;

    }

    .product_details_img {
        width: 36%;
    }

    .logo_img {
        display: flex;
        column-gap: 34px;

    }
}

@media screen and (min-width: 1367px) and (max-width: 1600px) {
    .product_details_sec {
        display: flex;
        justify-content: space-between;
        padding: 30px 8rem;
        margin-top: 5%;
    }

    .product_details_right_bar {
        width: 48%;
    }

    .product_details_right_bar ul {
        flex-wrap: wrap;
    }

    .product_details_right_bar ul li {
        font-size: 15px;
        margin-bottom: 20px;
    }

    .product_details_right_bar h1 {
        font-size: 38px;
        margin-top: 28px;
    }

    .product_details_right_bar p {
        font-size: 15px;
        width: 100%;
        margin-top: 13px;
        text-align: justify;
    }

    .list_bar {
        margin-top: 5%;
        width: 60%;
    }

    .Button_box {
        width: 100%;
        border-top: 1px solid #E1E1E1;
        padding-top: 28px;

    }

    .increamnet_decreament_btn {
        width: 30%;
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        padding-bottom: 8px;
        height: 48px;


    }

    .add_cart {
        margin-bottom: 20px !important;
        border: none;
        padding: 12px;
        height: 48px;
        width: 100%;
    }
    .add_cart_unlogin {
        margin-bottom: 20px !important;
        border: none;
        padding: 12px;
        height: 48px;
        width: 40%;
    }

    .product_details_right_bar h3 {
        font-size: 32px;
    }

    .product_details_right_bar span {
        font-size: 28px;
        font-weight: 600;
    }

    .list_bar p {
        line-height: 20px;

    }

    .list_bar h4 {
        font-size: 16px;
        line-height: 32px;
    }

    .Add_list_btn {
        width: 40%;
        height: 48px;

    }

    .tabs_box_lists ul {

        width: 100%;
        margin-top: 6rem;
    }

    /* 
    .product_details_sec img {
        width: 100%;
    } */

    .logo_img {
        display: flex;
        column-gap: 34px;

    }

    .items_list p {
        width: 100%;
    }

    .product_details_wrapper {
        padding: 20px 5rem;
        margin-top: 1%;
    }

    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
        grid-template-rows: repeat(1, minmax(300px, 1fr));
        gap: 10px;
        margin-top: 2%;
    }

    .logo_img {
        display: flex;
        column-gap: 34px;

    }

}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .product_details_sec {
        display: flex;
        justify-content: space-between;
        padding: 30px 8rem;
        margin-top: 5%;
    }

    .product_details_right_bar {
        width: 48%;
    }

    .product_details_right_bar ul {
        flex-wrap: wrap;
    }

    .product_details_right_bar ul li {
        font-size: 15px;
        margin-bottom: 20px;
    }

    .product_details_right_bar h1 {
        font-size: 38px;
        margin-top: 28px;
    }

    .product_details_right_bar p {
        font-size: 15px;
        width: 100%;
        margin-top: 13px;
        text-align: justify;
    }

    .list_bar {
        margin-top: 5%;
        width: 50%;
    }

    .Button_box {
        width: 100%;
        border-top: 1px solid #E1E1E1;
        padding-top: 28px;

    }

    .increamnet_decreament_btn {
        width: 30%;
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        padding-bottom: 8px;
        height: 48px;


    }

    .add_cart {
        margin-bottom: 20px !important;
        border: none;
        padding: 12px;
        height: 48px;
        width: 100%;

    }
    .add_cart_unlogin {
        margin-bottom: 20px !important;
        border: none;
        padding: 12px;
        height: 48px;
        width: 40%;

    }

    .product_details_right_bar h3 {
        font-size: 32px;
    }

    .product_details_right_bar span {
        font-size: 28px;
        font-weight: 600;
    }

    .list_bar p {
        line-height: 20px;

    }

    .list_bar h4 {
        font-size: 16px;
        line-height: 32px;
    }

    .Add_list_btn {
        width: 30%;
        height: 48px;
    }

    .tabs_box_lists ul {

        width: 100%;
        margin-top: 6rem;
    }

    /* .product_details_sec img {
        width: 100%;
    } */

    .items_list p {
        width: 100%;
    }

    .product_details_wrapper {
        padding: 20px 8rem;
        margin-top: 1%;
    }

    .logo_img {
        display: flex;
        column-gap: 34px;

    }
}

@media screen and (min-width: 1801px) and (max-width: 1920px) {
    .product_details_sec {
        display: flex;
        justify-content: space-between;
        padding: 30px 8rem;
        margin-top: 5%;
    }

    .product_details_right_bar {
        width: 48%;
    }

    .product_details_right_bar ul {
        flex-wrap: wrap;
    }

    .product_details_right_bar ul li {
        font-size: 15px;
        margin-bottom: 20px;
    }

    .product_details_right_bar h1 {
        font-size: 38px;
        margin-top: 28px;
    }

    .product_details_right_bar p {
        font-size: 15px;
        width: 100%;
        margin-top: 13px;
        text-align: justify;
    }

    .list_bar {
        margin-top: 5%;
        width: 40%;
    }

    .Button_box {
        width: 100%;
        border-top: 1px solid #E1E1E1;
        padding-top: 28px;

    }

    .increamnet_decreament_btn {
        width: 30%;
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        padding-bottom: 8px;
        height: 48px;
    }

    .add_cart {
        margin-bottom: 20px !important;
        border: none;
        padding: 12px;
        height: 48px;
        width: 100%;

    }
    .add_cart_unlogin {
        margin-bottom: 20px !important;
        border: none;
        padding: 12px;
        height: 48px;
        width: 40%;

    }

    .product_details_right_bar h3 {
        font-size: 32px;
    }

    .product_details_right_bar span {
        font-size: 28px;
        font-weight: 600;
    }

    .list_bar p {
        line-height: 20px;

    }

    .list_bar h4 {
        font-size: 16px;
        line-height: 32px;
    }

    .Add_list_btn {
        width: 30%;
        height: 48px;
    }

    .tabs_box_lists ul {

        width: 100%;
        margin-top: 6rem;
    }

    /* .product_details_sec img {
        width: 100%;
    } */

    .items_list p {
        width: 100%;
    }

    .product_details_wrapper {
        padding: 20px 8rem;
        margin-top: 1%;
    }

    .logo_img {
        display: flex;
        column-gap: 34px;
    }

    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        grid-template-rows: repeat(1, minmax(300px, 1fr));
        gap: 30px;
        margin-top: 2%;
    }
}

@media screen and (min-width: 1921px) and (max-width: 2560px) {
    .product_details_sec {
        display: flex;
        justify-content: space-between;
        padding: 30px 25rem;
        margin-top: 5%;
    }

    .product_details_right_bar {
        width: 48%;
    }

    .product_details_right_bar ul {
        flex-wrap: wrap;
    }

    .product_details_right_bar ul li {
        font-size: 15px;
        margin-bottom: 20px;
    }

    .product_details_right_bar h1 {
        font-size: 38px;
        margin-top: 28px;
    }

    .product_details_right_bar p {
        font-size: 15px;
        width: 100%;
        margin-top: 13px;
        text-align: justify;
    }

    .list_bar {
        margin-top: 5%;
        width: 40%;
    }

    .Button_box {
        width: 100%;
        border-top: 1px solid #E1E1E1;
        padding-top: 28px;
    }

    .increamnet_decreament_btn {
        width: 30%;
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        padding-bottom: 8px;
        height: 48px;

    }

    .add_cart {

        margin-bottom: 20px !important;
        border: none;
        padding: 12px;
        height: 48px;
        width: 100%;

    }
    .add_cart_unlogin {

        margin-bottom: 20px !important;
        border: none;
        padding: 12px;
        height: 48px;
        width: 100%;

    }

    .product_details_right_bar h3 {
        font-size: 32px;
    }

    .product_details_right_bar span {
        font-size: 28px;
        font-weight: 600;
    }

    .list_bar p {
        line-height: 20px;

    }

    .list_bar h4 {
        font-size: 16px;
        line-height: 32px;
    }

    .Add_list_btn {
        width: 30%;
        height: 48px;
    }

    .tabs_box_lists ul {

        width: 100%;
        margin-top: 6rem;
    }

    /* .product_details_sec img {
        width: 100%;
    } */

    .items_list p {
        width: 100%;
    }

    .product_details_wrapper {
        padding: 20px 8rem;
        margin-top: 1%;
    }

    .logo_img {
        display: flex;
        column-gap: 34px;

    }

    .ProductCardsectt {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
        grid-template-rows: repeat(1, minmax(204px, 1fr));
        gap: 30px;
        margin-top: 2%;
    }
}


