.image_container { border: 0.5px solid var(--light-gray);}
.cart_like_view_btn_wrapper {
    opacity: 0;
    transition: var(--mainTransition);
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 10px;
    padding-right: 10px;
    padding-top: 10px;
    cursor: pointer;
    min-height: 350px;
    max-width: 3500px;
}

.cart_like_view_btn_wrapper>span {
    padding: 11px;
    background: rgb(225, 225, 225, 0.4);
    color: #6F758B;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.cart_like_view_btn_wrapper>span:hover{
    color: red;
}

/* .cart_like_view_btn_wrapper i{position:absolute;left:0;right:0;width:20px;height:30px;margin:auto} .cart_like_view_btn_wrapper i{background:url("https://community.algolia.com/places/images/svg/icon-heart-dark-66670e8e.svg") no-repeat center center/contain}
@keyframes pulse{0%{box-shadow:0 0 0 0 rgba(251,54,110,0.4)}70%{box-shadow:0 0 0 30px rgba(251,54,110,0)}100%{box-shadow:0 0 0 0 rgba(251,54,110,0)}}@keyframes pulseHeart{0%{transform:scale(1.3)}70%{transform:scale(1.15)}100%{transform:scale(1)}}.cart_like_view_btn_wrapper i{display:block;position:relative}.i:before{content:'';display:block;width:8px;height:8px;position:absolute;top:45%;bottom:0;left:50%;transform:translate(-50%, -50%);right:0;z-index:-1;border-radius:100%}.cart_like_view_btn_wrapper:hover i{animation:pulseHeart 1s ease infinite}.cart_like_view_btn_wrapper:hover i:before{animation:pulse 1s ease infinite !important}i{width:0;height:0;border-top:5px solid transparent;border-bottom:5px solid transparent;border-left:5px solid #788795;position:relative;display:inline-block;margin-left:8px}

.cart_like_view_btn_wrapper:hover i{background:url("https://community.algolia.com/places/images/svg/icon-heart-light-fc92b1e0.svg") no-repeat center center/contain} */
.cart_like_view_btn_wrapper:hover {
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-right: 10px;
    padding-top: 10px;
    border: 0.5px solid var(--dark-orange);
}
.product_detail_section { margin-top: 30px; }
.product_name {
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: var(--dark-brinjal);
    cursor: pointer;
}
.company_name,
.product_category,
.product_sku {
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: var(--light-brinjal);
    text-decoration: none;
    margin-top: 5px;
}

.product_prices {
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    color: var(--dark-gray);
    margin-top: 10px;
}

.product_prices>span {
    margin-left: 5px;
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: var(--dark-gray);
}
.arabic_product_prices span{
    margin-right: 10px;
}