.main_wrapper {
    padding: 20px;
    margin-top: 20px; /* Adding margin from top */
}

.hero_image_wrapper {
    text-align: center;
    margin-bottom: 30px;
}

.hero_image_heading {
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.form_wrapper {
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form_group {
    margin-bottom: 15px;
}

.form_group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.form_group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1rem;
    transition: border-color 0.3s;
}

.form_group input:focus {
    border-color: #007bff;
    outline: none;
}

.button_wrapper {
    text-align: center;
}

.button_style {
    display: inline-block;
    padding: 12px 20px;
    background-color: #212F63;
    color: #fff;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

/* button:hover {
    background-color: #212F63;
} */

.message {
    margin-top: 20px;
    text-align: center;
    color: #d9534f;
    font-weight: bold;
}
