.main_wrapper{
    padding: 30px 10rem;
    
}
.shoping_cart{
    display: flex;
    justify-content: space-between;
    margin: 0% 0px 1rem;
}
.shoping_cart h1{
    font-size: 40px;
    color: #414141;
    font-weight: 600;
    text-transform: capitalize;
   
}
.shoping_cart p{
    font-size: 16px;
    color: #6F758B    ;
    font-weight: 400;
    line-height: 22px;
    margin-top: 25px;
    text-align: justify;
}
.shoping_cart_tabs{
    background: #6F758B;
    display: flex;
    list-style: none;
    justify-content: space-between;
    padding: 15px 44px;
}
.shoping_cart_tabs_lists p{
    color: white;
    cursor: pointer;
   
}
.shoping_cart_tabs_lists{
    display: flex;
    justify-content: space-between;
    width: 30%;
}
.shoping_cart_tabs_lists_filter{
    display: flex;
    justify-content: space-between;
    width: 32%;
    align-items: center;
}
.shoping_cart_tabs_lists2{
    display: flex;
    justify-content: space-between;
    width: 48%;
}
.shoping_cart_tabs_lists2 div{
    width: 35%;
}
.shoping_cart_tabs_lists2 p{
    color: white;
    cursor: pointer;
}
.shoping_cart_items{
    background: #F7F7F7;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    padding: 30px 44px;
    align-items: center;
}
.shoping_cart_items_products{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.shoping_cart_items_products1{
    display: flex;
    justify-content: space-between;
    width: 100%!important;
    align-items: center;
}
.shoping_cart_items_lists{
    width: 40%;
}
.shoping_cart_items_products1 i{
    margin-left: 10px;
  
}
.cart_icon{
    display: flex;
    align-items: center;
    column-gap: 20px;
}
.cart_icon i{
    color: #6F758B;
}
.orange_text p{
    color: #F86B1F;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
}
.orange_text span{
    color: #F86B1F;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
}
.shoping_cart_list_products{
    display: flex;
    justify-content: space-between;
    margin: 3% 0px;
}
.coupan_code_box h2{
    font-size: 22px;
    color: #414141;
    font-weight: 600;
}
.coupan_code_box p{
    color: #6F758B    ;
    font-size: 15px;
    margin-top: 20px;

}
.form_box input{
    border: 1px solid #E1E1E1    ;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    outline: none;
}
.form_box{
    display: flex;
    align-items: center;
}
.form_box button{
    background: #212F63;
    color: white;
    padding: 12px;
    border: none;
    margin-left: 10px;
}
.select_box_shopping h2{
    font-size: 22px;
    color: #414141;
    font-weight: 600;
}
.select_box_shopping p{
    color: #6F758B    ;
    font-size: 15px;
    margin-top: 20px;
}
.select_box_Cart button{
    background: #212F63;
    color: white;
    padding: 12px;
    border: none;
    margin-left: 10px;
    width: 100%;
    margin-top: 30px;
}
.select_box_Cart2{
    width: 100%;
}
.select_box_heading{
    width: 48%;
}

.select_box_Cart1 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.select_box_Cart{
    font-size: 18px;
    color: #414141;
    font-weight: 400;
}
.zipcode_item {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.zipcode_item input{
    border: 1px solid #f9efef!important;
    height: 40px;
    padding-left: 12px;
    outline: none;
}
.zipcode_item h1{
    width: 48%;
}
.zipcode_item input::placeholder{
    color: #E1E1E1;
}
.order_summary_heading{
    font-size: 22px;
    color: #414141;
    font-weight: 600;
}
.order_summary{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
/* .order_summary div{
    width: 48%;
} */
.order_summary_lists p{
    font-size: 18px;
    color: #6F758B;
    font-weight: 400;
    line-height: 35px;
    text-transform: capitalize;
    
}
.order_summary_lists h2{
    font-size: 22px;
    color: #6F758B;
    font-weight: 600;
    line-height: 30px;
    text-transform: capitalize;
}
.order_summary_lists1 p{
    font-size: 18px;
    color: #6F758B;
    font-weight: 400;
    line-height: 35px;
    text-transform: capitalize;
}
.order_summary_lists1 h2{
    font-size: 22px;
    color: #414141;
    font-weight: 600;
    line-height: 30px;
    text-transform: capitalize;
}
.order_summary_section button{
    background: #212F63;
    color: white;
    padding: 12px;
    border: none;
    margin-left: 10px;
    width: 100%;
    margin-top: 30px;
}
.shoping_cart_item_filters h5{
    color: #414141;
    font-size: 15px;
    font-weight: 600;
    margin-left: 18px;
    cursor: pointer;
}
.shoping_cart_description h4{
    color: #6F758B    ;
    font-size: 15px;
    font-weight: 500;
}
.shoping_cart_description p{
    color: #6F758B;
    font-size: 15px;
    font-weight: 500;
    white-space: nowrap;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
   

}
.shoping_cart_item_filters{
    display: flex;
    align-items: center;
}
.shoping_cart_item_filters img{
    width: 42px;
    height: 42px;
    cursor: pointer;
}
.shoping_cart_tabs_lists_product{
    display: flex;
    justify-content: space-between;
    width: 48%;
    align-items: center;
}
.quantity_dec {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: white;
    border: 1px solid #E1E1E1;
    padding: 12px;
    width: 140px;

  }
  .quantity__minus_btn,
  .quantity__plus_btn {
    display: block;
    width: 22px;
    height: 23px;
    margin: 0;
    text-decoration: none;
    text-align: center;
    line-height: 23px;
  }
  .quantity__minus_btn span{
    font-size: 25px;
  }
  .quantity__plus_btn span{
    font-size: 25px;
  }

  .quantity__minus_btn {
    border-radius: 3px 0 0 3px;
  }
  .quantity__plus_btn {
    border-radius: 0 3px 3px 0;
  }
  .quantity__input_btn {
    width: 32px;
    height: 19px;
    margin: 0;
    padding: 0;
    text-align: center;
    border-top: 2px solid #dee0ee;
    border-bottom: 2px solid #dee0ee;
    border-left: 1px solid #dee0ee;
    border-right: 2px solid #dee0ee;
    background: #fff;
    color: #414141;
    font-size: 20px;

  }
  .quantity__minus_btn:link,
  .quantity__plus_btn:link {
    color: #8184a1;
  } 
  .quantity__minus_btn:visited,
  .quantity__plus_btn:visited {
    color: #212F63
    ;
  }
@media screen and (max-width: 767px) {
    .main_wrapper{
        padding: 30px 1rem;
        margin-top: 5rem;
    }
    .shoping_cart{
        display: flex;
        align-items: center;
    }
    .shoping_cart h1{
        font-size: 20px;
    }
    .coupan_code_box h2{
        font-size: 20px;
    }
    .coupan_code_box p {
        color: #6F758B;
        font-size: 14px;
        margin-top: 4px;
    }
    .shoping_cart p{
        font-size: 14px;
        margin-top: 8px;
    }
    .shoping_cart_tabs ul {
        background: #6F758B;
        display: flex;
        list-style: none;
        justify-content: space-between;
        padding: 10px 16px;
        flex-wrap: wrap;
        line-height: 34px;
    }
    .shoping_cart_items{
        display: block;
        padding: 15px 16px;
    }
    .shoping_cart_items_products{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 0;

    }
    .shoping_cart_tabs_lists_filter{
        width: 100%;
        display: block;
    }
    .shoping_cart_tabs_lists_product{
        width: 100%;
       display: flex;
    }
    .shoping_cart_item_filters img {
        width: 100px;
        height: 100px;
    }
    .shoping_cart_item_filters h5 {
        color: #414141;
        font-size: 15px;
        font-weight: 600;
        margin-left: 18px;
        margin-top: 16px;
    }
    .shoping_cart_description h4 {
        margin-left: 65px;
        position: relative;
        bottom: 58px;
        left: 58px;
     width: 195px!important;
        overflow: hidden
    }
    .shoping_cart_tabs_lists_product {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-top: -10px;
    }
    .orange_text p {
        margin-top: 10px;
        margin-left: 34px;
    }
    .Shoppingcart_cart_icon {
        margin-top: 10px;
        margin-top: 10px;
        position: absolute;
        bottom: 0px;
        left: 300px;
        width: 0%;
    }
    .shoping_cart_item_filters{
        column-gap: 4px;
        align-items: flex-start;
    }
    .shoping_cart_items_lists{
        width: 100%;
    }
    .shoping_cart_items_products1 {
        display: flex;
        justify-content: space-between;
        width: 100%!important;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 18px;
        line-height: 32px;
    }
    .shoping_cart_list_products {
        display: flex;
        justify-content: space-between;
        margin: 3% 0px;
        flex-wrap: wrap;
    
    }
    /* .coupan_code_box{
        margin-bottom: 30px;
    } */
    .select_box_shopping h2{
        font-size: 20px;
    }
    .order_summary_lists h2{
        font-size: 18px;
    }
    .order_summary_lists1 h2{
        font-size: 18px;
    }
    .order_summary{
        margin-top: 10px;
    }
    .select_box_shopping p{
        margin-bottom: 0px;
    }
    .select_box_shopping p{
        font-size: 14px;
        margin-bottom: 0px;
        margin-top: 10px;
    }
    .order_summary_heading{
        font-size: 20px;
    }
    .order_summary_section{
        margin-top: 30px;
        width: 100%;
    }
    .select_box_Cart button{
        margin-left: 0px;
    }
    .order_summary_section button{
        margin-left: 0px;
    }
    .order_summary_lists p {
        font-size: 14px;
        color: #6F758B
    }
    .order_summary_lists1 p {
        font-size: 14px;
    }
    /* .order_summary div {
        width: 40%;
    }   */
    .shoping_cart_tabs {
        background: #6F758B;
        display: none;
        list-style: none;
        padding: 15px 15px;
    }  
    .orange_text p{
        margin-top: 10px;
    }
    .cart_icon{
        margin-top: 10px;
        position: relative;
        bottom: 134px;
        left: 18px;
    }
    .form_box input{
        font-size: 14px;
      
    }
    .form_box button{
        font-size: 14px;
       
    }
    .select_box_Cart button{
        font-size: 14px;
       
    }
    .increamnet_decreament_btn{
        
        margin-top: 10px;
        height: 40px!important;
    }
    .increamnet_decreament_btn span{
        font-size: 16px!important;
    }
    .shoping_cart_tabs_lists{
        width: 100%;
        column-gap: 14px;
        justify-content: flex-start;
    }
    .shoping_cart_tabs_lists2{
        width: 100%;
        column-gap: 14px;
    }
    .shoping_cart_tabs_lists p{
        font-size: 14px;
    }
    .shoping_cart_tabs_lists2 p{
        font-size: 14px;
    }
    .shoping_cart_description h4{
        margin-left: 65px;
    }
    .shoping_cartprice_mark p{
        display: none;
        font-family: 'Neue Haas Grotesk Text Pro';
        font-size: 14px;
        font-weight: 500;
    }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .main_wrapper{
        padding: 30px 2rem;
    }
    .shoping_cart{
        display: flex;
        align-items: center;
    }
    .shoping_cart h1{
        font-size: 30px;
    }
    .shoping_cart p{
        font-size: 14px;
        margin-top: 8px;
    }
    .shoping_cart_tabs ul {
        background: #6F758B;
        display: flex;
        list-style: none;
        justify-content: space-between;
        padding: 10px 16px;
        flex-wrap: wrap;
        line-height: 34px;
    }
    .shoping_cart_items{
        display: flex;
    }
    .shoping_cart_items_products{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 0;

    }
    .shoping_cart_items_lists{
        width: 100%;
    }
    .shoping_cart_items_products1 {
        display: flex;
        justify-content: space-between;
        width: 100%!important;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 18px;
        line-height: 32px;
    }
    .shoping_cart_list_products {
        display: block;
        justify-content: space-between;
        margin: 3% 0px;
        flex-wrap: wrap;
    
    }
    .coupan_code_box{
        margin-bottom: 30px;
    }
    .order_summary_section{
        margin-top: 30px;
        width: 100%;
    }
    .select_box_Cart button{
        margin-left: 0px;
    }
    .order_summary_section button{
        margin-left: 0px;
    }
    .order_summary_lists p {
        font-size: 14px;
        color: #6F758B
    }
    .order_summary_lists1 p {
        font-size: 14px;
    }
    .order_summary div {
        width: 40%;
    } 
}
@media screen and (min-width: 1201px) and (max-width: 1366px) {
    .main_wrapper{
        padding: 30px 4rem;
    }
    .shoping_cart{
        display: flex;
        align-items: center;
    }
    .shoping_cart h1{
        font-size: 30px;
    }
    .shoping_cart p{
        font-size: 14px;
        margin-top: 8px;
    }
    .shoping_cart_tabs ul {
        background: #6F758B;
        display: flex;
        list-style: none;
        justify-content: space-between;
        padding: 10px 16px;
        flex-wrap: wrap;
        line-height: 34px;
    }
    .shoping_cart_items{
        display: flex;
    }
    .shoping_cart_items_products{
        width: 48%;
        display: flex;
        flex-wrap: wrap;
        padding: 0;

    }
    .shoping_cart_items_lists{
        width: 48%;
    }
    .shoping_cart_items_products1 {
        display: flex;
        justify-content: space-between;
        width: 100%!important;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 18px;
        line-height: 32px;
    }
    .shoping_cart_list_products {
        display: flex;
        justify-content: space-between;
        margin: 3% 0px;
        column-gap: 40px;
    
    }
    .coupan_code_box{
        margin-bottom: 30px;
    }
    .order_summary_section{
        margin-top: 30px;
        width: 50%;
    }
    .select_box_Cart button{
        margin-left: 0px;
    }
    .order_summary_section button{
        margin-left: 0px;
    }
    .order_summary_lists p {
        font-size: 14px;
        color: #6F758B
    }
    .order_summary_lists1 p {
        font-size: 14px;
    }
    .order_summary div {
        width: 40%;
    }

}
@media screen and (min-width: 1367px) and (max-width: 1600px) {
    .main_wrapper{
        padding: 30px 6rem;
    }
    .shoping_cart{
        display: flex;
        align-items: center;
    }
    .shoping_cart h1{
        font-size: 30px;
    }
    .shoping_cart p{
        font-size: 14px;
        margin-top: 8px;
    }
    .shoping_cart_tabs ul {
        background: #6F758B;
        display: flex;
        list-style: none;
        justify-content: space-between;
        padding: 10px 16px;
        flex-wrap: wrap;
        line-height: 34px;
    }
    .shoping_cart_items{
        display: flex;
    }
    .shoping_cart_items_products{
        width: 48%;
        display: flex;
        flex-wrap: wrap;
        padding: 0;

    }
    .shoping_cart_items_lists{
        width: 48%;
    }
    .shoping_cart_items_products1 {
        display: flex;
        justify-content: space-between;
        width: 100%!important;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 18px;
        line-height: 32px;
    }
    .shoping_cart_list_products {
        display: flex;
        justify-content: space-between;
        margin: 3% 0px;
    
    }
   .select_box_shopping{
    width: 30%;
   }
   
 
    .coupan_code_box{
        margin-bottom: 30px;
        width: 30%;
    }
    .order_summary_section{
        margin-top: 30px;
        width: 30%;
    }
    .select_box_Cart button{
        margin-left: 0px;
    }
    .order_summary_section button{
        margin-left: 0px;
    }
    .order_summary_lists p {
        font-size: 14px;
        color: #6F758B
    }
    .order_summary_lists1 p {
        font-size: 14px;
    }
    .order_summary div {
        width: 40%;
    }
}
@media screen and (min-width: 1601px) and (max-width: 1800px) {
    .main_wrapper{
        padding: 30px 7rem;
    }
    .shoping_cart{
        display: flex;
        align-items: center;
    }
    .shoping_cart h1{
        font-size: 30px;
    }
    .shoping_cart p{
        font-size: 14px;
        margin-top: 8px;
    }
    .shoping_cart_tabs ul {
        background: #6F758B;
        display: flex;
        list-style: none;
        justify-content: space-between;
        padding: 10px 16px;
        flex-wrap: wrap;
        line-height: 34px;
    }
    .shoping_cart_items{
        display: flex;
    }
    .shoping_cart_items_products{
        width: 48%;
        display: flex;
        flex-wrap: wrap;
        padding: 0;

    }
    .shoping_cart_items_lists{
        width: 48%;
    }
    .shoping_cart_items_products1 {
        display: flex;
        justify-content: space-between;
        width: 100%!important;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 18px;
        line-height: 32px;
    }
    .shoping_cart_list_products {
        display: flex;
        justify-content: space-between;
        margin: 3% 0px;
    
    }
   .select_box_shopping{
    width: 30%;
   }
   
 
    .coupan_code_box{
        margin-bottom: 30px;
        width: 30%;
    }
    .order_summary_section{
        margin-top: 30px;
        width: 30%;
    }
    .select_box_Cart button{
        margin-left: 0px;
    }
    .order_summary_section button{
        margin-left: 0px;
    }
    .order_summary_lists p {
        font-size: 14px;
        color: #6F758B
    }
    .order_summary_lists1 p {
        font-size: 14px;
    }
    .order_summary div {
        width: 40%;
    }
}
.Button_box {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin-top: 5%;
}

.increamnet_decreament_btn {
    background: transparent;
    border: 1px solid #E1E1E1;
    width: 150px;
    display: flex;
    justify-content: space-evenly;
    height: 50px;
}

.increamnet_decreament_btn span {
    font-size: 22px;
    font-weight: 400;
    margin-top: 10px;
    color: #212F63;
}
.shoping_cart_description h4{

    color: #6F758B;
    font-size: 15px;
    font-weight: 500;
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  