.main_wrapper{
    padding: var(--whitespace);
    padding-bottom: 0px !important;
    margin-top: 2%;
}

.main_wrapper > h1{
    /* H --/H2 */
    
        font-family: 'Neue Haas Grotesk Text Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 140%;
        /* identical to box height, or 45px */
    
        font-feature-settings: 'pnum' on, 'lnum' on;
        text-align: center;
        margin-bottom: 25px;
    
        /* Black */
    
        color: #414141;
}

.main_wrapper> p {
    padding: 10px 50px ;
    /* Body --/Body Reg 16 */
    
        font-family: 'Neue Haas Grotesk Text Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        text-align: justify;
        /* or 26px */
    
    
        /* Grey 1 */
    
        color: #6F758B;
}

#track_form{
    background: #F7F7F7;
    padding: 30px 50px;
        display: grid;
        grid-template-columns: 40% 40% 10%;
        grid-gap: 30px;
        align-items: center;
        margin-bottom: 5%;
        margin-top: 4%;
}

.submit_btn{
    height: fit-content;
    background: #212F63;
    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    text-transform: capitalize;
    color: #FFFFFF;
    padding: 8px 10px;
    border: none;
    height: 48px;
    margin-top: 8px;
}




@media screen and (max-width: 1020px) {

#track_form {
 
        grid-template-columns: 35% 35% 20%;

    }
}

@media screen and (max-width: 700px) {

    #track_form {

        grid-template-columns: 1fr 1fr;

    }
.main_wrapper{
    margin-top: 5rem;
}
    #span_2{
        grid-column: 1 / span 2 !important;
    }
}


@media screen and (max-width: 550px) {

    #track_form {
        display: block;
    }

                .submit_btn {
            width: calc(107% - 20px);
        }

                .main_wrapper>p {
                    padding: 30px 0px;
                }

}