.main_wrapper{
    padding: 30px 10rem;
}
.checkout_cart{
    display: flex;
    justify-content: space-between;
    margin: 5% 0px;
    align-items: center;
}
.checkout_cart h1{
    font-size: 40px;
    color: #414141;
    font-weight: 600;
    text-transform: capitalize;
    
}

.custom_checkbox {
    width: 24px;
    height: 24px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 3px solid #6F758B;
    border-radius: 24px;
    background: none;
    cursor: pointer;
    background: none;
    cursor: pointer;
    line-height: 0;
    margin: 0 0.6em 0 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: text-top;
  }
  
  .custom_checkbox:checked {
      background-color: #F86B1F;
      /* border: 2px solid orange; */
      border-radius: 24px;
  }
  
  /* .custom_checkbox:checked::before {
      content: "\2713"; 
      color: white;
      font-size: 16px;
      text-align: center;
      line-height: 20px;
      position: relative;
      top: -3px;
      left: 1px;
  } */
.label.Paymentgate_payment_gateway_radio_btn__Z-sTC {
    /* float: left; */
    /* width: 50%; */
    padding: 127px;
}   


.checkout_cart p{
    font-size: 16px;
    color: #6F758B    ;
    font-weight: 400;
    line-height: 22px;
    text-align: justify;
}
.checkout_cart_coupancode{
    border: 1px solid #E1E1E1;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.checkout_cart_heading{
    display: flex;
    align-items: center;
}
.checkout_cart_heading span{
    display: flex;
    margin-left: 20px;
    color: #6F758B;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
}
.checkout_cart_heading h4{
    color: #212F63    ;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    margin-left: 2px;
}
.checkout_cart_coupancode i{
    color: #212F63;
}
.checkout_cart_customer{
    background: #F7F7F7;
    padding: 30px;
    margin-top: 45px;
}
.checkout_cart_customer span{
    display: flex;
    color: #F86B1F;
    font-size: 26px;
    font-weight: 500;
    align-items: center;
}
.checkout_cart_customer h3{
    color: #414141;
    font-size: 26px;
    font-weight: 500;
    margin-left: 10px
}
.checkout_cart_customer h4 {
    color: #6F758B;
    font-size: 16px;
    font-weight: 500;
    margin-left: 30px;
}
.checkout_form_box input{
    border: 1px solid #E1E1E1;
    padding: 10px;
    width: 100%;
    outline: none;
}
.checkout_form_box{
    display: flex;
    margin-top: 15px;
}
.checkout_form_box button{
    background: #212F63;
    padding: 10px 40px;
    color: white;
    border: none;
    margin-left: 20px;
    cursor: pointer;
}
.checkout_subscribe{
    display: flex;
    align-items: center;
    margin-top: 15px;
}
.checkout_subscribe h3{
    color: #414141;
    font-size: 18px;
    font-weight: 400;
    margin-left: 10px
}
.checkout_cart_customer p{
    display: flex;
    color: #6F758B
    ;
    font-size: 16px;
    text-transform: capitalize;
    margin-top: 15px;
    align-items: center;

}
.checkout_cart_customer h5{
    display: flex;
    color: #212F63;
    font-size: 16px;
    text-transform: capitalize;
    margin-left: 2px;

}

.checkout_cart_gray_box{
    background: #F7F7F7;
    padding: 30px;
    margin-top: 45px;
}
.checkout_cart_gray_box span{
    color: #F86B1F;
    font-size: 29px;
    font-weight: 600;
    display: flex;
    align-items: center
}
.checkout_cart_gray_box1{
    background: #F7F7F7;
    padding: 30px;
    margin-top: 45px;
}
.checkout_cart_gray_box1 span{
    color: #D1D1D2;
    font-size: 29px;
    font-weight: 600;
    display: flex;
    align-items: center
}
.checkout_cart_gray_box1 p{
    color: #6F758B    ;
    font-size: 22px;
    font-weight: 600;
    margin-left: 10px;
}
.checkout_cart_gray_box p{
    color: #6F758B    ;
    font-size: 22px;
    font-weight: 600;
    margin-left: 10px;
}
.checkout_order_summery h2{
    color: #666666 ;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
}
.checkout_order_summery{
    background: #F7F7F7;
    padding: 30px;
    width: 35%;

}
.checkout_cart_customer_product{
    display: flex;
    justify-content: space-between;
}
.checkout_cart_customer_products{
    width: 60%;
}
.checkout_items_products{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    align-items: center;
}
.checkout_items_oil_filters{
    display: flex;
    align-items: center;
}
.checkout_items_oil_filters img{
    margin-right: 30px;
}
.checkout_items_products h5{
    font-size: 16px;
    color: #414141;
    font-weight: 400;
}
.checkout_items_products{
    border-bottom: 1px solid #cdcdcd;
    padding-bottom:20px
}
.checkout_items_products h4{
    color: #6F758B;
    font-size: 16px;
    font-weight: 400;

}
.checkout_order_summery{
    margin-top: 45px;
    height: 100%;
}
.order_summary{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

}
.order_summary_section{
    margin-top: 30px;
}
/* .order_summary div{
    width: 28%;
} */

.order_summary_lists p{
    font-size: 15px;
    color: #6F758B;
    font-weight: 400;
    line-height: 28px;
    text-transform: capitalize;
   
}
.order_summary_lists h2{
    font-size: 16px;
    color: #F86B1F
    ;
    font-weight: 600;
    line-height: 28px;
    text-transform: capitalize;
     margin-top: 15px;
}
.order_summary_lists1 p{
    font-size: 15px;
    color: #414141;
    font-weight: 500;
    line-height: 28px;
    text-transform: capitalize;
}
.order_summary_lists1 h2{
    font-size: 16px;
    color: #F86B1F    ;
    font-weight: 600;
    line-height: 28px;
    text-transform: capitalize;
    margin-top: 15px;
}
.checkout_cart_gray_box h5{
    font-size: 18px;
    color: #414141;
    margin-left: 35px;
    margin-top: 10px;
    font-weight: 500;
    text-transform: uppercase;
}
.input_fileds{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2%;
}
.input_fileds div{
    width: 48%;
}
.input_fileds input{
    padding: 14px;
    margin-top: 14px;
    outline: none;
}
.input_filed_box input{
    padding: 14px;
    margin-top: 14px;
    outline: none;
}
.input_filed_box label{
margin-top: 14px;
}
.input_filed_box{
    margin-top: 5%;
}

.form_group{
    display: flex;
    align-items: center;
    height: 100%;
   
}
.deliver_text{
    display: flex;
    column-gap: 28px;
    border-bottom: 1px solid#E7EAF2;
    padding-bottom: 26px;
    width: 95%;
    margin: 0 auto;
}
.deliver_text h4{
    font-size: 18px;
    color: #212F63;
    font-weight: 600;
    text-transform: capitalize;
}
.deliver_text h4:nth-child(2){
color: #F86B1F;
}
.shipping_method{
    background: white;
    margin-top: 30px;
}
.shipping_method:hover{
    border: 1px solid #F86B1F;
}
.form_group h6{
    font-size: 18px;
    color: #414141;
    font-weight: 500;
    margin-left: 15px;
}
.deliver_step{
    background: white;
    display: flex;
    align-items: center;
    padding-bottom: 2%;
    padding-top: 2%;
   margin-top: 4%;
   padding-left: 18px;
}
.deliver_step h6{
    font-size: 18px;
    color: #6F758B;
    font-weight: 500;
    margin-left: 15px;
}
.deliver_step input{
    display: none;
    cursor: pointer;
}
.deliver_step label{
    -webkit-appearance: none;
    background-color: #fafafa;
    border: 1px solid #6F758B;
    box-shadow: 0 1px 2px rgb(0 0 0 / 5%), inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
    padding: 10px;
    border-radius: 50px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
}

.custom_radio_wrap h3{
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: #414141;
    margin-top: 4%;
    margin-bottom: 3%;
}
.select_box_method{
    display: flex;
    justify-content: space-between;
    padding: 10px 18px 40px;
    flex-wrap: wrap;
}

.select_box_Cart1{
    width: 48%;
}
.select_box_Cart2 input{
    border: 1px solid #e3e3e3;
    height: 48px;
    padding: 10px;
    margin-top: 5px;
}
.select_box_heading h1{
    margin-bottom: 14px;
    margin-top: 20px;
}
.address_name_content{
    padding: 18px;
}
.address_name_content h1{
    font-size: 18px;
    color: #414141;
    font-weight: 500;
    margin-bottom: 16px;
}
.address_name_content input{
    border: 1px solid #E1E1E1;
    height: 48px;
    padding: 10px;
}

.address_name_content::placeholder {
color: #6F758B;
}
.order_method{
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: #414141;
    margin-top: 4%;
}
.order_method input{
    padding: 14px;
    margin-top: 14px;
    outline: none;
    font-size: 16px;
}
.order_method input::placeholder {
color: #6F758B;
}
.order_method button{
    background: #212F63;
    padding: 10px 40px;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 20px;
}
.checkbox_wrap input[type=checkbox] {
    position: relative;
      border: 2px solid #212F63;
      border-radius: 2px;
      background: none;
      cursor: pointer;
      line-height: 0;
      margin: 0 .6em 0 0;
      outline: 0;
      padding: 0 !important;
      vertical-align: text-top;
      height: 20px;
      width: 20px;
      -webkit-appearance: none;
    opacity: .5;
  }
  
  .checkbox_wrap input[type=checkbox]:hover {
    opacity: 1;
  }
  
  .checkbox_wrap input[type=checkbox]:checked {
    background-color: #212F63;
    opacity: 1;
  }
  
 .checkbox_wrap input[type=checkbox]:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 4px;
    height: 10px;
    border: solid #FFF;
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
  }
  .checkbox_wrap{
    padding: 18px;
  }
  .checkbox_wrap label{
    font-size: 18px;
    color: #414141;
    font-weight: 500;
  }

/*payment-gateway*/
.checkout_gatway_wrapper{
    margin-top: 4%;
    margin-left: 35px;
}
.checkout_gatway_wrapper p{
    display: flex;
    font-size: 18px;
    font-weight: 500;
    margin-left: 0px;
}
.checkout_gatway_wrapper span{
    font-size: 18px;
    font-weight: 500;
    margin-left: 0px;
    color: #6F758B;
}
.checkout_gatway_wrapper h3{
    font-size: 16px;
    font-weight: 400;
    color: #6F758B;
    text-transform: capitalize;
    margin-top: 10px;
   
}
.checkout_gatway_wrapper h4{
    font-size: 16px;
    font-weight: 400;
    color: #6F758B;
    text-transform: capitalize;
    margin-top: 10px;
    display: flex;
    border-bottom: 1px solid #e3e3e3;
    width: 50%;
    padding-bottom: 32px;
}
.payment_gateway_text h4{
    font-size: 16px;
    font-weight: 400;
    color: #6F758B;
    text-transform: capitalize;
    margin-top: 10px;
    display: flex;
    margin-top: 20px;
    margin-left: 35px;
}
.payment_gateway_text span{
    font-size: 18px;
    font-weight: 500;
    margin-left: 10px;
    color: #414141;
}
.payment_gateway_details span{
    display: flex;
    color: #F86B1F;
    font-weight: 600;
    font-size: 22px;

}
.payment_gateway_details p{
    color: #414141;
    font-weight: 600;
    font-size: 22px;
    margin-left: 26px;
}
.payment_gateway_details {
    background-color: #F7F7F7 ;
    margin-top: 30px;
    padding: 40px 30px 20px;
}
.payment_gateway_credit_card_details{
    background: #212F63;
    margin-top: 4%;
}
.payment_gateway_card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 30px;
    border-bottom: 1px solid #626161;
    padding: 36px 0px 32px;

}
.payment_gateway_details .payment_label_text{
    color: white;
    font-size: 18px;
    font-weight: 400;
    margin-left: 12px;
}
.Paymentgate_input_fileds input {
    padding: 14px;
    margin-top: 14px;
    outline: none;
}
.Paymentgate_input_fileds div{
margin-top: 30px;
padding: 10px 30px;

}
.Paymentgate_input_fileds label{
    font-size: 18px;
    color: white;
    font-weight: 400;
}
.input_fileds{
    padding: 10px 30px 42px;
}
.input_fileds label{
    font-size: 18px;
    color: white;
    font-weight: 500;
}
.input_fileds_label label{
display: flex;
align-items: center;
}
.input_fileds_label span{
    background: white;
    width: 24px;
    height: 24px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    font-size: 20px;
    align-items: center;
    color: #212F63;
    font-weight: 400;
}
.payment_gateway_login{
    background: white;
    box-shadow: rgb(0 0 0 / 3%) 0px 1px 4px;
    margin-top: 3%;
    padding: 18px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.payment_gateway_radio_btn{
    color: #6F758B!important;
    font-size: 18px;
    font-weight: 500;
}
.place_order_btn{
    padding: 12px;
    width: 100%;
    background: #212F63;
    border: none;
    margin-top: 22px;
    color: white;
    font-size: 18px;
    font-weight: 500;
}
.comment_form_cookies_consent input[type=checkbox] {
    position: relative;
    border: 3px solid #6F758B  ;
    border-radius: 24px;
    background: none;
    cursor: pointer;
    line-height: 0;
    margin: 0 0.6em 0 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: text-top;
    height: 24px;
    width: 24px;
    -webkit-appearance: none;
    opacity: .5;
  }
  
  .comment_form_cookies_consent label{
    color: white;
    font-size: 16px;
    font-weight: 500;
  }
  .comment_form_cookies_consent input[type=checkbox]:hover {
    opacity: 1;
  }
  
  .comment_form_cookies_consent input[type=checkbox]:checked {
    background-color: #F86B1F
    ;
    opacity: 1;
  }
  
  .comment_form_cookies_consent input[type=checkbox]:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 4px;
    height: 10px;
    border: solid #FFF;
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
    display: none;
  }
@media screen and (max-width: 767px) {
    .main_wrapper{
        padding: 30px 2rem;
        margin-top: 5rem;
    }
    .checkout_cart h1 {
        font-size: 22px;
    }
    .checkout_cart p{
        font-size: 14px;
    }
    .checkout_cart_heading span {
        display: block;
    }
    .checkout_cart_customer_product{
        display: flex;
        flex-direction: column-reverse;
    }
    .checkout_cart_customer_products{
        width: 100%;
    }
    .checkout_form_box{
        display: block;
    }
    .checkout_form_box button{
        width: 100%;
        margin-top: 20px;
        margin-left: 0px;
    }
    .checkout_subscribe h3{
        font-size: 15px;
    }
    .checkout_cart_customer p{
        display: block;
    }
    .checkout_subscribe img{
         width: 6%;
    }
    .checkout_order_summery{
        width: 100%;
        margin-top: 24px;
    }
    .order_summary_lists p {
        font-size: 13px;
        color: #6F758B;
        font-weight: 400;
        line-height: 28px;
        text-transform: capitalize;
        inline-size: 0px;
        width: 100%;
    
}
.order_summary div{
    width: auto;
}
.checkout_order_summary__hOuHT div {
    width: 32%;
}
.order_summary_lists1 p {
    font-size: 13px;
    color: #414141;
    line-height: 28px;
}
.order_summary_lists h2 {
    font-size: 16px;
    color: #F86B1F;
    font-weight: 600;
    line-height: 8px;
    text-transform: capitalize;
    margin-top: 15px;
}
.order_summary_lists1 h2{
    font-size: 14px;
    color: #F86B1F;
    font-weight: 600;
    line-height: 8px;
}
.checkout_cart_customer h3 {
    color: #414141;
    font-size: 15px;
}
.checkout_cart_gray_box p{
    font-size: 14px;
    display: flex;
    align-items: center;
    
}
.checkout_cart_customer h4{
   font-size: 15px;
}
.checkout_cart_gray_box h5{
    font-size: 13px;
    margin-left: 22px;
}
.input_fileds{
    display: block;
    margin-top: 7%;

}
.input_fileds label{
    font-size: 14px;
}
.input_fileds div{
    width: 100%;
    margin-bottom: 20px;
}
.custom_radio_wrap h3{
    margin-top: 14px;
    font-size: 15px;
    margin-bottom: 14px;
}
.form_group h6{
    font-size: 15px;
    margin-left: 10px;
}
.select_box_heading h1 {
    margin-bottom: 6px;
    font-size: 14px;
    margin-top: 8px;
}
select {
    height: 40px;
    margin-top: 5px;
    padding: 5px;
    font-size: 14px;
}
.select_box_Cart2 input{
    font-size: 14px;
}
.address_name_content h1{
    font-size: 14px;
}
.address_name_content input{
    font-size: 14px;
}
.custom_radio_wrap form .form_group label{
    padding: 6px;
}
.Checkoutshipping_address_name_content__SMwCA {
    padding: 1px 18px;
}
.input_fileds input {
    padding: 10px;
    margin-top: 14px;
    outline: none;
    font-size: 14px;
}
.input_filed_box label{
    font-size: 14px;
}
.input_filed_box input {
    padding: 10px;
    margin-top: 14px;
    outline: none;
    font-size: 14px;
}
.form_group{
    padding-bottom: 20px;
}
.select_box_method{
    display: block;
    padding: 10px 18px 5px;
}
.order_summary{
    display: flex;
}
.checkout_cart_customer{
    padding: 20px;
    margin-top: 25px;
}
.checkout_cart_customer i{
    font-size: 20px;
}
.checkout_cart_gray_box span{
    font-size: 14px;
}
.checkout_form_box {
    display: flex;
    margin-top: 2px;
}
.checkout_gatway_wrapper h3{
    font-size: 14px;
    margin-top: 2px;
}
.checkout_gatway_wrapper h4{
    font-size: 14px;
    width: 100%;
}
.payment_gateway_text h4{
    font-size: 14px;
    margin-left: 22px;
}
.payment_gateway_details{
    padding: 40px 10px 20px;
}
.checkout_cart_gray_box{
    padding: 20px;
    margin-top: 26px;
}
.deliver_step {
    background: white;
    display: flex;
    align-items: center;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-top: 4%;
    padding-left: 18px;
}
.deliver_step label{
    padding: 6px;
}

.select_box_method div {
    width: 100%;
    margin-bottom: 10px;
}
.deliver_step h6{
    font-size: 14px;
    margin-left: 10px;
}
.order_method input {
    padding: 14px;
    margin-top: 3px;
    outline: none;
    font-size: 14px;
}
.order_method button {
    background: #212F63;
    padding: 5px 32px;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    font-size: 14px;
}
.checkout_cart_gray_box1 {
    background: #F7F7F7;
    padding: 20px;
    margin-top: 24px;
}
.checkout_cart_gray_box1 p{
    font-size: 16px;
}
.checkout_cart_gray_box1 span{
    font-size: 20px;
}
.checkout_order_summery h2{
    font-size: 15px;
}
.checkout_items_products h5{
    font-size: 14px;
}
.checkout_items_products h4{
    font-size: 14px;
}
.deliver_text{
    padding-bottom: 12px;
    margin: 0px 18px;
}
.deliver_text h4{
    font-size: 12px;
}
.checkbox_wrap label{
    font-size: 15px;
}
.checkout_gatway_wrapper{
    margin-left: 22px;
}
.checkout_gatway_wrapper{
    margin-top: 2%;
}
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .main_wrapper{
        padding: 30px 2rem;
    }
    .checkout_cart h1 {
        font-size: 24px;
    }
    .checkout_cart p{
        font-size: 14px;
    }
    .checkout_cart_heading span {
        display: block;
    }
    .checkout_cart_customer_product{
        display: block;
    }
    .checkout_cart_customer_products{
        width: 100%;
    }
    .checkout_form_box{
        display: flex;
        margin-top: 2px;
    }
    .checkout_form_box button{
        width: 48%;
        margin-top: 0;
    }
    .checkout_subscribe h3{
        font-size: 15px;
    }
    .checkout_cart_customer p{
        display: flex;
    }
    .checkout_subscribe img{
         width: 3%;
    }
    .checkout_order_summery{
        width: 100%;
        margin-top: 30px;
    }
    .order_summary_lists p {
        font-size: 14px;
        color: #6F758B;
        font-weight: 400;
        line-height: 28px;
        text-transform: capitalize;
        inline-size: 400px;
}
.form_group {
    display: flex;
    align-items: center;
    height: 100%;
    /* border-bottom: 1px solid #dfd9d9; */
    padding-bottom: 18px;
    padding-top: 3%;
    margin: 0px 18px;
}
.checkout_order_summery h2{
    font-size: 16px;
}
.order_method button{
    font-size: 16px;
}
.checkout_cart_gray_box1 p{
    font-size: 18px;
}
.checkout_cart_gray_box1 span{
    font-size: 24px;
}
.checkout_cart_gray_box1{
    padding: 18px;
    margin-top: 30px;
}
.checkoutpickup_select_box_method__a\+FhC {
    display: flex;
    justify-content: space-between;
    padding: 10px 18px 30px;
}
.deliver_step h6{
    font-size: 16px;
}
.form_group h6{
    font-size: 16px;
}
.custom_radio_wrap h3{
    font-size: 16px;
}
.checkout_cart_gray_box h5{
    font-size: 16px;
}
.checkout_cart_gray_box h5{
    font-size: 16px;
}
.checkout_cart_gray_box span{
    font-size: 18px;
}
.checkout_cart_customer h3{
    font-size: 20px;
}
.checkout_cart_gray_box{
    margin-top: 30px;
}
.checkout_cart_gray_box p{
    font-size: 18px;
}
.payment_gateway_text h4{
    font-size: 18px;
}
.order_summary_lists1 p {
    font-size: 14px;
    color: #414141;
    line-height: 28px;
}
.checkout_gatway_wrapper h4{
    width: 100%;
}
.order_summary_lists h2 {
    font-size: 16px;
    color: #F86B1F;
    font-weight: 600;
    line-height: 8px;
    text-transform: capitalize;
    margin-top: 15px;
}
.order_summary_lists1 h2{
    font-size: 16px;
    color: #F86B1F;
    font-weight: 600;
    line-height: 8px;
}
.checkout_cart_customer{
    padding: 20px;
}
}
@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .main_wrapper{
        padding: 30px 3rem;
    }
    .checkout_cart h1 {
        font-size: 24px;
    }
    .checkout_cart p{
        font-size: 14px;
    }
    .checkout_cart_heading span {
        display: block;
    }
    .checkout_cart_customer_product{
        display: flex;
    }
    .checkout_cart_customer_products{
        width: 48%;
    }
    .checkout_form_box{
        display: flex;
        margin-top: 2px;
    }
    .checkout_form_box button{
        width: 48%;
        margin-top: 0;
    }
    .checkout_gatway_wrapper h4 {
        width: 100%;
    }
    .checkout_subscribe h3{
        font-size: 15px;
    }
    .checkout_cart_customer p{
        display: flex;
    }
    .checkout_subscribe img{
         width: 3%;
    }
    .checkout_order_summery{
        width: 48%;
        margin-top: 30px;
    }
    .order_summary_lists p {
        font-size: 14px;
        color: #6F758B;
        font-weight: 400;
        line-height: 28px;
        text-transform: capitalize;
       
}
.form_group {
    display: flex;
    align-items: center;
    height: 100%;
    border-bottom: 1px solid #dfd9d9;
    padding-bottom: 18px;
    padding-top: 3%;
    margin: 0px 18px;
}
.checkout_order_summery h2{
    font-size: 16px;
}
.order_method button{
    font-size: 16px;
}
.checkout_cart_gray_box1 p{
    font-size: 18px;
}
.checkout_cart_gray_box1 span{
    font-size: 24px;
}
.checkout_cart_gray_box1{
    padding: 18px;
    margin-top: 30px;
}
.checkoutpickup_select_box_method__a\+FhC {
    display: flex;
    justify-content: space-between;
    padding: 10px 18px 30px;
}
.deliver_step h6{
    font-size: 16px;
}
.form_group h6{
    font-size: 16px;
}
.custom_radio_wrap h3{
    font-size: 16px;
}
.checkout_cart_gray_box h5{
    font-size: 16px;
}
.checkout_cart_gray_box h5{
    font-size: 14px;
}
.checkout_cart_gray_box span{
    font-size: 18px;
}
.checkout_cart_customer h3{
    font-size: 20px;
}
.checkout_cart_gray_box{
    margin-top: 30px;
}
.checkout_cart_gray_box p{
    font-size: 16px;
}
.order_summary_lists1 p {
    font-size: 14px;
    color: #414141;
    line-height: 28px;
}
.order_summary_lists h2 {
    font-size: 16px;
    color: #F86B1F;
    font-weight: 600;
    line-height: 8px;
    text-transform: capitalize;
    margin-top: 15px;
}
.order_summary_lists1 h2{
    font-size: 16px;
    color: #F86B1F;
    font-weight: 600;
    line-height: 8px;
}
.checkout_cart_customer{
    padding: 20px;
}
}
@media screen and (min-width: 1201px) and (max-width: 1366px) {
    .main_wrapper{
        padding: 30px 3rem;
    }
    .checkout_cart h1 {
        font-size: 24px;
    }
    .checkout_cart p{
        font-size: 14px;
    }
    .checkout_cart_heading span {
        display: block;
    }
    .checkout_cart_customer_product{
        display: flex;
    }
    .checkout_cart_customer_products{
        width: 48%;
    }
    .checkout_form_box{
        display: flex;
        margin-top: 2px;
    }
    .checkout_form_box button{
        width: 48%;
        margin-top: 0;
    }
    .checkout_subscribe h3{
        font-size: 15px;
    }
    .checkout_cart_customer p{
        display: flex;
    }
    .checkout_subscribe img{
         width: 3%;
    }
    .checkout_order_summery{
        width: 48%;
        margin-top: 30px;
    }
    .order_summary_lists p {
        font-size: 14px;
        color: #6F758B;
        font-weight: 400;
        line-height: 28px;
        text-transform: capitalize;
    
}
.form_group {
    display: flex;
    align-items: center;
    height: 100%;
    border-bottom: 1px solid #dfd9d9;
    padding-bottom: 18px;
    padding-top: 3%;
    margin: 0px 18px;
}
.checkout_order_summery h2{
    font-size: 16px;
}
.order_method button{
    font-size: 16px;
}
.checkout_cart_gray_box1 p{
    font-size: 18px;
}
.checkout_cart_gray_box1 span{
    font-size: 24px;
}
.checkout_cart_gray_box1{
    padding: 18px;
    margin-top: 30px;
}
.checkoutpickup_select_box_method__a\+FhC {
    display: flex;
    justify-content: space-between;
    padding: 10px 18px 30px;
}
.deliver_step h6{
    font-size: 16px;
}
.form_group h6{
    font-size: 16px;
}
.custom_radio_wrap h3{
    font-size: 16px;
}
.checkout_cart_gray_box h5{
    font-size: 16px;
}
.checkout_cart_gray_box h5{
    font-size: 16px;
}
.checkout_cart_gray_box span{
    font-size: 18px;
}
.checkout_cart_customer h3{
    font-size: 20px;
}
.checkout_cart_gray_box{
    margin-top: 30px;
}
.checkout_cart_gray_box p{
    font-size: 16px;
    color: black;
}
.order_summary_lists1 p {
    font-size: 14px;
    color: #414141;
    line-height: 28px;
}
.order_summary_lists h2 {
    font-size: 16px;
    color: #F86B1F;
    font-weight: 600;
    line-height: 8px;
    text-transform: capitalize;
    margin-top: 15px;
}
.order_summary_lists1 h2{
    font-size: 16px;
    color: #F86B1F;
    font-weight: 600;
    line-height: 8px;
}
.checkout_cart_customer{
    padding: 20px;
}
.checkout_gatway_wrapper h4 {
    width: 100%;
}
}
@media screen and (min-width: 1367px) and (max-width: 1600px) {
    .main_wrapper{
        padding: 30px 5rem;
    }
    .checkout_cart h1 {
        font-size: 24px;
    }
    .checkout_cart p{
        font-size: 14px;
    }
    .checkout_cart_heading span {
        display: block;
    }
    .checkout_cart_customer_product{
        display: flex;
    }
    .checkout_cart_customer_products{
        width: 48%;
    }
    .checkout_form_box{
        display: flex;
        margin-top: 2px;
    }
    .checkout_form_box button{
        width: 48%;
        margin-top: 0;
    }
    .checkout_subscribe h3{
        font-size: 15px;
    }
    .checkout_cart_customer p{
        display: flex;
    }
    .checkout_subscribe img{
         width: 3%;
    }
    .checkout_order_summery{
        width: 48%;
        margin-top: 30px;
    }
    .order_summary_lists p {
        font-size: 14px;
        color: #6F758B;
        font-weight: 400;
        line-height: 28px;
        text-transform: capitalize;
      
}
.form_group {
    display: flex;
    align-items: center;
    height: 100%;
    border-bottom: 1px solid #dfd9d9;
    padding-bottom: 18px;
    padding-top: 3%;
    margin: 0px 18px;
}
.checkout_order_summery h2{
    font-size: 16px;
}
.order_method button{
    font-size: 16px;
}
.checkout_cart_gray_box1 p{
    font-size: 18px;
}
.checkout_cart_gray_box1 span{
    font-size: 24px;
}
.checkout_cart_gray_box1{
    padding: 18px;
    margin-top: 30px;
}
.checkoutpickup_select_box_method__a\+FhC {
    display: flex;
    justify-content: space-between;
    padding: 10px 18px 30px;
}
.deliver_step h6{
    font-size: 16px;
}
.form_group h6{
    font-size: 16px;
}
.custom_radio_wrap h3{
    font-size: 16px;
}
.checkout_cart_gray_box h5{
    font-size: 16px;
}
.checkout_cart_gray_box h5{
    font-size: 16px;
}
.checkout_cart_gray_box span{
    font-size: 18px;
}
.checkout_cart_customer h3{
    font-size: 20px;
}
.checkout_cart_gray_box{
    margin-top: 30px;
}
.checkout_cart_gray_box p{
    font-size: 20px;
}
.order_summary_lists1 p {
    font-size: 14px;
    color: #414141;
    line-height: 28px;
}
.order_summary_lists h2 {
    font-size: 16px;
    color: #F86B1F;
    font-weight: 600;
    line-height: 8px;
    text-transform: capitalize;
    margin-top: 15px;
}
.order_summary_lists1 h2{
    font-size: 16px;
    color: #F86B1F;
    font-weight: 600;
    line-height: 8px;
}
.checkout_cart_customer{
    padding: 20px;
}
.checkout_gatway_wrapper h4 {
    width: 100%;
}
}

.paymentgate_wrap_images img{
    margin-right: 30px;
    width: 34px;
    height: 34px;
}