.account_detail_section_wrapper {
    padding: 30px 10rem;
    display: grid;
    grid-template-columns: 20% 80%;
    margin-top: 3%;

}



.clickable_text a:focus {
    color: red;
    cursor: default;
  }
.account_order_lists{
    margin-top: 5rem;
}

.toggle_section>ul {
    list-style: none;
    margin-top: 22%;
}

#pet-select {
    display: block !important;
}

/* .toggle_section ul li:nth-child(2) {
    color: #6F758B;
} */

.shipping_address_text {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
}

.shipping_address_text p {
    color: #212F63;
    font-size: 16px;
    font-weight: 600;
}

.shipping_address_text h1 {
    color: #414141;
    font-size: 32px;
    font-weight: 700;
}

.toggle_section>ul>li {
    /* UI/Tab */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 10px;

    /* Primary 01 */

    color: #212F63;
    cursor: pointer;
}

.billing_address_section>h1,
.shipping_address_section>h1 {
    margin-top: 40px;
}

.user_detail_section>h1,
.billing_address_section>h1,
.shipping_address_section>h1 {
    /* H --/H2 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;
    /* identical to box height, or 45px */

    font-feature-settings: 'pnum' on, 'lnum' on;

    /* Black */

    color: #414141;
}

.input_label {
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 140%; */
    grid-template-columns: repeat(2, 1fr);
    
}

.input_field {
    background: var(--white);
    box-sizing: border-box;
    
    padding: 5px;
    width: 100%;
    margin-top: 6px;
    display: grid;
    height: 48px;
    outline: none;
}


.user_detail_input_section,
.billing_address_input_section,
.shipping_address_input_section {
    padding: 30px;
    background: #F7F7F7;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 40px;

}

.account_order_lists a {
    list-style: none;
    color: #212F63;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    line-height: 34px;
}

.account_order_lists li {
    list-style: none;
}

.account_order_lists .active_list {
    list-style: none;
    color: #6F758B;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    line-height: 34px;
}


@media screen and (max-width: 1000px) {
    .account_detail_section_wrapper {
        display: block;
        padding: 30px 2rem;
    }

    .toggle_section>ul {
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;
    }

    .user_detail_section>h1,
    .billing_address_section>h1,
    .shipping_address_section>h1 {
        text-align: center;
    }
}

@media screen and (max-width: 550px) {

    .user_detail_input_section,
    .billing_address_input_section,
    .shipping_address_input_section {
        grid-template-columns: 1fr;
        display: block;

    }
}

@media screen and (max-width: 767px) {
    .toggle_section>ul {
        list-style: none;
        margin-top: 0%;
    }

    .shipping_address_text {
        display: block;
        justify-content: space-between;
        margin-top: 5%;
    }

    .shipping_address_text p {
        margin-top: 20px;
    }
    .user_detail_section>h1,
.billing_address_section>h1,
.shipping_address_section>h1 {
    /* H --/H2 */

    font-family: 'Neue Haas Grotesk Text Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    /* identical to box height, or 45px */

    font-feature-settings: 'pnum' on, 'lnum' on;
    text-align: left;

    /* Black */

    color: #414141;
}
.shipping_address_text h1{
    font-size: 24px;
}
.account_detail_section_wrapper{
    padding: 16px 1rem;
}
.account_order_lists{
    margin-top:0px
}
.account_order_lists ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}
.account_order_lists a {
    font-size: 13px;
}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .toggle_section>ul {
        list-style: none;
        margin-top: 0%;
    }
    .account_detail_section_wrapper{
        padding: 30px 2rem;
    }
}

.active {
    color: red;
  }
  