.special_order_page_container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 2px solid #ccc; /* Add border */
    border-radius: 15px; /* Add border radius for rounded corners */
}

.special_order_page_heading {
    font-size: 32px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px; /* Add some space below the heading */
    display: flex;
    justify-content: center; /* Center the content horizontally */
}

.special_order_form_container {
    margin-top: 20px;
}

.special_order_form_input {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

/* Apply the same style to the submit button */
.special_order_form_submit {
    background-color: #333;
    color: #fff;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.special_order_form_submit:hover {
    background-color: #555;
}

/* Add this to the end of specialOrder.module.css */




.contact_text {
    margin-top: 20px;
    font-size: 14px;
}

.contact_options {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.whatsapp_icon, .gmail_icon {
    cursor: pointer;
}

.whatsapp_icon:hover, .gmail_icon:hover {
    text-decoration: underline;
}

/* Styling for WhatsApp icon */
.whatsapp_icon {
    color: #25d366; /* WhatsApp color */
}

/* Styling for Gmail icon */
.gmail_icon {
    color: #d93025; /* Gmail color */
}

.center {
    text-align: center;
}
/* Add this to the end of specialOrder.module.css */

.additional_info_text {
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
}

/* Add this to the end of specialOrder.module.css */

.contact_link {
    display: flex; /* Use flexbox to align icon and text */
    align-items: center; /* Align items vertically */
    cursor: pointer;
    text-decoration: underline;
    color: #333; /* Text color */
    margin-right: 20px; /* Add space between icon and text */

}

.contact_link:hover {
    color: #555; /* Text color on hover */
}

.contact_icon {
    width: 30px; /* Adjust icon size as needed */
    height: 30px; /* Adjust icon size as needed */
    margin-right: 10px; /* Add space between icon and text */
}
/* Add this to the end of specialOrder.module.css */

.contact_icons_container {
    display: flex;
    justify-content: center; /* Center the content horizontally */
}

.contact_options {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.whatsapp_icon{
    cursor: pointer;
    color: #25d366; /* WhatsApp color */
    margin-right: 10px;
}
.gmail_icon {
    cursor: pointer;
    color: #db0909; /* WhatsApp color */
    margin-right: 10px;
}

.whatsapp_icon:hover,
.gmail_icon:hover {
    text-decoration: underline;
}
