/*footer section*/
.footer_main_wrapper{
    background: var(--light-gray);
    padding: var(--whitespace);
    margin-top: 40px;
}

.footer_wrapper {
    display: flex;
    justify-content: space-between;
    background: var(--light-gray);
    
}

.contactus_section h3 {
    color: var(--light-purple);
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
}

.contactus_section p {
    color: var(--light-purple);
    font-size: 14px;
    font-weight: 600;
}

.contactus_section span {
    color: var(--dark-brinjal);
    font-weight: 500;
    font-size: 16px;
}
.arabic_address_text{
    margin-right: 15px;
    margin-left: 15px;
}
.address_text,
.phone_number,
.email_address { display: flex; }

.address_text,
.phone_number,
.email_address {
    display: flex;
    margin-bottom: 20px;
}

.address_text>div,
.phone_number>div,
.email_address>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.address_text>div>p,
.phone_number>div>p,
.email_address>div>p {

    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 500;
    font-size: .9rem;
    line-height: 140%;
    color: var(--light-purple);
    cursor: pointer;
}


.address_text>div>span,
.phone_number>div>span,
.email_address>div>span {
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 400;
    font-size: .9rem;
    line-height: 140%;
    color: var(--dark-brinjal);
    cursor: pointer;
}


.address_text>aside,
.phone_number>aside,
.email_address>aside {
    background: var(--white);
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.service_section_heading,
.conatctus_heading,
.information_section_heading,
.subscribe_section_heading{
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 140%;
    text-transform: capitalize;
    color: var(--light-purple);
}

.services_section>ul,
.information_section > ul{
    padding-top: 25px;
}

.services_section >ul >li,
.information_section>ul>li {
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 400;
    font-size: .9rem;
    line-height: 140%;
    color: var(--dark-brinjal);
    margin-bottom: 15px;
    list-style: none;
    cursor: pointer;
}

.services_section>ul>li > a,
.information_section>ul>li >a {
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 400;
    font-size: .9rem;
    line-height: 140%;
    color: var(--dark-brinjal);
    margin-bottom: 15px;
    list-style: none;
    cursor: pointer;
}

/* subscription */
.subscribe_section > p{

    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: var(--light-purple);
    padding: 20px 0;
}


#subscription_form{ display: flex; align-items: center; }
.subscription_email_input{ width: 100%;}
#subscription_form > button {
    display: flex;
    height: fit-content;
    margin-top: 1.1px;
    display: flex;
    padding: 14.2px 20px;
    border: none;
    background: var(--dark-brinjal);
    margin-bottom: 15px;
}

.copy_right_text{
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0px !important;
    color: var(--light-purple);
}

.copy_right_text > span{
    color: var(--dark-brinjal);
    margin-left: 10px;

}
.address_text>aside, .phone_number>aside, .email_address>aside{
    margin-right: 0px;
}
/* media query */
@media screen and (max-width: 1100px) {
    .footer_wrapper{
        display: grid;
        gap: 30px;
        grid-template-columns: repeat(3, 1fr);
    }

    .copy_right_text{
        margin-top: 20px;
    }
    .address_text>aside, .phone_number>aside, .email_address>aside{
        margin-right: 0px;
    }
}


@media screen and (max-width: 880px) {
    .footer_wrapper {
        grid-template-columns: repeat(2, 1fr);
    }

}

@media screen and (max-width: 550px) {
    .footer_wrapper {
        grid-template-columns: 1fr;
    }
    .address_text>aside, .phone_number>aside, .email_address>aside{
        margin-right: 0px;
    }
   

}

